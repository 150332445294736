import * as Apollo from '@apollo/client';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getDissolutionDocuments.graphql';

const defaultOptions = {} as const;
export type GetDissolutionDocumentsQueryVariables = Types.Exact<{
	documentTypeGroup: Types.DocumentGroupEnum;
}>;

export type GetDissolutionDocumentsQuery = {getDocumentTypesByGroup?: Array<{id: string; name: string}> | null};

export type GetDissolutionDocumentsType = {id: string; name: string};

/**
 * __useGetDissolutionDocumentsQuery__
 *
 * To run a query within a React component, call `useGetDissolutionDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDissolutionDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDissolutionDocumentsQuery({
 *   variables: {
 *      documentTypeGroup: // value for 'documentTypeGroup'
 *   },
 * });
 */
export function useGetDissolutionDocumentsQuery(
	baseOptions: Apollo.QueryHookOptions<GetDissolutionDocumentsQuery, GetDissolutionDocumentsQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetDissolutionDocumentsQuery, GetDissolutionDocumentsQueryVariables>(Operations, options);
}
export function useGetDissolutionDocumentsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetDissolutionDocumentsQuery, GetDissolutionDocumentsQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetDissolutionDocumentsQuery, GetDissolutionDocumentsQueryVariables>(Operations, options);
}
export type GetDissolutionDocumentsQueryHookResult = ReturnType<typeof useGetDissolutionDocumentsQuery>;
export type GetDissolutionDocumentsLazyQueryHookResult = ReturnType<typeof useGetDissolutionDocumentsLazyQuery>;
export type GetDissolutionDocumentsQueryResult = Apollo.QueryResult<GetDissolutionDocumentsQuery, GetDissolutionDocumentsQueryVariables>;
