import * as Apollo from '@apollo/client';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getAccountBalanceForPaymentScheduleSidebar.graphql';

const defaultOptions = {} as const;
export type GetAccountBalanceForPaymentScheduleSidebarQueryVariables = Types.Exact<{
	accountInfoId: Types.Scalars['String'];
}>;

export type GetAccountBalanceForPaymentScheduleSidebarQuery = {getTransactionBalanceByAccountInfoId: {balance: any}};

export type GetAccountBalanceForPaymentScheduleSidebarType = {balance: any};

/**
 * __useGetAccountBalanceForPaymentScheduleSidebarQuery__
 *
 * To run a query within a React component, call `useGetAccountBalanceForPaymentScheduleSidebarQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountBalanceForPaymentScheduleSidebarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountBalanceForPaymentScheduleSidebarQuery({
 *   variables: {
 *      accountInfoId: // value for 'accountInfoId'
 *   },
 * });
 */
export function useGetAccountBalanceForPaymentScheduleSidebarQuery(
	baseOptions: Apollo.QueryHookOptions<GetAccountBalanceForPaymentScheduleSidebarQuery, GetAccountBalanceForPaymentScheduleSidebarQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetAccountBalanceForPaymentScheduleSidebarQuery, GetAccountBalanceForPaymentScheduleSidebarQueryVariables>(
		Operations,
		options,
	);
}
export function useGetAccountBalanceForPaymentScheduleSidebarLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetAccountBalanceForPaymentScheduleSidebarQuery,
		GetAccountBalanceForPaymentScheduleSidebarQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetAccountBalanceForPaymentScheduleSidebarQuery, GetAccountBalanceForPaymentScheduleSidebarQueryVariables>(
		Operations,
		options,
	);
}
export type GetAccountBalanceForPaymentScheduleSidebarQueryHookResult = ReturnType<typeof useGetAccountBalanceForPaymentScheduleSidebarQuery>;
export type GetAccountBalanceForPaymentScheduleSidebarLazyQueryHookResult = ReturnType<typeof useGetAccountBalanceForPaymentScheduleSidebarLazyQuery>;
export type GetAccountBalanceForPaymentScheduleSidebarQueryResult = Apollo.QueryResult<
	GetAccountBalanceForPaymentScheduleSidebarQuery,
	GetAccountBalanceForPaymentScheduleSidebarQueryVariables
>;
