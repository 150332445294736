import * as Apollo from '@apollo/client';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './markAllNotificationsReadByUserId.graphql';

const defaultOptions = {} as const;
export type MarkAllNotificationsReadByUserIdMutationVariables = Types.Exact<{
	userId: Types.Scalars['String'];
}>;

export type MarkAllNotificationsReadByUserIdMutation = {markAllNotificationsReadByUserId?: Array<{id: string; version: number}> | null};

export type MarkAllNotificationsReadByUserIdType = {id: string; version: number};

export type MarkAllNotificationsReadByUserIdMutationFn = Apollo.MutationFunction<
	MarkAllNotificationsReadByUserIdMutation,
	MarkAllNotificationsReadByUserIdMutationVariables
>;

/**
 * __useMarkAllNotificationsReadByUserIdMutation__
 *
 * To run a mutation, you first call `useMarkAllNotificationsReadByUserIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAllNotificationsReadByUserIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAllNotificationsReadByUserIdMutation, { data, loading, error }] = useMarkAllNotificationsReadByUserIdMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useMarkAllNotificationsReadByUserIdMutation(
	baseOptions?: Apollo.MutationHookOptions<MarkAllNotificationsReadByUserIdMutation, MarkAllNotificationsReadByUserIdMutationVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<MarkAllNotificationsReadByUserIdMutation, MarkAllNotificationsReadByUserIdMutationVariables>(Operations, options);
}
export type MarkAllNotificationsReadByUserIdMutationHookResult = ReturnType<typeof useMarkAllNotificationsReadByUserIdMutation>;
export type MarkAllNotificationsReadByUserIdMutationResult = Apollo.MutationResult<MarkAllNotificationsReadByUserIdMutation>;
export type MarkAllNotificationsReadByUserIdMutationOptions = Apollo.BaseMutationOptions<
	MarkAllNotificationsReadByUserIdMutation,
	MarkAllNotificationsReadByUserIdMutationVariables
>;
