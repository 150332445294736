import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {EmployeesDropdown} from '../../accounts/components/employees/EmployeesDropdown';
import type {routes} from '../routes';
import {setEmployeeId, useCalendarState} from '../state';

export function EmployeeDropdownSidebar() {
	const dispatch = useDispatch();
	const {accountInfoId} = useParams<typeof routes.types.calendar>();
	const {employeeId} = useCalendarState();

	return (
		<EmployeesDropdown
			accountInfoId={accountInfoId}
			placeholder="select Employee..."
			selection
			fluid
			defaultToUser
			onChange={employee => {
				if (employee) {
					dispatch(setEmployeeId(employee));
				}
			}}
			value={employeeId}
		/>
	);
}
