import * as Apollo from '@apollo/client';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './markNSF.graphql';

const defaultOptions = {} as const;
export type MarkNsfMutationVariables = Types.Exact<{
	paymentId: Types.Scalars['String'];
}>;

export type MarkNsfMutation = {markPaymentNSF: string};

export type MarkNsfType = string;

export type MarkNsfMutationFn = Apollo.MutationFunction<MarkNsfMutation, MarkNsfMutationVariables>;

/**
 * __useMarkNsfMutation__
 *
 * To run a mutation, you first call `useMarkNsfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkNsfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markNsfMutation, { data, loading, error }] = useMarkNsfMutation({
 *   variables: {
 *      paymentId: // value for 'paymentId'
 *   },
 * });
 */
export function useMarkNsfMutation(baseOptions?: Apollo.MutationHookOptions<MarkNsfMutation, MarkNsfMutationVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<MarkNsfMutation, MarkNsfMutationVariables>(Operations, options);
}
export type MarkNsfMutationHookResult = ReturnType<typeof useMarkNsfMutation>;
export type MarkNsfMutationResult = Apollo.MutationResult<MarkNsfMutation>;
export type MarkNsfMutationOptions = Apollo.BaseMutationOptions<MarkNsfMutation, MarkNsfMutationVariables>;
