import * as Apollo from '@apollo/client';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './endContract.graphql';

const defaultOptions = {} as const;
export type EndContractMutationVariables = Types.Exact<{
	contractId: Types.Scalars['String'];
}>;

export type EndContractMutation = {endContract: {id: string}};

export type EndContractType = {id: string};

export type EndContractMutationFn = Apollo.MutationFunction<EndContractMutation, EndContractMutationVariables>;

/**
 * __useEndContractMutation__
 *
 * To run a mutation, you first call `useEndContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEndContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [endContractMutation, { data, loading, error }] = useEndContractMutation({
 *   variables: {
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useEndContractMutation(baseOptions?: Apollo.MutationHookOptions<EndContractMutation, EndContractMutationVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<EndContractMutation, EndContractMutationVariables>(Operations, options);
}
export type EndContractMutationHookResult = ReturnType<typeof useEndContractMutation>;
export type EndContractMutationResult = Apollo.MutationResult<EndContractMutation>;
export type EndContractMutationOptions = Apollo.BaseMutationOptions<EndContractMutation, EndContractMutationVariables>;
