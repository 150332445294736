import * as Apollo from '@apollo/client';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getVendorDropdownGlobal.graphql';

const defaultOptions = {} as const;
export type GetVendorDropdownGlobalQueryVariables = Types.Exact<{[key: string]: never}>;

export type GetVendorDropdownGlobalQuery = {getGlobalVendors?: Array<{id: string; version: number; name: string}> | null};

export type GetVendorDropdownGlobalType = {id: string; version: number; name: string};

/**
 * __useGetVendorDropdownGlobalQuery__
 *
 * To run a query within a React component, call `useGetVendorDropdownGlobalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVendorDropdownGlobalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVendorDropdownGlobalQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVendorDropdownGlobalQuery(
	baseOptions?: Apollo.QueryHookOptions<GetVendorDropdownGlobalQuery, GetVendorDropdownGlobalQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetVendorDropdownGlobalQuery, GetVendorDropdownGlobalQueryVariables>(Operations, options);
}
export function useGetVendorDropdownGlobalLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetVendorDropdownGlobalQuery, GetVendorDropdownGlobalQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetVendorDropdownGlobalQuery, GetVendorDropdownGlobalQueryVariables>(Operations, options);
}
export type GetVendorDropdownGlobalQueryHookResult = ReturnType<typeof useGetVendorDropdownGlobalQuery>;
export type GetVendorDropdownGlobalLazyQueryHookResult = ReturnType<typeof useGetVendorDropdownGlobalLazyQuery>;
export type GetVendorDropdownGlobalQueryResult = Apollo.QueryResult<GetVendorDropdownGlobalQuery, GetVendorDropdownGlobalQueryVariables>;
