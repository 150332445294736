import * as Apollo from '@apollo/client';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './ChangeTicketAssignedTo.graphql';

const defaultOptions = {} as const;
export type ChangeTicketAssignedToMutationVariables = Types.Exact<{
	data: Types.ChangeAssignedToInput;
}>;

export type ChangeTicketAssignedToMutation = {changeAssignedTo: string};

export type ChangeTicketAssignedToType = string;

export type ChangeTicketAssignedToMutationFn = Apollo.MutationFunction<ChangeTicketAssignedToMutation, ChangeTicketAssignedToMutationVariables>;

/**
 * __useChangeTicketAssignedToMutation__
 *
 * To run a mutation, you first call `useChangeTicketAssignedToMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeTicketAssignedToMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeTicketAssignedToMutation, { data, loading, error }] = useChangeTicketAssignedToMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useChangeTicketAssignedToMutation(
	baseOptions?: Apollo.MutationHookOptions<ChangeTicketAssignedToMutation, ChangeTicketAssignedToMutationVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<ChangeTicketAssignedToMutation, ChangeTicketAssignedToMutationVariables>(Operations, options);
}
export type ChangeTicketAssignedToMutationHookResult = ReturnType<typeof useChangeTicketAssignedToMutation>;
export type ChangeTicketAssignedToMutationResult = Apollo.MutationResult<ChangeTicketAssignedToMutation>;
export type ChangeTicketAssignedToMutationOptions = Apollo.BaseMutationOptions<
	ChangeTicketAssignedToMutation,
	ChangeTicketAssignedToMutationVariables
>;
