import debug from 'debug';
import {Link} from 'react-router-dom';
import {List, Placeholder} from 'semantic-ui-react';
import {EmployeeStatusFilterEnum} from '~core/graphql';
import {getName} from '~lib/getName';
import {routes} from '../../../routes';
import {useGetEmployeeListEmployeesQuery} from './getEmployeeListEmployees';

const d = debug('tacs.web.accounts.components.employees.DisplayEmployeeList');

interface DisplayEmployeeListProps {
	accountInfoId: string;
}

export function DisplayEmployeeList(props: DisplayEmployeeListProps) {
	const {accountInfoId} = props;
	const {data, loading, error} = useGetEmployeeListEmployeesQuery({
		variables: {accountInfoId, filter: {status: EmployeeStatusFilterEnum.Employed}},
	});

	if (loading)
		return (
			<Placeholder>
				<Placeholder.Line />
				<Placeholder.Line />
				<Placeholder.Line />
			</Placeholder>
		);
	if (error) throw error;
	if (!data) throw new Error("Data couldn't be loaded");

	return (
		<List bulleted>
			{data?.getEmployeesByAccountInfoId?.length ? (
				data?.getEmployeesByAccountInfoId?.map(employee => {
					return (
						<List.Item key={employee?.id}>
							<Link to={routes.to.viewEmployee({accountInfoId, employeeId: employee?.id || ''})}>
								{employee ? getName(employee.contact) : 'name not found'}
							</Link>
						</List.Item>
					);
				})
			) : (
				<List.Item>(No Employees added)</List.Item>
			)}
		</List>
	);
}
