import * as Apollo from '@apollo/client';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './markTimeCardPaidButton.graphql';

const defaultOptions = {} as const;
export type MarkTimeCardPaidButtonMutationVariables = Types.Exact<{
	timeCardId: Types.Scalars['String'];
}>;

export type MarkTimeCardPaidButtonMutation = {markTimeCardAsPaid: {id: string}};

export type MarkTimeCardPaidButtonType = {id: string};

export type MarkTimeCardPaidButtonMutationFn = Apollo.MutationFunction<MarkTimeCardPaidButtonMutation, MarkTimeCardPaidButtonMutationVariables>;

/**
 * __useMarkTimeCardPaidButtonMutation__
 *
 * To run a mutation, you first call `useMarkTimeCardPaidButtonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkTimeCardPaidButtonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markTimeCardPaidButtonMutation, { data, loading, error }] = useMarkTimeCardPaidButtonMutation({
 *   variables: {
 *      timeCardId: // value for 'timeCardId'
 *   },
 * });
 */
export function useMarkTimeCardPaidButtonMutation(
	baseOptions?: Apollo.MutationHookOptions<MarkTimeCardPaidButtonMutation, MarkTimeCardPaidButtonMutationVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<MarkTimeCardPaidButtonMutation, MarkTimeCardPaidButtonMutationVariables>(Operations, options);
}
export type MarkTimeCardPaidButtonMutationHookResult = ReturnType<typeof useMarkTimeCardPaidButtonMutation>;
export type MarkTimeCardPaidButtonMutationResult = Apollo.MutationResult<MarkTimeCardPaidButtonMutation>;
export type MarkTimeCardPaidButtonMutationOptions = Apollo.BaseMutationOptions<
	MarkTimeCardPaidButtonMutation,
	MarkTimeCardPaidButtonMutationVariables
>;
