import {defineRoutes} from '@imperium/router';

export const routes = defineRoutes({
	dashboard: {
		path: '/',
		exact: true,
	},
	otherUserDashboard: {
		path: '/dashboard/:userId',
		params: ['userId'] as const,
	},
	podcastsPage: {
		path: '/podcasts/:videoId',
		params: ['videoId'] as const,
	},
});
