import {useHistory, useParams} from 'react-router-dom';
import {Button, List} from 'semantic-ui-react';
import {routes} from '../../routes';
import {useGetAccountBalanceForPaymentScheduleSidebarQuery} from './getAccountBalanceForPaymentScheduleSidebar';

export function PaymentScheduleSidebar() {
	const {push} = useHistory();
	const {accountInfoId} = useParams<typeof routes.types.paymentSchedule>();

	const {data} = useGetAccountBalanceForPaymentScheduleSidebarQuery({
		variables: {accountInfoId},
		onError: error => {
			throw error;
		},
	});

	return (
		<List>
			{data?.getTransactionBalanceByAccountInfoId.balance > 0 && (
				<List.Item>
					<Button fluid color="green" onClick={() => push(routes.to.paymentAdd({accountInfoId}))}>
						Add Payment
					</Button>
				</List.Item>
			)}
		</List>
	);
}
