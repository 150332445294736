import debug from 'debug';
import {Dropdown, DropdownProps} from 'semantic-ui-react';
import {AgentRole} from '~core/graphql';
import {createDropdownOptionsFromEnum} from '~lib/createDropdownOptionsFromEnum';

const d = debug('tacs.web.user.components.AgentRolesDropdown');

interface AgentRolesDropdownProps extends Omit<DropdownProps, 'onChange' | 'options'> {
	onChange?: (val: string | string[]) => void;
}

export function AgentRolesDropdown(props: AgentRolesDropdownProps) {
	const {onChange, loading: propsLoading, ...rest} = props;

	function handleChange(value?: string | string[]) {
		if (value && onChange) onChange(value);
	}

	return (
		<Dropdown
			{...rest}
			options={createDropdownOptionsFromEnum(AgentRole, true)}
			onChange={(e, val) => handleChange(val.value as string | string[])}
		/>
	);
}
