import * as Apollo from '@apollo/client';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getById.graphql';

const defaultOptions = {} as const;
export type GetDocumentSortingClassByIdQueryVariables = Types.Exact<{
	documentSortingClassId: Types.Scalars['String'];
}>;

export type GetDocumentSortingClassByIdQuery = {
	getDocumentSortingClassById: {
		id: string;
		version: number;
		className: string;
		active: boolean;
		amountRequired: boolean;
		partialSortingRequired: boolean;
		count: number;
		type: {id: string};
		vendor: {id: string};
	};
};

export type GetDocumentSortingClassByIdType = {
	id: string;
	version: number;
	className: string;
	active: boolean;
	amountRequired: boolean;
	partialSortingRequired: boolean;
	count: number;
	type: {id: string};
	vendor: {id: string};
};

/**
 * __useGetDocumentSortingClassByIdQuery__
 *
 * To run a query within a React component, call `useGetDocumentSortingClassByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentSortingClassByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentSortingClassByIdQuery({
 *   variables: {
 *      documentSortingClassId: // value for 'documentSortingClassId'
 *   },
 * });
 */
export function useGetDocumentSortingClassByIdQuery(
	baseOptions: Apollo.QueryHookOptions<GetDocumentSortingClassByIdQuery, GetDocumentSortingClassByIdQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetDocumentSortingClassByIdQuery, GetDocumentSortingClassByIdQueryVariables>(Operations, options);
}
export function useGetDocumentSortingClassByIdLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetDocumentSortingClassByIdQuery, GetDocumentSortingClassByIdQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetDocumentSortingClassByIdQuery, GetDocumentSortingClassByIdQueryVariables>(Operations, options);
}
export type GetDocumentSortingClassByIdQueryHookResult = ReturnType<typeof useGetDocumentSortingClassByIdQuery>;
export type GetDocumentSortingClassByIdLazyQueryHookResult = ReturnType<typeof useGetDocumentSortingClassByIdLazyQuery>;
export type GetDocumentSortingClassByIdQueryResult = Apollo.QueryResult<GetDocumentSortingClassByIdQuery, GetDocumentSortingClassByIdQueryVariables>;
