import {ApolloError} from '@apollo/client';
import debug from 'debug';
import {compressToBase64} from 'lz-string';
import {Component} from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Button, Form, FormField, Grid, GridColumn, Header, Segment, TextArea} from 'semantic-ui-react';

const d = debug('tacs.web.common.components.ErrorBoundary');

interface ErrorBoundaryProps extends RouteComponentProps {
	children: JSX.Element;
}
interface State {
	hasError: boolean;
	error?: Error;
}

const errorBoundarySegmentStyle = {
	margin: '1em',
};

class ErrorBoundaryComponent extends Component<ErrorBoundaryProps, State> {
	constructor(props: ErrorBoundaryProps | Readonly<ErrorBoundaryProps>) {
		super(props);
		this.state = {hasError: false};
	}

	static getDerivedStateFromError() {
		return {hasError: true};
	}

	componentDidUpdate(prevProps: Readonly<ErrorBoundaryProps>, prevState: Readonly<State>) {
		// Reset error state if route has changed
		if (prevState.hasError && this.state.hasError && prevProps.location.key !== this.props.location.key) {
			// eslint-disable-next-line react/no-did-update-set-state
			this.setState({hasError: false});
		}
	}

	componentDidCatch(error: Error) {
		this.setState({error});
	}

	render() {
		if (this.state.hasError) {
			if (this.state.error instanceof ApolloError) {
				const {graphQLErrors} = this.state.error;
				const sessionCodes: string[] = [];
				const otherErrors: Error[] = [];

				// Parse through graphql errors and pull out session codes and other errors
				graphQLErrors.forEach(v => {
					switch (v.extensions?.code) {
						case 'INTERNAL_SERVER_ERROR':
							// @ts-ignore
							if (v.extensions.exception.session) {
								// @ts-ignore
								sessionCodes.push(v.extensions.exception.session);
							} else {
								otherErrors.push(v);
							}
							break;
						case 'GRAPHQL_VALIDATION_FAILED':
							otherErrors.push(v);
							break;
						default:
							otherErrors.push(v);
					}
				});

				// Prepare header message
				const header = sessionCodes.length + otherErrors.length > 1 ? 'Errors have occurred' : 'An error has occurred';

				// Prepare session code snippet
				let sessionError = null;
				if (sessionCodes.length > 1) {
					sessionError = (
						<>
							<p>Contact tech support with the following error codes:</p>
							<ul>
								{sessionCodes.map(code => (
									<li key={code}>
										<code style={{fontWeight: 'bold'}}>{code}</code>
									</li>
								))}
							</ul>
						</>
					);
				} else if (sessionCodes.length === 1) {
					sessionError = (
						<p>
							Contact tech support with the following error code: <code style={{fontWeight: 'bold'}}>{sessionCodes[0]}</code>
						</p>
					);
				}

				// Prepare other error snippet
				let otherError = null;
				if (otherErrors.length > 0) {
					const str = otherErrors.map(e => JSON.stringify(e, null, '\t')).join('\n');
					const b64 = compressToBase64(str);
					otherError = (
						<>
							<p>Contact tech support with the following error trace:</p>
							<Grid>
								<GridColumn width={4}>
									<Form>
										<FormField>
											<TextArea readOnly rows={8} value={b64} />
										</FormField>
										<CopyToClipboard
											text={b64}
											onCopy={() => {
												toast.success('Error trace copied to clipboard');
											}}
										>
											<Button>Copy to clipboard</Button>
										</CopyToClipboard>
									</Form>
								</GridColumn>
							</Grid>
						</>
					);
				}

				// Render visible error (codes or traces)
				return (
					<Segment style={errorBoundarySegmentStyle}>
						<Header size="large">{header}</Header>
						{sessionError}
						{otherError}
					</Segment>
				);
			} // end: instanceof ApolloError

			// Render unknown error message (string)
			const message = this.state.error?.message ? (
				<p>
					Error message: <code>{this.state.error.message}</code>
				</p>
			) : null;
			return (
				<Segment style={errorBoundarySegmentStyle}>
					<Header size="large">An error has occurred</Header>
					<p>Please contact tech support.</p>
					{message}
				</Segment>
			);
		}
		return this.props.children;
	}
}

export const ErrorBoundary = withRouter(ErrorBoundaryComponent);
