import * as Apollo from '@apollo/client';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getNotificationsByUserId.graphql';

const defaultOptions = {} as const;
export type GetNotificationsByUserIdQueryVariables = Types.Exact<{
	userId: Types.Scalars['String'];
	limit: Types.Scalars['Int'];
	offset: Types.Scalars['Int'];
}>;

export type GetNotificationsByUserIdQuery = {
	getNotificationsByUserId?: Array<{
		id: string;
		version: number;
		dateTimeRead?: any | null;
		dateTimeCreated: any;
		webRoute?: string | null;
		description: string;
		title: string;
		type: {id: string; name: string; icon: string};
	}> | null;
};

export type GetNotificationsByUserIdType = {
	id: string;
	version: number;
	dateTimeRead?: any | null;
	dateTimeCreated: any;
	webRoute?: string | null;
	description: string;
	title: string;
	type: {id: string; name: string; icon: string};
};

/**
 * __useGetNotificationsByUserIdQuery__
 *
 * To run a query within a React component, call `useGetNotificationsByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationsByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationsByUserIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetNotificationsByUserIdQuery(
	baseOptions: Apollo.QueryHookOptions<GetNotificationsByUserIdQuery, GetNotificationsByUserIdQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetNotificationsByUserIdQuery, GetNotificationsByUserIdQueryVariables>(Operations, options);
}
export function useGetNotificationsByUserIdLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationsByUserIdQuery, GetNotificationsByUserIdQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetNotificationsByUserIdQuery, GetNotificationsByUserIdQueryVariables>(Operations, options);
}
export type GetNotificationsByUserIdQueryHookResult = ReturnType<typeof useGetNotificationsByUserIdQuery>;
export type GetNotificationsByUserIdLazyQueryHookResult = ReturnType<typeof useGetNotificationsByUserIdLazyQuery>;
export type GetNotificationsByUserIdQueryResult = Apollo.QueryResult<GetNotificationsByUserIdQuery, GetNotificationsByUserIdQueryVariables>;
