import {defineRoutes} from '@imperium/router';

export const routes = defineRoutes({
	taxRates: {
		path: '/rates/tax-rates',
		exact: true,
	},
	taxRateEdit: {
		path: `/rates/tax-rates/:taxRateId/edit`,
		params: ['taxRateId'] as const,
	},
	taxRateCreate: {
		path: '/rates/tax-rates/create',
		exact: true,
	},
	fuelTaxRates: {
		path: '/rates/fuel-tax-rates',
		exact: true,
	},
	fuelTaxRateEdit: {
		path: `/rates/fuel-tax-rates/:fuelTaxRateId/edit`,
		params: ['fuelTaxRateId'] as const,
	},
	fuelTaxRateCreate: {
		path: '/rates/fuel-tax-rates/create',
		exact: true,
	},
	minimumWages: {
		path: '/rates/minimum-wages',
		exact: true,
	},
	minimumWageEdit: {
		path: `/rates/minimum-wages/:minimumWageId/edit`,
		params: ['minimumWageId'] as const,
	},
	minimumWageCreate: {
		path: '/rates/minimum-wages/create',
		exact: true,
	},
	pvl: {
		path: '/rates/pvl',
		exact: true,
	},
	pvlCreate: {
		path: '/rates/pvl/create',
		exact: true,
	},
	pvlEdit: {
		path: `/rates/pvl/:pvlId/edit`,
		params: ['pvlId'] as const,
	},
	reductionRate: {
		path: '/rates/reduction-rate',
		exact: true,
	},
	reductionRateCreate: {
		path: '/rates/reduction-rate/create',
		exact: true,
	},
	reductionRateEdit: {
		path: '/rates/reduction-rate/:reductionRateId/edit',
		params: ['reductionRateId'] as const,
	},
	ei: {
		path: '/rates/ei',
		exact: true,
	},
	eiCreate: {
		path: '/rates/ei/create',
		exact: true,
	},
	eiEdit: {
		path: '/rates/ei/:eiId/edit',
		params: ['eiId'] as const,
	},
	accommodationAmount: {
		path: '/rates/accommodation-amount',
		exact: true,
	},
	accommodationAmountCreate: {
		path: '/rates/accommodation-amount/create',
		exact: true,
	},
	accommodationAmountEdit: {
		path: '/rates/accommodation-amount/:accommodationAmount/edit',
		params: ['accommodationAmountId'] as const,
	},
	treasuryAmount: {
		path: '/rates/treasury-amount',
		exact: true,
	},
	treasuryAmountCreate: {
		path: '/rates/treasury-amount/create',
		exact: true,
	},
	treasuryAmountEdit: {
		path: `/rates/treasury-amount/:treasuryAmountId/edit`,
		params: ['treasuryAmountId'] as const,
	},
});
