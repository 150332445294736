import {useDispatch} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import {Button, Dropdown, Form} from 'semantic-ui-react';
import {routes} from '../../routes';
import {setArchivedFinancialAccount, useAccountState} from '../../state';

export default function FinancialAccountsOverviewSidebar() {
	const dispatch = useDispatch();
	const {archivedFinancialAccount} = useAccountState();
	const {accountInfoId} = useParams<typeof routes.types.financialAccounts>();
	const {push} = useHistory();

	return (
		<Form>
			<Form.Input label="Status">
				<Dropdown
					fluid
					selection
					options={[
						{id: 1, text: 'Active', value: false},
						{id: 2, text: 'Archived', value: true},
					]}
					value={archivedFinancialAccount}
					onChange={(_e, val) => dispatch(setArchivedFinancialAccount(val.value as boolean))}
				/>
			</Form.Input>
			<Form.Input>
				<Button fluid positive onClick={() => push(routes.to.createFinancialAccount({accountInfoId}))}>
					Add Financial Account
				</Button>
			</Form.Input>
		</Form>
	);
}
