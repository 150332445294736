import * as Apollo from '@apollo/client';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getPaymentDetails.graphql';

const defaultOptions = {} as const;
export type GetPaymentDetailsQueryVariables = Types.Exact<{
	paymentId: Types.Scalars['String'];
}>;

export type GetPaymentDetailsQuery = {
	getPaymentById?: {id: string; date: any; paymentMethod?: Types.PaymentMethodEnum | null; total: any; reversed: boolean} | null;
};

export type GetPaymentDetailsType = {id: string; date: any; paymentMethod?: Types.PaymentMethodEnum | null; total: any; reversed: boolean};

/**
 * __useGetPaymentDetailsQuery__
 *
 * To run a query within a React component, call `useGetPaymentDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentDetailsQuery({
 *   variables: {
 *      paymentId: // value for 'paymentId'
 *   },
 * });
 */
export function useGetPaymentDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetPaymentDetailsQuery, GetPaymentDetailsQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetPaymentDetailsQuery, GetPaymentDetailsQueryVariables>(Operations, options);
}
export function useGetPaymentDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaymentDetailsQuery, GetPaymentDetailsQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetPaymentDetailsQuery, GetPaymentDetailsQueryVariables>(Operations, options);
}
export type GetPaymentDetailsQueryHookResult = ReturnType<typeof useGetPaymentDetailsQuery>;
export type GetPaymentDetailsLazyQueryHookResult = ReturnType<typeof useGetPaymentDetailsLazyQuery>;
export type GetPaymentDetailsQueryResult = Apollo.QueryResult<GetPaymentDetailsQuery, GetPaymentDetailsQueryVariables>;
