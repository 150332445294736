import * as Apollo from '@apollo/client';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './removePartner.graphql';

const defaultOptions = {} as const;
export type RemovePartnerMutationVariables = Types.Exact<{
	id: Types.Scalars['String'];
	version: Types.Scalars['Int'];
}>;

export type RemovePartnerMutation = {removePartner: string};

export type RemovePartnerType = string;

export type RemovePartnerMutationFn = Apollo.MutationFunction<RemovePartnerMutation, RemovePartnerMutationVariables>;

/**
 * __useRemovePartnerMutation__
 *
 * To run a mutation, you first call `useRemovePartnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePartnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePartnerMutation, { data, loading, error }] = useRemovePartnerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useRemovePartnerMutation(baseOptions?: Apollo.MutationHookOptions<RemovePartnerMutation, RemovePartnerMutationVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<RemovePartnerMutation, RemovePartnerMutationVariables>(Operations, options);
}
export type RemovePartnerMutationHookResult = ReturnType<typeof useRemovePartnerMutation>;
export type RemovePartnerMutationResult = Apollo.MutationResult<RemovePartnerMutation>;
export type RemovePartnerMutationOptions = Apollo.BaseMutationOptions<RemovePartnerMutation, RemovePartnerMutationVariables>;
