import {useParams} from 'react-router-dom';
import {useAsyncError} from '~lib/useAsyncError';
import {SendPasswordResetEmailButton} from '../../../accounts/components/contacts/SendPasswordResetEmailButton';
import type {routes} from '../../routes';
import {useGetUserInfoByIdQuery} from './getUserInfoById';

export function UserResetPasswordButton() {
	const {userId: id} = useParams<typeof routes.types.user>();
	const throwError = useAsyncError();
	const {data, loading, error} = useGetUserInfoByIdQuery({variables: {id}});
	if (error) throwError(error);
	return <SendPasswordResetEmailButton email={data?.getUserById?.email} userId={id} />;
}
