import type {LocalDate} from '@js-joda/core';
import debug from 'debug';
import {useState} from 'react';
import {useParams} from 'react-router-dom';
import {Button, Feed, MessageSizeProp} from 'semantic-ui-react';
import {useCommonState} from '~common/state';
import {CommunicationTypeEnum} from '~core/graphql';
import type {routes as accountRoutes} from '../../../accounts/routes';
import type {routes} from '../../routes';
import {useCommunicationState} from '../../state';
import {CommunicationFeedItem} from '../CommunicationFeedItem';
import {CommunicationFeedItemForm} from '../CommunicationFeedItemForm';
import {useGetCommunicationFeedItemsQuery} from './getCommunicationFeedItems';

const d = debug('tacs.web.communications.components.CommunicationFeed');

interface CommunicationFeedProps {
	limit?: number;
	offset?: number;
	size?: MessageSizeProp;
	startDate?: LocalDate;
	endDate?: LocalDate;
	hideLoadMore?: boolean;
	deletable?: boolean;
}

export default function CommunicationFeed({limit = 50, offset = 0, size, startDate, endDate, hideLoadMore}: CommunicationFeedProps) {
	const {communicationFilter, showCommunicationForm} = useCommunicationState();
	const {accountInfoId, leadId} = useParams<typeof routes.types.communicationFeed & typeof accountRoutes.types.viewLead>();
	const [loadMoreButtonVisible, setLoadMoreButtonVisible] = useState(true);
	const {startDate: sessionStartDate, endDate: sessionEndDate} = useCommonState();

	const {data, fetchMore} = useGetCommunicationFeedItemsQuery({
		variables: {
			accountInfoId,
			leadId,
			filter: communicationFilter,
			limit,
			offset,
			startDate: startDate || sessionStartDate,
			endDate: endDate || sessionEndDate,
		},
	});

	const loadMore = () => {
		if (!loadMoreButtonVisible || !data?.getCommunicationFeedItems || data.getCommunicationFeedItems.length < limit) return null;
		const offsetN: number = data.getCommunicationFeedItems.length;
		return (
			<Button
				color="blue"
				onClick={() =>
					fetchMore({
						variables: {
							accountInfoId,
							filter: communicationFilter,
							limit,
							offset: offsetN,
							startDate,
							endDate,
						},
						updateQuery: (prev, {fetchMoreResult}) => {
							if (!fetchMoreResult?.getCommunicationFeedItems || !prev?.getCommunicationFeedItems) return prev;
							if (fetchMoreResult.getCommunicationFeedItems.length < limit) setLoadMoreButtonVisible(false);
							return {...prev, getCommunicationFeedItems: [...prev.getCommunicationFeedItems, ...fetchMoreResult.getCommunicationFeedItems]};
						},
					})
				}
			>
				Load more
			</Button>
		);
	};

	return (
		<Feed>
			{showCommunicationForm && (
				<CommunicationFeedItemForm
					accountInfoId={accountInfoId}
					leadId={leadId}
					type={CommunicationTypeEnum.Note || CommunicationTypeEnum.CorporateTaxes}
					label="Add a new note"
				/>
			)}
			{data?.getCommunicationFeedItems?.map(entry => {
				return <CommunicationFeedItem entry={entry} key={entry.id} size={size} />;
			})}
			{!hideLoadMore && loadMore()}
		</Feed>
	);
}
