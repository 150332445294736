import * as Apollo from '@apollo/client';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './spacesCleanupCleanAll.graphql';

const defaultOptions = {} as const;
export type SpacesCleanupCleanAllMutationVariables = Types.Exact<{[key: string]: never}>;

export type SpacesCleanupCleanAllMutation = {spacesCleanupCleanAll?: Array<{id: string}> | null};

export type SpacesCleanupCleanAllType = {id: string};

export type SpacesCleanupCleanAllMutationFn = Apollo.MutationFunction<SpacesCleanupCleanAllMutation, SpacesCleanupCleanAllMutationVariables>;

/**
 * __useSpacesCleanupCleanAllMutation__
 *
 * To run a mutation, you first call `useSpacesCleanupCleanAllMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpacesCleanupCleanAllMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spacesCleanupCleanAllMutation, { data, loading, error }] = useSpacesCleanupCleanAllMutation({
 *   variables: {
 *   },
 * });
 */
export function useSpacesCleanupCleanAllMutation(
	baseOptions?: Apollo.MutationHookOptions<SpacesCleanupCleanAllMutation, SpacesCleanupCleanAllMutationVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<SpacesCleanupCleanAllMutation, SpacesCleanupCleanAllMutationVariables>(Operations, options);
}
export type SpacesCleanupCleanAllMutationHookResult = ReturnType<typeof useSpacesCleanupCleanAllMutation>;
export type SpacesCleanupCleanAllMutationResult = Apollo.MutationResult<SpacesCleanupCleanAllMutation>;
export type SpacesCleanupCleanAllMutationOptions = Apollo.BaseMutationOptions<SpacesCleanupCleanAllMutation, SpacesCleanupCleanAllMutationVariables>;
