import debug from 'debug';
import {Link, useParams} from 'react-router-dom';
import {Button} from 'semantic-ui-react';
import {useAsyncError} from '~lib/useAsyncError';
import type {routes as administrationRoutes} from '../../../administration/routes';
import {routes} from '../../routes';
import {useGetTimeCardDatesQuery} from './getTimeCardDates';

const d = debug('tacs.web.user.components.UserActivity.UserActivityButton');

export function UserActivityButton() {
	const throwError = useAsyncError();
	const {userId, timeCardId} = useParams<typeof administrationRoutes.types.timeCardEdit>();
	const {data, error} = useGetTimeCardDatesQuery({variables: {id: timeCardId || ''}});

	if (error) throwError(error);

	return (
		<Link
			to={routes.to.userActivity({
				userId,
				startDate: data?.getTimeCardById.payPeriodStart,
				endDate: data?.getTimeCardById.payPeriodEnd,
			})}
		>
			<Button fluid color="blue" compact>
				Activity for Pay Period
			</Button>
		</Link>
	);
}
