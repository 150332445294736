import {formatMoney, toMoney} from '@thx/money';
import {useParams} from 'react-router-dom';
import {Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow} from 'semantic-ui-react';
import {LinkedTableCell} from '~common/components/LinkedTableCell';
import {routes as accountingRoutes} from '../../../../accounting/routes';
import type {routes} from '../../../routes';
import {useGetJournalEntriesByDocumentIdQuery} from './getJournalEntriesByDocumentId';

export function DocumentSidebarJournalEntries() {
	const {documentId, accountInfoId} = useParams<typeof routes.types.documentViewer>();
	const {data, error, loading} = useGetJournalEntriesByDocumentIdQuery({variables: {documentId}});

	if (loading) return null;
	if (error) throw error;
	if (!data?.getJournalEntriesByDocumentId) throw new Error('The unsorted doc meta information could not be loaded');

	return (
		<Table selectable basic="very">
			<TableHeader>
				<TableRow>
					<TableHeaderCell>GL Account</TableHeaderCell>
					<TableHeaderCell>Debit</TableHeaderCell>
					<TableHeaderCell>Credit</TableHeaderCell>
				</TableRow>
			</TableHeader>
			<TableBody>
				{data?.getJournalEntriesByDocumentId?.map(journalEntry => {
					return (
						<>
							{journalEntry.journalEntryLines?.map(line => {
								return (
									<TableRow key={line.id}>
										<LinkedTableCell
											to={accountingRoutes.to.editGeneralJournalEntry({accountInfoId, journalEntryId: journalEntry.id})}
											value={line.glAccount.name}
										/>
										<LinkedTableCell
											to={accountingRoutes.to.editGeneralJournalEntry({accountInfoId, journalEntryId: journalEntry.id})}
											value={line.debit.isZero() ? '-' : formatMoney(line.debit)}
										/>
										<LinkedTableCell
											to={accountingRoutes.to.editGeneralJournalEntry({accountInfoId, journalEntryId: journalEntry.id})}
											value={line.credit.isZero() ? '-' : formatMoney(line.credit)}
										/>
									</TableRow>
								);
							})}

							<TableRow>
								<TableCell />
								<LinkedTableCell
									to={accountingRoutes.to.editGeneralJournalEntry({accountInfoId, journalEntryId: journalEntry.id})}
									value={<strong>{formatMoney(journalEntry.journalEntryLines?.reduce((debit, line) => debit.add(line.debit), toMoney(0)))}</strong>}
								/>
								<LinkedTableCell
									to={accountingRoutes.to.editGeneralJournalEntry({accountInfoId, journalEntryId: journalEntry.id})}
									value={
										<strong>{formatMoney(journalEntry.journalEntryLines?.reduce((credit, line) => credit.add(line.credit), toMoney(0)))}</strong>
									}
								/>
							</TableRow>
						</>
					);
				})}
			</TableBody>
		</Table>
	);
}
