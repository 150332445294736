import {useLogout, useAuthenticatedState} from '@imperium/auth-client';
import debug from 'debug';
import {useHistory, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Button} from 'semantic-ui-react';
import {DeleteModal} from '~common/components';
import {useAsyncError} from '~lib/useAsyncError';
import {routes as dashboardRoutes} from '../../../dashboard/routes';
import {useGetUserByIdEditQuery} from '../../graphql/getUserByIdEdit';
import {routes} from '../../routes';
import {useDeleteUserByIdMutation} from './deleteUserById';

const d = debug('tacs.web.user.components.DeleteUserButton');

export function DeleteUserButton() {
	const {userId} = useParams<typeof routes.types.user>();
	const {id} = useAuthenticatedState();
	const {push} = useHistory();
	const logout = useLogout();
	const throwError = useAsyncError();
	const [confirmMutation, {loading: mutationLoading}] = useDeleteUserByIdMutation();

	const {data, loading, error} = useGetUserByIdEditQuery({
		variables: {
			userId: userId || '',
		},
	});

	if (error) throw error;

	function onConfirmClicked() {
		confirmMutation({
			variables: {id: data?.getUserById?.id || '', version: data?.getUserById?.version || 0},
		})
			.then(() => {
				toast.success('The user account was deleted.');
				if (userId === id) {
					logout().catch(throwError);
					push(routes.to.login());
				} else {
					push(dashboardRoutes.to.dashboard());
				}
			})
			.catch(throwError);
	}

	return (
		<DeleteModal
			key={userId}
			onDelete={onConfirmClicked}
			headerText="Confirm Delete"
			text="Are you sure you want to delete this user account? This can not be undone."
			trigger={
				<Button disabled={mutationLoading} fluid negative compact>
					Delete User Account
				</Button>
			}
		/>
	);
}
