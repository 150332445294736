import {useAuthenticatedState, useCan} from '@imperium/auth-client';
import {LocalDate} from '@js-joda/core';
import {formatDate} from '@thx/date';
import debug from 'debug';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Button, Card, CardContent, CardDescription, CardGroup, CardHeader, Divider, Grid, Icon, List, Placeholder} from 'semantic-ui-react';
import {ColoredSpan} from '~common/components';
import {DirectorOfficeEnum, Permission} from '~core/graphql';
import {getName} from '~lib/getName';
import CommunicationFeed from '../../../../communications/components/CommunicationFeed';
import {setShowCommunicationForm, useCommunicationState} from '../../../../communications/state';
import type {routes} from '../../../routes';
import AuthorizedRepresentativesCard from '../AuthorizedRepresentativesCard';
import EmployeesCard from '../EmployeesCard';
import RelatedAccountsCard from '../RelatedAccountsCard';
import {useGetCorporateAccountInformationQuery} from './getCorporateAccountInformation';

const d = debug('tacs.web.accounts.components.accounts.CorporateAccountInfo');

interface CorporateAccountInfoType {
	directors: {office: string; contact: {givenName: string; surname: string; preferredName?: string | null}}[];
}

export function CorporateAccountInfo() {
	const dispatch = useDispatch();
	const {accountInfoId} = useParams<typeof routes.types.account>();
	const {id} = useAuthenticatedState();
	const {data, loading, error} = useGetCorporateAccountInformationQuery({variables: {id: accountInfoId, currentlyLoggedInUserId: id || ''}});
	const {showCommunicationForm} = useCommunicationState();
	const [readAllAccount] = useCan([Permission.ReadAllAccount]);

	if (error) throw error;
	if (loading || !data?.getCorporateAccountByAccountInfoId)
		return (
			<Placeholder>
				<Placeholder.Line />
				<Placeholder.Line />
				<Placeholder.Line />
				<Placeholder.Line />
				<Placeholder.Line />
			</Placeholder>
		);
	const {directors, accountInfo, yearEnd, gstFilingFrequency, communicationCode, incorporationDate} = data.getCorporateAccountByAccountInfoId;

	const communicationEmail = communicationCode
		? `mymc-${accountInfo.friendlyId.toString().padStart(4, '0')}${communicationCode}@makingyourmilescount.com`
		: undefined;

	function getDirectorByOffice(office: DirectorOfficeEnum, rectors: CorporateAccountInfoType['directors'] | null | undefined) {
		return rectors?.find(dir => dir?.office === office);
	}

	let president;
	let vicePresident;
	if (directors?.length) {
		president = getDirectorByOffice(DirectorOfficeEnum.President, directors);
		vicePresident = getDirectorByOffice(DirectorOfficeEnum.VicePresident, directors);
	}

	return (
		<>
			<CardGroup>
				<Card>
					<CardContent>
						<CardHeader>
							<Icon name="clipboard" />
							Details
						</CardHeader>
						<Divider />
						<CardDescription>
							<List>
								<List.Item>
									<ColoredSpan labelText="President" contentText={president ? getName(president?.contact) : ''} copyTextOnClick />
								</List.Item>
								<List.Item>
									<ColoredSpan labelText="Vice-President" contentText={vicePresident ? getName(vicePresident?.contact) : ''} copyTextOnClick />
								</List.Item>
								<List.Item />
								<List.Item>
									<ColoredSpan labelText="Business Number" contentText={data?.getCorporateAccountByAccountInfoId.businessNumber} copyTextOnClick />
								</List.Item>
								<List.Item>
									<ColoredSpan
										labelText="Address"
										copyTextOnClick
										contentText={[
											data?.getCorporateAccountByAccountInfoId.accountInfo?.address?.lineOne,
											data?.getCorporateAccountByAccountInfoId.accountInfo?.address?.lineTwo,
											data?.getCorporateAccountByAccountInfoId.accountInfo?.address?.city,
											data?.getCorporateAccountByAccountInfoId.accountInfo?.address?.province,
											data?.getCorporateAccountByAccountInfoId.accountInfo?.address?.postalCode,
										]
											.filter(value => value)
											.join(', ')}
									/>
								</List.Item>
								<List.Item>
									{communicationEmail && <ColoredSpan labelText="Communication Email" contentText={communicationEmail} copyTextOnClick />}
								</List.Item>
								<List.Item>
									<ColoredSpan
										labelText="Incorporation Date"
										copyTextOnClick
										contentText={formatDate(incorporationDate, {date: true, type: 'long', format: 'MMMM dd, yyyy'})}
									/>
								</List.Item>
								<List.Item>
									<ColoredSpan labelText="Year End" contentText={formatDate(yearEnd, {date: true, type: 'long', format: 'MMMM dd'})} />
								</List.Item>
								<List.Item>
									<ColoredSpan labelText="GST Filing Frequency" contentText={gstFilingFrequency || ''} />
								</List.Item>
								<List.Item />
								<List.Item>
									<ColoredSpan labelText="Manager" contentText={accountInfo?.manager?.name} />
								</List.Item>
								<List.Item>
									<ColoredSpan labelText="Default Agent" contentText={accountInfo?.defaultAgent?.name} />
								</List.Item>
								<List.Item>
									<ColoredSpan labelText="Status" contentText={accountInfo.archived ? 'Archived' : 'Active'} />
								</List.Item>
								<List.Item>
									<ColoredSpan labelText="Organization" contentText={accountInfo?.organization?.name} />
								</List.Item>
								<List.Item>
									<ColoredSpan labelText="Office" contentText={accountInfo?.office?.name} />
								</List.Item>
							</List>
						</CardDescription>
					</CardContent>
				</Card>
				<AuthorizedRepresentativesCard accountInfoId={accountInfoId} />
				<EmployeesCard accountInfoId={accountInfoId} />
				{readAllAccount && <RelatedAccountsCard accountInfoId={accountInfoId} />}
			</CardGroup>
			{readAllAccount && (
				<Grid padded>
					<Grid.Row>
						<Grid.Column>
							<Button color={!showCommunicationForm ? 'blue' : undefined} onClick={() => dispatch(setShowCommunicationForm(!showCommunicationForm))}>
								{!showCommunicationForm ? 'Add Communication' : 'Close Communication'}
							</Button>
							<CommunicationFeed limit={5} startDate={LocalDate.now().minusYears(1)} endDate={LocalDate.now()} hideLoadMore />
						</Grid.Column>
					</Grid.Row>
				</Grid>
			)}
		</>
	);
}
