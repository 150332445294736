import * as Apollo from '@apollo/client';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './markTimeCardUnpaidButton.graphql';

const defaultOptions = {} as const;
export type MarkTimeCardUnpaidButtonMutationVariables = Types.Exact<{
	timeCardId: Types.Scalars['String'];
}>;

export type MarkTimeCardUnpaidButtonMutation = {markTimeCardAsUnpaid: {id: string}};

export type MarkTimeCardUnpaidButtonType = {id: string};

export type MarkTimeCardUnpaidButtonMutationFn = Apollo.MutationFunction<MarkTimeCardUnpaidButtonMutation, MarkTimeCardUnpaidButtonMutationVariables>;

/**
 * __useMarkTimeCardUnpaidButtonMutation__
 *
 * To run a mutation, you first call `useMarkTimeCardUnpaidButtonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkTimeCardUnpaidButtonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markTimeCardUnpaidButtonMutation, { data, loading, error }] = useMarkTimeCardUnpaidButtonMutation({
 *   variables: {
 *      timeCardId: // value for 'timeCardId'
 *   },
 * });
 */
export function useMarkTimeCardUnpaidButtonMutation(
	baseOptions?: Apollo.MutationHookOptions<MarkTimeCardUnpaidButtonMutation, MarkTimeCardUnpaidButtonMutationVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<MarkTimeCardUnpaidButtonMutation, MarkTimeCardUnpaidButtonMutationVariables>(Operations, options);
}
export type MarkTimeCardUnpaidButtonMutationHookResult = ReturnType<typeof useMarkTimeCardUnpaidButtonMutation>;
export type MarkTimeCardUnpaidButtonMutationResult = Apollo.MutationResult<MarkTimeCardUnpaidButtonMutation>;
export type MarkTimeCardUnpaidButtonMutationOptions = Apollo.BaseMutationOptions<
	MarkTimeCardUnpaidButtonMutation,
	MarkTimeCardUnpaidButtonMutationVariables
>;
