import * as Apollo from '@apollo/client';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from '~lib/useConvertMoney/getExchangeRate.graphql';

const defaultOptions = {} as const;
export type GetExchangeRateQueryVariables = Types.Exact<{
	currency: Types.Scalars['String'];
	baseCurrency: Types.Scalars['String'];
	date: Types.Scalars['LocalDate'];
}>;

export type GetExchangeRateQuery = {getExchangeRate: number};

export type GetExchangeRateType = number;

/**
 * __useGetExchangeRateQuery__
 *
 * To run a query within a React component, call `useGetExchangeRateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExchangeRateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExchangeRateQuery({
 *   variables: {
 *      currency: // value for 'currency'
 *      baseCurrency: // value for 'baseCurrency'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetExchangeRateQuery(baseOptions: Apollo.QueryHookOptions<GetExchangeRateQuery, GetExchangeRateQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetExchangeRateQuery, GetExchangeRateQueryVariables>(Operations, options);
}
export function useGetExchangeRateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExchangeRateQuery, GetExchangeRateQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetExchangeRateQuery, GetExchangeRateQueryVariables>(Operations, options);
}
export type GetExchangeRateQueryHookResult = ReturnType<typeof useGetExchangeRateQuery>;
export type GetExchangeRateLazyQueryHookResult = ReturnType<typeof useGetExchangeRateLazyQuery>;
export type GetExchangeRateQueryResult = Apollo.QueryResult<GetExchangeRateQuery, GetExchangeRateQueryVariables>;
