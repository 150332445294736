import * as Apollo from '@apollo/client';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './createDocumentTypeForm.graphql';

const defaultOptions = {} as const;
export type CreateDocumentTypeFormMutationVariables = Types.Exact<{
	data: Types.CreateDocumentTypeInput;
}>;

export type CreateDocumentTypeFormMutation = {createDocumentType?: {id: string; version: number} | null};

export type CreateDocumentTypeFormType = {id: string; version: number};

export type CreateDocumentTypeFormMutationFn = Apollo.MutationFunction<CreateDocumentTypeFormMutation, CreateDocumentTypeFormMutationVariables>;

/**
 * __useCreateDocumentTypeFormMutation__
 *
 * To run a mutation, you first call `useCreateDocumentTypeFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDocumentTypeFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDocumentTypeFormMutation, { data, loading, error }] = useCreateDocumentTypeFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateDocumentTypeFormMutation(
	baseOptions?: Apollo.MutationHookOptions<CreateDocumentTypeFormMutation, CreateDocumentTypeFormMutationVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<CreateDocumentTypeFormMutation, CreateDocumentTypeFormMutationVariables>(Operations, options);
}
export type CreateDocumentTypeFormMutationHookResult = ReturnType<typeof useCreateDocumentTypeFormMutation>;
export type CreateDocumentTypeFormMutationResult = Apollo.MutationResult<CreateDocumentTypeFormMutation>;
export type CreateDocumentTypeFormMutationOptions = Apollo.BaseMutationOptions<
	CreateDocumentTypeFormMutation,
	CreateDocumentTypeFormMutationVariables
>;
