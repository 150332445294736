import * as Apollo from '@apollo/client';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './editAccountFilingFrequency.graphql';

const defaultOptions = {} as const;
export type EditSelfEmployedAccountFilingFrequencyMutationVariables = Types.Exact<{
	accountInfoId: Types.Scalars['String'];
	gstFilingFrequency: Types.GstFilingFrequencyEnum;
}>;

export type EditSelfEmployedAccountFilingFrequencyMutation = {editGSTFilingFrequency?: {id: string; version: number} | null};

export type EditSelfEmployedAccountFilingFrequencyType = {id: string; version: number};

export type EditSelfEmployedAccountFilingFrequencyMutationFn = Apollo.MutationFunction<
	EditSelfEmployedAccountFilingFrequencyMutation,
	EditSelfEmployedAccountFilingFrequencyMutationVariables
>;

/**
 * __useEditSelfEmployedAccountFilingFrequencyMutation__
 *
 * To run a mutation, you first call `useEditSelfEmployedAccountFilingFrequencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditSelfEmployedAccountFilingFrequencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editSelfEmployedAccountFilingFrequencyMutation, { data, loading, error }] = useEditSelfEmployedAccountFilingFrequencyMutation({
 *   variables: {
 *      accountInfoId: // value for 'accountInfoId'
 *      gstFilingFrequency: // value for 'gstFilingFrequency'
 *   },
 * });
 */
export function useEditSelfEmployedAccountFilingFrequencyMutation(
	baseOptions?: Apollo.MutationHookOptions<EditSelfEmployedAccountFilingFrequencyMutation, EditSelfEmployedAccountFilingFrequencyMutationVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<EditSelfEmployedAccountFilingFrequencyMutation, EditSelfEmployedAccountFilingFrequencyMutationVariables>(
		Operations,
		options,
	);
}
export type EditSelfEmployedAccountFilingFrequencyMutationHookResult = ReturnType<typeof useEditSelfEmployedAccountFilingFrequencyMutation>;
export type EditSelfEmployedAccountFilingFrequencyMutationResult = Apollo.MutationResult<EditSelfEmployedAccountFilingFrequencyMutation>;
export type EditSelfEmployedAccountFilingFrequencyMutationOptions = Apollo.BaseMutationOptions<
	EditSelfEmployedAccountFilingFrequencyMutation,
	EditSelfEmployedAccountFilingFrequencyMutationVariables
>;
