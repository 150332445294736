import * as Apollo from '@apollo/client';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './changeVacationPayRateByUserId.graphql';

const defaultOptions = {} as const;
export type ChangeVacationPayRateByUserIdMutationVariables = Types.Exact<{
	userId: Types.Scalars['String'];
	vacationPayRate: Types.Scalars['Int'];
	startingDate: Types.Scalars['LocalDate'];
}>;

export type ChangeVacationPayRateByUserIdMutation = {changeVacationPayRateByUserId: number};

export type ChangeVacationPayRateByUserIdType = number;

export type ChangeVacationPayRateByUserIdMutationFn = Apollo.MutationFunction<
	ChangeVacationPayRateByUserIdMutation,
	ChangeVacationPayRateByUserIdMutationVariables
>;

/**
 * __useChangeVacationPayRateByUserIdMutation__
 *
 * To run a mutation, you first call `useChangeVacationPayRateByUserIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeVacationPayRateByUserIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeVacationPayRateByUserIdMutation, { data, loading, error }] = useChangeVacationPayRateByUserIdMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      vacationPayRate: // value for 'vacationPayRate'
 *      startingDate: // value for 'startingDate'
 *   },
 * });
 */
export function useChangeVacationPayRateByUserIdMutation(
	baseOptions?: Apollo.MutationHookOptions<ChangeVacationPayRateByUserIdMutation, ChangeVacationPayRateByUserIdMutationVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<ChangeVacationPayRateByUserIdMutation, ChangeVacationPayRateByUserIdMutationVariables>(Operations, options);
}
export type ChangeVacationPayRateByUserIdMutationHookResult = ReturnType<typeof useChangeVacationPayRateByUserIdMutation>;
export type ChangeVacationPayRateByUserIdMutationResult = Apollo.MutationResult<ChangeVacationPayRateByUserIdMutation>;
export type ChangeVacationPayRateByUserIdMutationOptions = Apollo.BaseMutationOptions<
	ChangeVacationPayRateByUserIdMutation,
	ChangeVacationPayRateByUserIdMutationVariables
>;
