import * as Apollo from '@apollo/client';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './endEmployeeEmployment.graphql';

const defaultOptions = {} as const;
export type EndEmployeeEmploymentMutationVariables = Types.Exact<{
	data: Types.EndEmployeeEmploymentInput;
}>;

export type EndEmployeeEmploymentMutation = {endEmployeeEmployment?: {id: string; endDate?: any | null; version: number} | null};

export type EndEmployeeEmploymentType = {id: string; endDate?: any | null; version: number};

export type EndEmployeeEmploymentMutationFn = Apollo.MutationFunction<EndEmployeeEmploymentMutation, EndEmployeeEmploymentMutationVariables>;

/**
 * __useEndEmployeeEmploymentMutation__
 *
 * To run a mutation, you first call `useEndEmployeeEmploymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEndEmployeeEmploymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [endEmployeeEmploymentMutation, { data, loading, error }] = useEndEmployeeEmploymentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEndEmployeeEmploymentMutation(
	baseOptions?: Apollo.MutationHookOptions<EndEmployeeEmploymentMutation, EndEmployeeEmploymentMutationVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<EndEmployeeEmploymentMutation, EndEmployeeEmploymentMutationVariables>(Operations, options);
}
export type EndEmployeeEmploymentMutationHookResult = ReturnType<typeof useEndEmployeeEmploymentMutation>;
export type EndEmployeeEmploymentMutationResult = Apollo.MutationResult<EndEmployeeEmploymentMutation>;
export type EndEmployeeEmploymentMutationOptions = Apollo.BaseMutationOptions<EndEmployeeEmploymentMutation, EndEmployeeEmploymentMutationVariables>;
