import * as Apollo from '@apollo/client';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './deleteLeadCascade.graphql';

const defaultOptions = {} as const;
export type DeleteLeadCascadeMutationVariables = Types.Exact<{
	leadId: Types.Scalars['String'];
}>;

export type DeleteLeadCascadeMutation = {deleteLeadCascade?: string | null};

export type DeleteLeadCascadeType = string;

export type DeleteLeadCascadeMutationFn = Apollo.MutationFunction<DeleteLeadCascadeMutation, DeleteLeadCascadeMutationVariables>;

/**
 * __useDeleteLeadCascadeMutation__
 *
 * To run a mutation, you first call `useDeleteLeadCascadeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLeadCascadeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLeadCascadeMutation, { data, loading, error }] = useDeleteLeadCascadeMutation({
 *   variables: {
 *      leadId: // value for 'leadId'
 *   },
 * });
 */
export function useDeleteLeadCascadeMutation(
	baseOptions?: Apollo.MutationHookOptions<DeleteLeadCascadeMutation, DeleteLeadCascadeMutationVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<DeleteLeadCascadeMutation, DeleteLeadCascadeMutationVariables>(Operations, options);
}
export type DeleteLeadCascadeMutationHookResult = ReturnType<typeof useDeleteLeadCascadeMutation>;
export type DeleteLeadCascadeMutationResult = Apollo.MutationResult<DeleteLeadCascadeMutation>;
export type DeleteLeadCascadeMutationOptions = Apollo.BaseMutationOptions<DeleteLeadCascadeMutation, DeleteLeadCascadeMutationVariables>;
