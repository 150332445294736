import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Button, type SemanticCOLORS} from 'semantic-ui-react';
import {ConfirmModal} from '~common/components';
import {useAsyncError} from '~lib/useAsyncError';
import type {routes} from '../../routes';
import {useGetTimeCardPaymentStatusQuery} from './getTimeCardPaymentStatus';
import {useMarkTimeCardPaidButtonMutation} from './markTimeCardPaidButton';
import {useMarkTimeCardUnpaidButtonMutation} from './markTimeCardUnpaidButton';

export function MarkTimeCardPaidButton() {
	const throwError = useAsyncError();
	const {timeCardId} = useParams<typeof routes.types.timeCardEdit>();
	const [payTimeCard, {loading: payLoading}] = useMarkTimeCardPaidButtonMutation();
	const [unPayTimeCard, {loading: unPayLoading}] = useMarkTimeCardUnpaidButtonMutation();
	const {data, loading, error} = useGetTimeCardPaymentStatusQuery({variables: {timeCardId}});

	if (error) throw error;

	function handlePay() {
		payTimeCard({
			variables: {timeCardId},
			refetchQueries: ['markTimeCardAsPaid', 'getTimeCardById'],
		})
			.then(async () => {
				toast.success('Time Card was marked as paid');
			})
			.catch(throwError);
	}

	function handleUnPay() {
		unPayTimeCard({
			variables: {timeCardId},
			refetchQueries: ['markTimeCardAsPaid', 'getTimeCardById'],
		})
			.then(async () => {
				toast.success('Time Card was marked as unpaid');
			})
			.catch(throwError);
	}

	const getButtonColor = (): SemanticCOLORS => {
		switch (data?.getTimeCardById.paid) {
			case true:
				return 'orange';
			case false:
				return 'green';
			default:
				return 'grey';
		}
	};

	return (
		<ConfirmModal
			onConfirm={data?.getTimeCardById.paid ? handleUnPay : handlePay}
			trigger={
				<Button loading={!data?.getTimeCardById} fluid compact color={getButtonColor()} disabled={payLoading || unPayLoading}>
					Mark Time Card {data?.getTimeCardById.paid ? 'Unpaid' : 'Paid'}
				</Button>
			}
			text={`Are you sure you want to mark this time card ${data?.getTimeCardById.paid ? 'un' : ''}paid?`}
			headerText={`Confirm ${data?.getTimeCardById.paid ? 'Reverse ' : ''}Payment`}
		/>
	);
}
