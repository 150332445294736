import * as Apollo from '@apollo/client';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getEditFilingFrequency.graphql';

const defaultOptions = {} as const;
export type GetEditFilingFrequencyQueryVariables = Types.Exact<{
	accountInfoId: Types.Scalars['String'];
}>;

export type GetEditFilingFrequencyQuery = {getGstFilingFrequency?: Types.GstFilingFrequencyEnum | null};

export type GetEditFilingFrequencyType = Types.GstFilingFrequencyEnum;

/**
 * __useGetEditFilingFrequencyQuery__
 *
 * To run a query within a React component, call `useGetEditFilingFrequencyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEditFilingFrequencyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEditFilingFrequencyQuery({
 *   variables: {
 *      accountInfoId: // value for 'accountInfoId'
 *   },
 * });
 */
export function useGetEditFilingFrequencyQuery(
	baseOptions: Apollo.QueryHookOptions<GetEditFilingFrequencyQuery, GetEditFilingFrequencyQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetEditFilingFrequencyQuery, GetEditFilingFrequencyQueryVariables>(Operations, options);
}
export function useGetEditFilingFrequencyLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetEditFilingFrequencyQuery, GetEditFilingFrequencyQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetEditFilingFrequencyQuery, GetEditFilingFrequencyQueryVariables>(Operations, options);
}
export type GetEditFilingFrequencyQueryHookResult = ReturnType<typeof useGetEditFilingFrequencyQuery>;
export type GetEditFilingFrequencyLazyQueryHookResult = ReturnType<typeof useGetEditFilingFrequencyLazyQuery>;
export type GetEditFilingFrequencyQueryResult = Apollo.QueryResult<GetEditFilingFrequencyQuery, GetEditFilingFrequencyQueryVariables>;
