import * as Apollo from '@apollo/client';

import Operations from '~common/components/ManagerDropdown/getManagerDropdownManagers.graphql';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

const defaultOptions = {} as const;
export type GetManagerDropdownManagersQueryVariables = Types.Exact<{[key: string]: never}>;

export type GetManagerDropdownManagersQuery = {getActiveAgentsByRoles?: Array<{id: string; version: number; name: string}> | null};

export type GetManagerDropdownManagersType = {id: string; version: number; name: string};

/**
 * __useGetManagerDropdownManagersQuery__
 *
 * To run a query within a React component, call `useGetManagerDropdownManagersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagerDropdownManagersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagerDropdownManagersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetManagerDropdownManagersQuery(
	baseOptions?: Apollo.QueryHookOptions<GetManagerDropdownManagersQuery, GetManagerDropdownManagersQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetManagerDropdownManagersQuery, GetManagerDropdownManagersQueryVariables>(Operations, options);
}
export function useGetManagerDropdownManagersLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetManagerDropdownManagersQuery, GetManagerDropdownManagersQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetManagerDropdownManagersQuery, GetManagerDropdownManagersQueryVariables>(Operations, options);
}
export type GetManagerDropdownManagersQueryHookResult = ReturnType<typeof useGetManagerDropdownManagersQuery>;
export type GetManagerDropdownManagersLazyQueryHookResult = ReturnType<typeof useGetManagerDropdownManagersLazyQuery>;
export type GetManagerDropdownManagersQueryResult = Apollo.QueryResult<GetManagerDropdownManagersQuery, GetManagerDropdownManagersQueryVariables>;
