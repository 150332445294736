import * as Apollo from '@apollo/client';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './deleteSourceDeductionPayment.graphql';

const defaultOptions = {} as const;
export type DeleteSourceDeductionPaymentMutationVariables = Types.Exact<{
	id: Types.Scalars['String'];
	version: Types.Scalars['Int'];
}>;

export type DeleteSourceDeductionPaymentMutation = {deleteSourceDeductionPayment?: string | null};

export type DeleteSourceDeductionPaymentType = string;

export type DeleteSourceDeductionPaymentMutationFn = Apollo.MutationFunction<
	DeleteSourceDeductionPaymentMutation,
	DeleteSourceDeductionPaymentMutationVariables
>;

/**
 * __useDeleteSourceDeductionPaymentMutation__
 *
 * To run a mutation, you first call `useDeleteSourceDeductionPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSourceDeductionPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSourceDeductionPaymentMutation, { data, loading, error }] = useDeleteSourceDeductionPaymentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useDeleteSourceDeductionPaymentMutation(
	baseOptions?: Apollo.MutationHookOptions<DeleteSourceDeductionPaymentMutation, DeleteSourceDeductionPaymentMutationVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<DeleteSourceDeductionPaymentMutation, DeleteSourceDeductionPaymentMutationVariables>(Operations, options);
}
export type DeleteSourceDeductionPaymentMutationHookResult = ReturnType<typeof useDeleteSourceDeductionPaymentMutation>;
export type DeleteSourceDeductionPaymentMutationResult = Apollo.MutationResult<DeleteSourceDeductionPaymentMutation>;
export type DeleteSourceDeductionPaymentMutationOptions = Apollo.BaseMutationOptions<
	DeleteSourceDeductionPaymentMutation,
	DeleteSourceDeductionPaymentMutationVariables
>;
