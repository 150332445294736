import debug from 'debug';
import {Dropdown, DropdownProps} from 'semantic-ui-react';
import {useGetEmployeeDropdownQuery} from '~common/components/EmployeeDropdown/getEmployeeDropdown';

const d = debug('tacs.web.common.components.EmployeeDropdown');

interface EmployeeDropdownProps extends Omit<DropdownProps, 'options' | 'onChange'> {
	onChange?: (managerId: string) => void;
	agentId: string;
}

export function EmployeeDropdown(props: EmployeeDropdownProps) {
	const {onChange, loading, agentId, ...rest} = props;
	const {data, loading: queryLoading, error} = useGetEmployeeDropdownQuery({variables: {agentId}});

	function handleChange(val?: string) {
		if (val && onChange) onChange(val);
	}

	const options = data?.getEmployeesForAgent?.map(employee => {
		return {key: employee.id, value: employee.id, text: `${employee.contact.givenName} ${employee.contact.surname}`};
	});

	return (
		<Dropdown
			search
			{...rest}
			error={!!error}
			loading={loading || queryLoading}
			options={options || []}
			onChange={(e, val) => handleChange(val.value?.toString())}
		/>
	);
}
