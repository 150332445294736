import {formatDate} from '@thx/date';
import {useCommonState} from '~common/state';

export function FilteredByDateRange() {
	const {startDate, endDate} = useCommonState();
	return (
		<strong>
			{formatDate(startDate, {type: 'medium'})} - {formatDate(endDate, {type: 'medium'})}
		</strong>
	);
}
