import debug from 'debug';
import {type SyntheticEvent, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Dropdown, DropdownProps} from 'semantic-ui-react';
import {routes as assetRoutes} from '../../routes';
import {useGetCorporateAssetDropdownQuery} from './getCorporateAssetDropdown';

const d = debug('tacs.web.assets.components.CorporateAssetDropdown');

interface AssetDropdownProps extends Omit<DropdownProps, 'options'> {
	accountInfoId: string;
	onChange?: (value?: string) => void;
	value: string | undefined;
	defaultNull?: boolean;
	capitalCostAllowanceClass?: string;
}

export function CorporateAssetDropdown(props: AssetDropdownProps) {
	const {onChange, accountInfoId, value, capitalCostAllowanceClass, defaultNull, ...rest} = props;
	const {data, error} = useGetCorporateAssetDropdownQuery({
		variables: {accountInfoId, filter: {capitalCostAllowanceClass, archived: false}},
	});
	if (error) throw error;

	const options = data?.getCorporateAssetsByAccountId?.map(asset => {
		return {key: asset.id, value: asset.id, text: ` ${asset.name} ${asset.truckNumber ? `- ${asset.truckNumber}` : ''}`};
	});

	function handleChange(e: SyntheticEvent<HTMLElement, Event>, val: DropdownProps) {
		if (val.value) {
			onChange && onChange(val.value.toString());
		} else {
			onChange && onChange();
		}
	}

	useEffect(() => {
		if (!defaultNull && options?.length && !value) {
			onChange && onChange(options[0].key);
		}
	}, [defaultNull, onChange, options, value]);

	const noData = !options?.length;

	if (!data) return <Dropdown {...rest} options={[]} disabled placeholder="The data couldn't be loaded" />;

	return (
		<Dropdown
			disabled={!data.getCorporateAssetsByAccountId?.length}
			value={value}
			options={
				noData
					? [
							{
								key: 'createAsset',
								content: <Link to={assetRoutes.to.createCorporateAsset({accountInfoId})}>Create Corporate Asset</Link>,
							},
					  ]
					: options
			}
			{...rest}
			onChange={handleChange}
		/>
	);
}
