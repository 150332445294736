import {useAuthenticatedState} from '@imperium/auth-client';
import {LocalDate} from '@js-joda/core';
import {LocalDatePicker} from '@thx/controls';
import {useState} from 'react';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Button, Form, FormInput, Segment} from 'semantic-ui-react';
import type {routes} from '../../routes';
import {useExportArchiveLazyQuery} from './graphql/exportArchive';

export function AccountStatusSidebar() {
	const {id} = useAuthenticatedState();
	const {accountInfoId} = useParams<typeof routes.types.account>();
	const [startDate, setStartDate] = useState<LocalDate | null>(LocalDate.now().minusDays(30));
	const [endDate, setEndDate] = useState<LocalDate | null>(LocalDate.now());

	const [exportFiles, {loading}] = useExportArchiveLazyQuery();

	async function handleExport() {
		if (startDate && endDate && id) {
			await exportFiles({variables: {data: {accountInfoId, startDate, endDate, userId: id}}})
				.then(() => {
					toast.info('Export in progress. Check back later to download your files.');
				})
				.catch();
		}
	}

	return (
		<Form>
			<Segment>
				<FormInput label="Start date">
					<LocalDatePicker value={startDate} onChange={value => setStartDate(value)} maxDate={endDate || LocalDate.now()} />
				</FormInput>
				<FormInput label="End date">
					<LocalDatePicker value={endDate} onChange={value => setEndDate(value)} minDate={startDate || undefined} maxDate={LocalDate.now()} />
				</FormInput>
				<FormInput>
					<Button loading={loading} positive fluid onClick={() => handleExport()}>
						Export Files
					</Button>
				</FormInput>
			</Segment>
		</Form>
	);
}
