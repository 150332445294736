import * as Apollo from '@apollo/client';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getDocumentsTable.graphql';

const defaultOptions = {} as const;
export type GetDocumentsTableQueryVariables = Types.Exact<{
	accountInfoId: Types.Scalars['String'];
	filters: Types.DocumentsFilterInput;
}>;

export type GetDocumentsTableQuery = {
	getDocumentsByAccount?: Array<{
		id: string;
		date: any;
		amount?: any | null;
		status: Types.DocumentStatusEnum;
		cash: boolean;
		version: number;
		type: {id: string; name: string; statement: boolean};
		vendor: {id: string; name: string};
		employee?: {id: string; contact: {id: string; givenName: string; surname: string}} | null;
		corporateAsset?: {id: string; name: string} | null;
	}> | null;
};

export type GetDocumentsTableType = {
	id: string;
	date: any;
	amount?: any | null;
	status: Types.DocumentStatusEnum;
	cash: boolean;
	version: number;
	type: {id: string; name: string; statement: boolean};
	vendor: {id: string; name: string};
	employee?: {id: string; contact: {id: string; givenName: string; surname: string}} | null;
	corporateAsset?: {id: string; name: string} | null;
};

/**
 * __useGetDocumentsTableQuery__
 *
 * To run a query within a React component, call `useGetDocumentsTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentsTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentsTableQuery({
 *   variables: {
 *      accountInfoId: // value for 'accountInfoId'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetDocumentsTableQuery(baseOptions: Apollo.QueryHookOptions<GetDocumentsTableQuery, GetDocumentsTableQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetDocumentsTableQuery, GetDocumentsTableQueryVariables>(Operations, options);
}
export function useGetDocumentsTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocumentsTableQuery, GetDocumentsTableQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetDocumentsTableQuery, GetDocumentsTableQueryVariables>(Operations, options);
}
export type GetDocumentsTableQueryHookResult = ReturnType<typeof useGetDocumentsTableQuery>;
export type GetDocumentsTableLazyQueryHookResult = ReturnType<typeof useGetDocumentsTableLazyQuery>;
export type GetDocumentsTableQueryResult = Apollo.QueryResult<GetDocumentsTableQuery, GetDocumentsTableQueryVariables>;
