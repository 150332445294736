import * as Apollo from '@apollo/client';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getFileInfoById.graphql';

const defaultOptions = {} as const;
export type GetFileInfoByIdQueryVariables = Types.Exact<{
	fileInfoId: Types.Scalars['String'];
}>;

export type GetFileInfoByIdQuery = {
	getFileInfoById?: {
		id: string;
		receiveMethod: Types.ReceiveMethodEnum;
		receivedOn: any;
		submittedBy?: {id: string; name: string} | null;
		accountInfo: {manager?: {id: string} | null};
	} | null;
};

export type GetFileInfoByIdType = {
	id: string;
	receiveMethod: Types.ReceiveMethodEnum;
	receivedOn: any;
	submittedBy?: {id: string; name: string} | null;
	accountInfo: {manager?: {id: string} | null};
};

/**
 * __useGetFileInfoByIdQuery__
 *
 * To run a query within a React component, call `useGetFileInfoByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFileInfoByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFileInfoByIdQuery({
 *   variables: {
 *      fileInfoId: // value for 'fileInfoId'
 *   },
 * });
 */
export function useGetFileInfoByIdQuery(baseOptions: Apollo.QueryHookOptions<GetFileInfoByIdQuery, GetFileInfoByIdQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetFileInfoByIdQuery, GetFileInfoByIdQueryVariables>(Operations, options);
}
export function useGetFileInfoByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFileInfoByIdQuery, GetFileInfoByIdQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetFileInfoByIdQuery, GetFileInfoByIdQueryVariables>(Operations, options);
}
export type GetFileInfoByIdQueryHookResult = ReturnType<typeof useGetFileInfoByIdQuery>;
export type GetFileInfoByIdLazyQueryHookResult = ReturnType<typeof useGetFileInfoByIdLazyQuery>;
export type GetFileInfoByIdQueryResult = Apollo.QueryResult<GetFileInfoByIdQuery, GetFileInfoByIdQueryVariables>;
