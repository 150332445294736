import {LayoutData, useLayoutState} from '@imperium/layout';
import {debug} from 'debug';
import {AccountTypeEnum, Permission} from '~core/graphql';
import {isLoggedIn, needsPermission, needsRoute} from '~lib/permissionHelpers';
import {useAccountState} from '../accounts/state';
import {routes} from './routes';

const d = debug('tacs.web.reports.layout');

export const layout: LayoutData = {
	permissions: [Permission.Sysadmin, Permission.ViewReports, Permission.ViewAdminPayrollAccount],
	primaryMenu: [
		{
			text: 'Reports',
			visible: {...isLoggedIn(), ...needsPermission(Permission.ViewReports)},
			to: routes.to.overviewReport(),
			weight: 6,
		},
	],
	secondaryMenu: [
		{
			text: 'Annual Savings',
			stateSelectorHook: [useLayoutState, useAccountState],
			visible: {
				...needsPermission(Permission.ViewAdminPayrollAccount),
				...needsRoute('account'),
				'state.type': {$in: [AccountTypeEnum.CorporateAccount]},
			},
			to: data => (data.state.params?.accountInfoId ? routes.to.annualSavingsReport({accountInfoId: data.state.params.accountInfoId}) : ''),
			icon: 'money bill alternate outline',
		},
		{
			text: 'Overview',
			visible: {...needsPermission(Permission.ViewReports), ...needsRoute('reports')},
			to: routes.to.overviewReport(),
		},
		{
			text: 'Organization',
			visible: {...needsPermission(Permission.ViewReports), ...needsRoute('reports')},
			to: routes.to.organizationReport(),
		},
		{
			text: 'Converted Lead Source',
			visible: {...needsPermission(Permission.ViewReports), ...needsRoute('reports')},
			to: routes.to.leadSourceReport(),
		},
		{
			text: 'Annual Savings',
			visible: {...needsPermission(Permission.ViewReports), ...needsRoute('reports')},
			dropdown: [
				{
					text: 'Visual Chart',
					icon: 'chart area',
					to: routes.to.annualSavingsGlobalChart(),
				},
				{
					text: 'Detailed Statistics',
					icon: 'table',
					to: routes.to.annualSavingsGlobalStatistics(),
				},
			],
		},
		{
			text: 'Documents',
			visible: {...needsPermission(Permission.ViewReports), ...needsRoute('reports')},
			dropdown: [
				{
					text: 'Document Upload Source',
					to: routes.to.documentUploadSource(),
				},
				{
					text: 'Document Sorter',
					to: routes.to.documentSorter(),
				},
				{
					text: 'File Storage Usage',
					to: routes.to.fileStorageReport(),
				},
				{
					text: 'Missing Files',
					to: routes.to.fileStorageMissingReport(),
				},
				{
					text: 'Users Emailing Paperwork',
					to: routes.to.clientsWhoEmailPaperworkReport(),
				},
			],
		},
		{
			text: 'Receivables',
			visible: {...needsPermission(Permission.ViewReports), ...needsRoute('reports')},
			dropdown: [
				{
					text: 'Accounts without CAFT',
					icon: 'money',
					to: routes.to.receivablesNoCaft(),
				},
				{
					text: 'Accounts without Fees',
					icon: 'money',
					to: routes.to.receivablesNoFees(),
				},
				{
					text: 'Outstanding Accounts',
					icon: 'money',
					to: routes.to.receivablesOutstanding(),
				},
				{
					text: 'CAFT Pending',
					icon: 'money',
					to: routes.to.caftPendingReport(),
				},
			],
		},
	],
};
