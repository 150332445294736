import {useTForm} from '@thx/controls';
import debug from 'debug';
import {useState} from 'react';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Button, Form, FormInput} from 'semantic-ui-react';
import {useAsyncError} from '~lib/useAsyncError';
import type {routes} from '../../routes';
import {AgentRolesDropdown} from '../AgentRolesDropdown';
import {useEditAgentRolesButtonMutationMutation} from './editAgentRolesButtonMutation';
import {useGetAgentRolesButtonAgentQuery} from './getAgentRolesButtonAgent';

const d = debug('tacs.web.user.components.EditAgentRolesButton');

export function EditAgentRolesButton() {
	const throwError = useAsyncError();
	const {userId} = useParams<typeof routes.types.userEdit>();
	const {data, loading, error} = useGetAgentRolesButtonAgentQuery({variables: {userId}});
	const [editMutation, {loading: mutationLoading}] = useEditAgentRolesButtonMutationMutation();
	const [editClicked, setEditClicked] = useState(false);

	const {hasErrors, hasWarnings, handleSubmit, setFieldValue, values, resetForm} = useTForm({
		initialValues: {roles: data?.getAgentByUserId?.roles || []},
		onSubmit: formValues => {
			if (data?.getAgentByUserId?.id && data.getAgentByUserId.version) {
				editMutation({
					variables: {
						data: {
							id: data.getAgentByUserId.id,
							version: data.getAgentByUserId.version,
							roles: formValues.roles,
						},
					},
				})
					.then(() => {
						toast.success('Roles saved.');
						setEditClicked(false);
					})
					.catch(throwError);
			}
		},
		enableReinitialize: true,
	});

	if (error) throwError(error);
	if (!data?.getAgentByUserId?.id) return null;

	if (editClicked) {
		return (
			<Form error={hasErrors} warning={hasWarnings} onSubmit={handleSubmit}>
				<FormInput label="Roles">
					<AgentRolesDropdown value={values.roles} loading={loading} selection multiple onChange={val => setFieldValue('roles', val)} />
				</FormInput>
				<Button disabled={mutationLoading} positive type="submit" floated="right">
					Save
				</Button>
				<Button
					type="button"
					onClick={() => {
						resetForm();
						setEditClicked(false);
					}}
				>
					Cancel
				</Button>
			</Form>
		);
	}

	return (
		<Button fluid color="blue" onClick={() => setEditClicked(true)} compact>
			Edit Roles
		</Button>
	);
}
