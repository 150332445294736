import {defineRoutes} from '@imperium/router';

export const routes = defineRoutes({
	agentUserAdd: {
		path: '/management/organization/:organizationId/offices/:officeId/agent/add',
		params: ['organizationId', 'officeId'] as const,
	},
	officeOverview: {
		path: `/management/organization/:organizationId/offices/:officeId/overview`,
		params: ['organizationId', 'officeId'] as const,
	},
	officeCreate: {
		path: `/management/organization/:organizationId/offices/add`,
		params: ['organizationId'] as const,
	},
	officeEdit: {
		path: `/management/organization/:organizationId/offices/:officeId/edit`,
		params: ['organizationId', 'officeId'] as const,
	},
	organizationOverview: {
		path: `/management/organization/:organizationId/overview`,
		params: ['organizationId'] as const,
	},
	organizationEdit: {
		path: `/management/organization/:organizationId/edit`,
		params: ['organizationId'] as const,
	},
	organizations: {
		path: `/admin/organization`,
		exact: true,
	},
	organizationCreate: {
		path: `/admin/organization/add`,
		exact: true,
	},
	timeCardEdit: {
		path: `/user/:userId/time-cards/:timeCardId/edit`,
		params: ['userId', 'timeCardId'] as const,
	},
	timeCards: {
		path: `/user/:userId/time-cards`,
		params: ['userId'] as const,
	},
});
