import {MoneyInput} from '@thx/controls';
import {useDispatch} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import {Button, Divider, Label, List, Input, Header, Checkbox} from 'semantic-ui-react';
import {VendorDropdown} from '../../../documents/components/documents/VendorDropdown';
import {routes} from '../../routes';
import {setReceivablesFilter, clearReceivablesFilter, useAccountingState} from '../../state';

export function ReceivablesTableSidebar() {
	const {accountInfoId} = useParams<typeof routes.types.receivables>();
	const {receivablesFilter} = useAccountingState();
	const {invoiceNumber, showPaid, showOutstandingFromPreviousYears, customer, fromAmount, toAmount} = receivablesFilter;
	const {push} = useHistory();
	const dispatch = useDispatch();
	return (
		<List>
			<List.Item>
				<Button icon="add" content="Create Receivable" positive fluid onClick={() => push(routes.to.createReceivable({accountInfoId}))} />
			</List.Item>
			<Divider />
			<Header size="small">Filter Receivables</Header>
			<List.Item>
				<Label>Customer</Label>
				<VendorDropdown
					onChange={val => dispatch(setReceivablesFilter({customer: val}))}
					fluid
					tabIndex={0}
					value={customer || ''}
					accountInfoId={accountInfoId}
				/>
			</List.Item>
			<List.Item>
				<Label>Invoice Number</Label>
				<Input
					value={invoiceNumber || ''}
					fluid
					onChange={e => {
						const val = e.target.value;
						dispatch(setReceivablesFilter({invoiceNumber: val}));
					}}
				/>
			</List.Item>
			<List.Item>
				<Label>From Total Amount</Label>
				<MoneyInput
					fluid
					placeholder="From Amount"
					value={fromAmount}
					onChange={value => value && dispatch(setReceivablesFilter({fromAmount: value}))}
				/>
			</List.Item>
			<List.Item>
				<Label>To Total Amount</Label>
				<MoneyInput fluid placeholder="To Amount" value={toAmount} onChange={value => value && dispatch(setReceivablesFilter({toAmount: value}))} />
			</List.Item>
			<List.Item>
				<Checkbox label="Show Paid" checked={showPaid} onChange={(e, checkbox) => dispatch(setReceivablesFilter({showPaid: checkbox.checked}))} />
			</List.Item>
			<List.Item>
				<Checkbox
					label="Include Past Outstanding"
					checked={showOutstandingFromPreviousYears}
					onChange={(e, checkbox) => dispatch(setReceivablesFilter({showOutstandingFromPreviousYears: checkbox.checked}))}
				/>
			</List.Item>
			<List.Item>
				<Button content="Clear Filters" onClick={() => dispatch(clearReceivablesFilter)} />
			</List.Item>
		</List>
	);
}
