import * as Apollo from '@apollo/client';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './changeTicketPriority.graphql';

const defaultOptions = {} as const;
export type ChangeTicketPriorityMutationVariables = Types.Exact<{
	data: Types.ChangePriorityInput;
}>;

export type ChangeTicketPriorityMutation = {changePriority: string};

export type ChangeTicketPriorityType = string;

export type ChangeTicketPriorityMutationFn = Apollo.MutationFunction<ChangeTicketPriorityMutation, ChangeTicketPriorityMutationVariables>;

/**
 * __useChangeTicketPriorityMutation__
 *
 * To run a mutation, you first call `useChangeTicketPriorityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeTicketPriorityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeTicketPriorityMutation, { data, loading, error }] = useChangeTicketPriorityMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useChangeTicketPriorityMutation(
	baseOptions?: Apollo.MutationHookOptions<ChangeTicketPriorityMutation, ChangeTicketPriorityMutationVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<ChangeTicketPriorityMutation, ChangeTicketPriorityMutationVariables>(Operations, options);
}
export type ChangeTicketPriorityMutationHookResult = ReturnType<typeof useChangeTicketPriorityMutation>;
export type ChangeTicketPriorityMutationResult = Apollo.MutationResult<ChangeTicketPriorityMutation>;
export type ChangeTicketPriorityMutationOptions = Apollo.BaseMutationOptions<ChangeTicketPriorityMutation, ChangeTicketPriorityMutationVariables>;
