import {useCan} from '@imperium/auth-client';
import debug from 'debug';
import {useHistory, useParams} from 'react-router-dom';
import {Button, Divider, Header, List} from 'semantic-ui-react';
import {GeneralLedgerAccountGroupEnum, LinkedGlAccountType, Permission} from '~core/graphql';
import {routes} from '../../../routes';
import {useAccountingState} from '../../../state';
import {ChooseLinkedAccount} from '../ChooseLinkedAccount/ChooseLinkedAccount';
import {useEditLinkedAccountMutation} from './editLinkedAccount';
import {useGetChartOfAccountsLinkedQuery} from './getChartOfAccountsLinked';

const d = debug('tacs.web.accounting.components.chartOfAccounts.GeneralLedgerAccountsTableSideBar');

export function GeneralLedgerAccountsTableSideBar() {
	const {push} = useHistory();
	const {accountInfoId} = useParams<typeof routes.types.chartOfAccounts>();
	const [sysadmin] = useCan(Permission.Sysadmin);
	const {historicDataEntryMode} = useAccountingState();
	const {data, loading} = useGetChartOfAccountsLinkedQuery({
		variables: {
			accountInfoId,
		},
	});
	const [editLinkedMutation, {loading: mutationLoading}] = useEditLinkedAccountMutation();

	const needDivider = historicDataEntryMode || sysadmin;

	return (
		<List>
			{historicDataEntryMode && (
				<List.Item>
					<Button fluid color="teal" onClick={() => push(routes.to.openingBalances({accountInfoId}))}>
						Enter Opening Balances
					</Button>
				</List.Item>
			)}
			{needDivider && (
				<List.Item>
					<Divider />
				</List.Item>
			)}
			<List.Item>
				<Header as="h4">Linked Accounts</Header>
			</List.Item>
			<List.Item>
				<ChooseLinkedAccount
					selectionsFromBankAccounts
					glAccounts={data?.getChartOfAccountsByAccountInfoId?.linkedAccounts}
					type={LinkedGlAccountType.Deposit}
					queryLoading={loading}
					name="Default Bank"
					accountInfoId={accountInfoId}
					group={GeneralLedgerAccountGroupEnum.Asset}
					mutationLoading={mutationLoading}
					save={dat => {
						return editLinkedMutation({variables: {data: {...dat, accountInfoId}}});
					}}
				/>
			</List.Item>
			<List.Item>
				<ChooseLinkedAccount
					glAccounts={data?.getChartOfAccountsByAccountInfoId?.linkedAccounts}
					type={LinkedGlAccountType.GstCollected}
					queryLoading={loading}
					name="GST Collected"
					accountInfoId={accountInfoId}
					group={GeneralLedgerAccountGroupEnum.Liability}
					mutationLoading={mutationLoading}
					save={dat => {
						return editLinkedMutation({variables: {data: {...dat, accountInfoId}}});
					}}
				/>
			</List.Item>
			<List.Item>
				<ChooseLinkedAccount
					glAccounts={data?.getChartOfAccountsByAccountInfoId?.linkedAccounts}
					type={LinkedGlAccountType.GstPaid}
					queryLoading={loading}
					name="GST Paid"
					accountInfoId={accountInfoId}
					group={GeneralLedgerAccountGroupEnum.Liability}
					mutationLoading={mutationLoading}
					save={dat => {
						return editLinkedMutation({variables: {data: {...dat, accountInfoId}}});
					}}
				/>
			</List.Item>
			<List.Item>
				<ChooseLinkedAccount
					glAccounts={data?.getChartOfAccountsByAccountInfoId?.linkedAccounts}
					type={LinkedGlAccountType.Receivables}
					queryLoading={loading}
					name="Receivables"
					accountInfoId={accountInfoId}
					group={GeneralLedgerAccountGroupEnum.Asset}
					mutationLoading={mutationLoading}
					save={dat => {
						return editLinkedMutation({variables: {data: {...dat, accountInfoId}}});
					}}
				/>
			</List.Item>
			<List.Item>
				<ChooseLinkedAccount
					glAccounts={data?.getChartOfAccountsByAccountInfoId?.linkedAccounts}
					type={LinkedGlAccountType.Payables}
					queryLoading={loading}
					name="Payables"
					accountInfoId={accountInfoId}
					group={GeneralLedgerAccountGroupEnum.Liability}
					mutationLoading={mutationLoading}
					save={dat => {
						return editLinkedMutation({variables: {data: {...dat, accountInfoId}}});
					}}
				/>
			</List.Item>
		</List>
	);
}
