import * as Apollo from '@apollo/client';

import Operations from '~common/components/FiscalDatePicker/getFiscalDatePickerMinDate.graphql';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

const defaultOptions = {} as const;
export type GetFiscalDatePickerMinDateQueryVariables = Types.Exact<{
	accountInfoId: Types.Scalars['String'];
}>;

export type GetFiscalDatePickerMinDateQuery = {getLastClosedDate?: any | null};

export type GetFiscalDatePickerMinDateType = any;

/**
 * __useGetFiscalDatePickerMinDateQuery__
 *
 * To run a query within a React component, call `useGetFiscalDatePickerMinDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFiscalDatePickerMinDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFiscalDatePickerMinDateQuery({
 *   variables: {
 *      accountInfoId: // value for 'accountInfoId'
 *   },
 * });
 */
export function useGetFiscalDatePickerMinDateQuery(
	baseOptions: Apollo.QueryHookOptions<GetFiscalDatePickerMinDateQuery, GetFiscalDatePickerMinDateQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetFiscalDatePickerMinDateQuery, GetFiscalDatePickerMinDateQueryVariables>(Operations, options);
}
export function useGetFiscalDatePickerMinDateLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetFiscalDatePickerMinDateQuery, GetFiscalDatePickerMinDateQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetFiscalDatePickerMinDateQuery, GetFiscalDatePickerMinDateQueryVariables>(Operations, options);
}
export type GetFiscalDatePickerMinDateQueryHookResult = ReturnType<typeof useGetFiscalDatePickerMinDateQuery>;
export type GetFiscalDatePickerMinDateLazyQueryHookResult = ReturnType<typeof useGetFiscalDatePickerMinDateLazyQuery>;
export type GetFiscalDatePickerMinDateQueryResult = Apollo.QueryResult<GetFiscalDatePickerMinDateQuery, GetFiscalDatePickerMinDateQueryVariables>;
