import {LocalDate} from '@js-joda/core';
import {useParams} from 'react-router-dom';
import {Grid, GridColumn, GridRow} from 'semantic-ui-react';
import {useAsyncError} from '~lib/useAsyncError';
import type {routes} from '../routes';
import {setUserEventOffsetFilter, useUserState} from '../state';
import {UserAndAccountEventFeedPagination} from './UserEventFeed/UserAndAccountEventFeedPagination';
import {useGetNumberOfUserEventsByUserIdQuery} from './UserEventTimeline/getNumberOfUserEventsByUserId';

export function UserEventTimelineHeader() {
	const {userId} = useParams<typeof routes.types.timeline>();
	const {userEventFeatureFilter, userEventOffsetFilter} = useUserState();
	const throwError = useAsyncError();

	const {data, error, loading} = useGetNumberOfUserEventsByUserIdQuery({
		variables: {
			id: userId,
			filter: {
				startDate: LocalDate.now().minusDays(90),
				feature: userEventFeatureFilter,
			},
		},
	});

	if (error) throwError(error);

	const totalPages = data && data.getNumberOfUserEventsByUserId > 100 ? Math.ceil(data.getNumberOfUserEventsByUserId / 100) : 1;

	return (
		<Grid stackable>
			<GridRow stretched columns={2}>
				<GridColumn width={4}>
					<h2 style={{display: 'inline', flex: ''}}>User Timeline</h2>
				</GridColumn>
				<GridColumn textAlign="right" width={12}>
					<GridRow>
						<GridColumn floated="right">
							<UserAndAccountEventFeedPagination
								setOffsetFilter={setUserEventOffsetFilter}
								offsetFilter={userEventOffsetFilter}
								numberOfResults={data?.getNumberOfUserEventsByUserId}
								totalPages={totalPages}
							/>
						</GridColumn>
					</GridRow>
				</GridColumn>
			</GridRow>
		</Grid>
	);
}
