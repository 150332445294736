import * as Apollo from '@apollo/client';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './editAgentRolesButtonMutation.graphql';

const defaultOptions = {} as const;
export type EditAgentRolesButtonMutationMutationVariables = Types.Exact<{
	data: Types.EditAgentInput;
}>;

export type EditAgentRolesButtonMutationMutation = {editAgent?: {id: string; version: number} | null};

export type EditAgentRolesButtonMutationType = {id: string; version: number};

export type EditAgentRolesButtonMutationMutationFn = Apollo.MutationFunction<
	EditAgentRolesButtonMutationMutation,
	EditAgentRolesButtonMutationMutationVariables
>;

/**
 * __useEditAgentRolesButtonMutationMutation__
 *
 * To run a mutation, you first call `useEditAgentRolesButtonMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAgentRolesButtonMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAgentRolesButtonMutationMutation, { data, loading, error }] = useEditAgentRolesButtonMutationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditAgentRolesButtonMutationMutation(
	baseOptions?: Apollo.MutationHookOptions<EditAgentRolesButtonMutationMutation, EditAgentRolesButtonMutationMutationVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<EditAgentRolesButtonMutationMutation, EditAgentRolesButtonMutationMutationVariables>(Operations, options);
}
export type EditAgentRolesButtonMutationMutationHookResult = ReturnType<typeof useEditAgentRolesButtonMutationMutation>;
export type EditAgentRolesButtonMutationMutationResult = Apollo.MutationResult<EditAgentRolesButtonMutationMutation>;
export type EditAgentRolesButtonMutationMutationOptions = Apollo.BaseMutationOptions<
	EditAgentRolesButtonMutationMutation,
	EditAgentRolesButtonMutationMutationVariables
>;
