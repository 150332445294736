import {MoneyInput} from '@thx/controls';
import {useDispatch} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import {Button, Divider, Label, List, Input, Header, Checkbox} from 'semantic-ui-react';
import {VendorDropdown} from '../../../documents/components/documents/VendorDropdown';
import {routes} from '../../routes';
import {setPayablesFilter, clearPayablesFilter, useAccountingState} from '../../state';

export function PayablesTableSidebar() {
	const {accountInfoId} = useParams<typeof routes.types.payables>();
	const {payablesFilter} = useAccountingState();
	const {invoiceNumber, showPaid, showOutstandingFromPreviousYears, vendor, fromAmount, toAmount} = payablesFilter;
	const {push} = useHistory();
	const dispatch = useDispatch();

	return (
		<List>
			<List.Item>
				<Button icon="add" content="Create Payable" positive fluid onClick={() => push(routes.to.createPayable({accountInfoId}))} />
			</List.Item>
			<Divider />
			<Header size="small">Filter Payables</Header>
			<List.Item>
				<Label>Vendor</Label>
				<VendorDropdown
					onChange={val => dispatch(setPayablesFilter({vendor: val}))}
					fluid
					tabIndex={0}
					value={vendor || ''}
					accountInfoId={accountInfoId}
				/>
			</List.Item>
			<List.Item>
				<Label>Invoice Number</Label>
				<Input
					value={invoiceNumber || ''}
					fluid
					onChange={e => {
						const val = e.target.value;
						dispatch(setPayablesFilter({invoiceNumber: val}));
					}}
				/>
			</List.Item>
			<List.Item>
				<Label>From Total Amount</Label>
				<MoneyInput
					fluid
					placeholder="From Amount"
					value={fromAmount}
					onChange={value => {
						value && dispatch(setPayablesFilter({fromAmount: value}));
					}}
				/>
			</List.Item>
			<List.Item>
				<Label>To Total Amount</Label>
				<MoneyInput fluid placeholder="To Amount" value={toAmount} onChange={value => value && dispatch(setPayablesFilter({toAmount: value}))} />
			</List.Item>
			<List.Item>
				<Checkbox label="Show Paid" checked={showPaid} onChange={(e, checkbox) => dispatch(setPayablesFilter({showPaid: checkbox.checked}))} />
			</List.Item>
			<List.Item>
				<Checkbox
					label="Include Past Outstanding"
					checked={showOutstandingFromPreviousYears}
					onChange={(e, checkbox) => dispatch(setPayablesFilter({showOutstandingFromPreviousYears: checkbox.checked}))}
				/>
			</List.Item>
			<List.Item>
				<Button content="Clear Filters" onClick={() => dispatch(clearPayablesFilter())} />
			</List.Item>
		</List>
	);
}
