import * as Apollo from '@apollo/client';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './markSourceDeductionPaymentNSF.graphql';

const defaultOptions = {} as const;
export type MarkSourceDeductionPaymentNsfMutationVariables = Types.Exact<{
	id: Types.Scalars['String'];
	version: Types.Scalars['Int'];
	date: Types.Scalars['LocalDate'];
}>;

export type MarkSourceDeductionPaymentNsfMutation = {markSourceDeductionPaymentNSF?: {id: string; version: number} | null};

export type MarkSourceDeductionPaymentNsfType = {id: string; version: number};

export type MarkSourceDeductionPaymentNsfMutationFn = Apollo.MutationFunction<
	MarkSourceDeductionPaymentNsfMutation,
	MarkSourceDeductionPaymentNsfMutationVariables
>;

/**
 * __useMarkSourceDeductionPaymentNsfMutation__
 *
 * To run a mutation, you first call `useMarkSourceDeductionPaymentNsfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkSourceDeductionPaymentNsfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markSourceDeductionPaymentNsfMutation, { data, loading, error }] = useMarkSourceDeductionPaymentNsfMutation({
 *   variables: {
 *      id: // value for 'id'
 *      version: // value for 'version'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useMarkSourceDeductionPaymentNsfMutation(
	baseOptions?: Apollo.MutationHookOptions<MarkSourceDeductionPaymentNsfMutation, MarkSourceDeductionPaymentNsfMutationVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<MarkSourceDeductionPaymentNsfMutation, MarkSourceDeductionPaymentNsfMutationVariables>(Operations, options);
}
export type MarkSourceDeductionPaymentNsfMutationHookResult = ReturnType<typeof useMarkSourceDeductionPaymentNsfMutation>;
export type MarkSourceDeductionPaymentNsfMutationResult = Apollo.MutationResult<MarkSourceDeductionPaymentNsfMutation>;
export type MarkSourceDeductionPaymentNsfMutationOptions = Apollo.BaseMutationOptions<
	MarkSourceDeductionPaymentNsfMutation,
	MarkSourceDeductionPaymentNsfMutationVariables
>;
