import * as Apollo from '@apollo/client';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './createDirector.graphql';

const defaultOptions = {} as const;
export type CreateDirectorMutationVariables = Types.Exact<{
	data: Types.CreateDirectorDataInput;
}>;

export type CreateDirectorMutation = {createDirector: {id: string; version: number}};

export type CreateDirectorType = {id: string; version: number};

export type CreateDirectorMutationFn = Apollo.MutationFunction<CreateDirectorMutation, CreateDirectorMutationVariables>;

/**
 * __useCreateDirectorMutation__
 *
 * To run a mutation, you first call `useCreateDirectorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDirectorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDirectorMutation, { data, loading, error }] = useCreateDirectorMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateDirectorMutation(baseOptions?: Apollo.MutationHookOptions<CreateDirectorMutation, CreateDirectorMutationVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<CreateDirectorMutation, CreateDirectorMutationVariables>(Operations, options);
}
export type CreateDirectorMutationHookResult = ReturnType<typeof useCreateDirectorMutation>;
export type CreateDirectorMutationResult = Apollo.MutationResult<CreateDirectorMutation>;
export type CreateDirectorMutationOptions = Apollo.BaseMutationOptions<CreateDirectorMutation, CreateDirectorMutationVariables>;
