import * as Apollo from '@apollo/client';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getEditAgentOrganization.graphql';

const defaultOptions = {} as const;
export type GetEditAgentOrganizationQueryVariables = Types.Exact<{
	userId: Types.Scalars['String'];
}>;

export type GetEditAgentOrganizationQuery = {getAgentByUserId?: {id: string; version: number; organization?: {id: string} | null} | null};

export type GetEditAgentOrganizationType = {id: string; version: number; organization?: {id: string} | null};

/**
 * __useGetEditAgentOrganizationQuery__
 *
 * To run a query within a React component, call `useGetEditAgentOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEditAgentOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEditAgentOrganizationQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetEditAgentOrganizationQuery(
	baseOptions: Apollo.QueryHookOptions<GetEditAgentOrganizationQuery, GetEditAgentOrganizationQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetEditAgentOrganizationQuery, GetEditAgentOrganizationQueryVariables>(Operations, options);
}
export function useGetEditAgentOrganizationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetEditAgentOrganizationQuery, GetEditAgentOrganizationQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetEditAgentOrganizationQuery, GetEditAgentOrganizationQueryVariables>(Operations, options);
}
export type GetEditAgentOrganizationQueryHookResult = ReturnType<typeof useGetEditAgentOrganizationQuery>;
export type GetEditAgentOrganizationLazyQueryHookResult = ReturnType<typeof useGetEditAgentOrganizationLazyQuery>;
export type GetEditAgentOrganizationQueryResult = Apollo.QueryResult<GetEditAgentOrganizationQuery, GetEditAgentOrganizationQueryVariables>;
