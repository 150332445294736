import {defineRoutes} from '@imperium/router';

export const routes = defineRoutes({
	annualSavingsReport: {
		path: `/account/:accountInfoId/annual-savings`,
		params: ['accountInfoId'] as const,
	},
	annualSavingsGlobalChart: {
		path: `/reports/annual-savings/chart`,
		exact: true,
	},
	annualSavingsGlobalStatistics: {
		path: `/reports/annual-savings/statistics`,
		exact: true,
	},
	overviewReport: {
		path: `/reports/overview`,
		exact: true,
	},
	organizationReport: {
		path: `/reports/organization`,
		exact: true,
	},
	officeReport: {
		path: `/reports/organization/office/:officeId`,
		params: ['officeId'] as const,
	},
	employeeReport: {
		path: `/reports/organization/office/:officeId/employee/:employeeId`,
		params: ['officeId', 'employeeId'] as const,
	},
	caftPendingReport: {
		path: `/reports/receivables/caft/pending`,
		exact: true,
	},
	receivablesOutstanding: {
		path: `/reports/receivables/outstanding`,
		exact: true,
	},
	receivablesNoCaft: {
		path: `/reports/receivables/no-caft`,
		exact: true,
	},
	receivablesNoFees: {
		path: `/reports/receivables/no-fees`,
		exact: true,
	},
	documentUploadSource: {
		path: `/reports/file-storage/document-upload-source`,
		exact: true,
	},
	documentSorter: {
		path: `/reports/file-storage/document-sorted`,
		exact: true,
	},
	fileStorageReport: {
		path: `/reports/file-storage`,
		exact: true,
	},
	fileStorageMissingReport: {
		path: `/reports/file-storage-missing`,
		exact: true,
	},
	leadSourceReport: {
		path: `/reports/leads/source`,
		exact: true,
	},
	clientsWhoEmailPaperworkReport: {
		path: `/reports/emailed-paperwork`,
		exact: true,
	},
});
