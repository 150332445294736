import * as Apollo from '@apollo/client';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getLeadsTable.graphql';

const defaultOptions = {} as const;
export type GetLeadsTableQueryVariables = Types.Exact<{
	status: Types.LeadStatusEnum;
}>;

export type GetLeadsTableQuery = {
	getLeadsReport: Array<{
		id: string;
		givenName: string;
		surname?: string | null;
		primaryPhone?: string | null;
		email?: string | null;
		status: Types.LeadStatusEnum;
		communicationDate?: any | null;
		source?: Types.ReferralEnum | null;
		details?: string | null;
		createdBy: {id: string; name: string};
	}>;
};

export type GetLeadsTableType = {
	id: string;
	givenName: string;
	surname?: string | null;
	primaryPhone?: string | null;
	email?: string | null;
	status: Types.LeadStatusEnum;
	communicationDate?: any | null;
	source?: Types.ReferralEnum | null;
	details?: string | null;
	createdBy: {id: string; name: string};
};

/**
 * __useGetLeadsTableQuery__
 *
 * To run a query within a React component, call `useGetLeadsTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeadsTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeadsTableQuery({
 *   variables: {
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetLeadsTableQuery(baseOptions: Apollo.QueryHookOptions<GetLeadsTableQuery, GetLeadsTableQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetLeadsTableQuery, GetLeadsTableQueryVariables>(Operations, options);
}
export function useGetLeadsTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLeadsTableQuery, GetLeadsTableQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetLeadsTableQuery, GetLeadsTableQueryVariables>(Operations, options);
}
export type GetLeadsTableQueryHookResult = ReturnType<typeof useGetLeadsTableQuery>;
export type GetLeadsTableLazyQueryHookResult = ReturnType<typeof useGetLeadsTableLazyQuery>;
export type GetLeadsTableQueryResult = Apollo.QueryResult<GetLeadsTableQuery, GetLeadsTableQueryVariables>;
