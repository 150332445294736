import {MoneyCurrencyInput} from '@thx/controls';
import debug from 'debug';
import type Money from 'js-money';
import {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import type {InputProps} from 'semantic-ui-react';
import {useAsyncError} from '~lib/useAsyncError';
import {useGetDocumentAmountInputDocumentTypeLazyQuery} from './getDocumentAmountInputDocumentType';
import onChange = toast.onChange;

const d = debug('tacs.web.documents.components.documents.DocumentAmountInput');

interface DocumentTypeDropdownProps extends Omit<InputProps, 'onChange'> {
	onChange?: (val: Money | undefined) => void;
	documentTypeId: string;
	disabled: boolean;
}

export function DocumentAmountInput(props: DocumentTypeDropdownProps) {
	const throwError = useAsyncError();
	const {documentTypeId, loading: propsLoading, setVendorEnabled, ...rest} = props;
	const [disabled, setDisabled] = useState<boolean>(true);
	const [runQuery, {data, loading}] = useGetDocumentAmountInputDocumentTypeLazyQuery();

	useEffect(() => {
		if (documentTypeId) runQuery({variables: {documentTypeId}}).catch(throwError);
		if (!data?.getDocumentTypeById?.amount || !documentTypeId) {
			onChange(() => undefined);
			setDisabled(true);
		}
		if (data?.getDocumentTypeById?.amount) {
			setDisabled(!data?.getDocumentTypeById?.amount || props.disabled);
		}
	}, [documentTypeId, runQuery, data?.getDocumentTypeById?.amount, throwError, props.disabled]);

	return <MoneyCurrencyInput {...rest} disabled={disabled} loading={propsLoading || loading} fluid />;
}
