import * as Apollo from '@apollo/client';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './removeAuthorizedRepresentative.graphql';

const defaultOptions = {} as const;
export type RemoveAuthorizedRepresentativeMutationVariables = Types.Exact<{
	id: Types.Scalars['String'];
	version: Types.Scalars['Int'];
}>;

export type RemoveAuthorizedRepresentativeMutation = {removeAuthorizedRepresentative?: string | null};

export type RemoveAuthorizedRepresentativeType = string;

export type RemoveAuthorizedRepresentativeMutationFn = Apollo.MutationFunction<
	RemoveAuthorizedRepresentativeMutation,
	RemoveAuthorizedRepresentativeMutationVariables
>;

/**
 * __useRemoveAuthorizedRepresentativeMutation__
 *
 * To run a mutation, you first call `useRemoveAuthorizedRepresentativeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAuthorizedRepresentativeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAuthorizedRepresentativeMutation, { data, loading, error }] = useRemoveAuthorizedRepresentativeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useRemoveAuthorizedRepresentativeMutation(
	baseOptions?: Apollo.MutationHookOptions<RemoveAuthorizedRepresentativeMutation, RemoveAuthorizedRepresentativeMutationVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<RemoveAuthorizedRepresentativeMutation, RemoveAuthorizedRepresentativeMutationVariables>(Operations, options);
}
export type RemoveAuthorizedRepresentativeMutationHookResult = ReturnType<typeof useRemoveAuthorizedRepresentativeMutation>;
export type RemoveAuthorizedRepresentativeMutationResult = Apollo.MutationResult<RemoveAuthorizedRepresentativeMutation>;
export type RemoveAuthorizedRepresentativeMutationOptions = Apollo.BaseMutationOptions<
	RemoveAuthorizedRepresentativeMutation,
	RemoveAuthorizedRepresentativeMutationVariables
>;
