import {createPages} from '@imperium/layout';
import loadable from '@loadable/component';
import {FilteredByDateRange} from '~common/components/FilteredByDateRange';
import {routes} from './routes';

const PersonalTaxesTable = loadable(() => import('./components/PersonalTaxesTable'));
const T1CreateForm = loadable(() => import('./components/T1CreateForm'));
const T1EditForm = loadable(() => import('./components/T1EditForm'));

export const routeProps = createPages(routes, {
	personalTaxes: {
		header: (
			<>
				<h2>Personal Taxes</h2>
				<FilteredByDateRange />
			</>
		),
		content: () => <PersonalTaxesTable />,
		sidebar: [
			{
				to: dat => routes.to.createT1({accountInfoId: dat.params.accountInfoId}),
				color: 'green',
				text: 'File T1',
			},
		],
	},
	createT1: {
		header: 'Create T1',
		content: () => <T1CreateForm />,
	},
	editT1: {
		header: 'Edit T1',
		content: () => <T1EditForm />,
	},
});
