import debug from 'debug';
import {useDispatch} from 'react-redux';
import {Link, useParams} from 'react-router-dom';
import {Button, List} from 'semantic-ui-react';
import {EmployeesDropdown} from '~common/components';
import {routes} from '../../../routes';
import {setSelectedEmployee, setSelectedPayStubs, usePayrollState} from '../../../state';
import {DownloadPayStubButton} from '../DownloadPayStubButton';

const d = debug('tacs.web.payroll.components.payStubs.PayStubTable.PayStubTableSideBar');

export function PayStubTableSideBar() {
	const dispatch = useDispatch();
	const {accountInfoId} = useParams<typeof routes.types.payStubTable>();
	const {selectedEmployee, selectedPayStubs} = usePayrollState();

	return (
		<List>
			<List.Item>
				<EmployeesDropdown
					selection
					fluid
					placeholder="Employee"
					accountInfoId={accountInfoId}
					value={selectedEmployee}
					onChange={employee => {
						if (employee) dispatch(setSelectedEmployee(employee));
						else dispatch(setSelectedEmployee('all'));
					}}
					defaultNull
					clearable
					active
				/>
			</List.Item>
			<List.Item>
				<Link to={routes.to.addPayStub({accountInfoId})}>
					<Button positive fluid>
						Add Pay Stub
					</Button>
				</Link>
			</List.Item>
			<List.Item>
				<DownloadPayStubButton
					payStubIds={selectedPayStubs}
					onDownload={() => {
						dispatch(setSelectedPayStubs([]));
					}}
				/>
			</List.Item>
		</List>
	);
}
