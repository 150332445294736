import * as Apollo from '@apollo/client';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getTimeCardStats.graphql';

const defaultOptions = {} as const;
export type GetBankedHoursVacationQueryVariables = Types.Exact<{
	userId: Types.Scalars['String'];
}>;

export type GetBankedHoursVacationQuery = {
	getAgentByUserId?: {id: string; bankedHours: number; bankedVacationPay: any} | null;
	getLatestTimeCard?: {id: string; vacationPayRate: number; timeCardLines?: Array<{id: string; hourlyPay: any}> | null} | null;
};

export type GetBankedHoursVacationType = {id: string; bankedHours: number; bankedVacationPay: any};

/**
 * __useGetBankedHoursVacationQuery__
 *
 * To run a query within a React component, call `useGetBankedHoursVacationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBankedHoursVacationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBankedHoursVacationQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetBankedHoursVacationQuery(
	baseOptions: Apollo.QueryHookOptions<GetBankedHoursVacationQuery, GetBankedHoursVacationQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetBankedHoursVacationQuery, GetBankedHoursVacationQueryVariables>(Operations, options);
}
export function useGetBankedHoursVacationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetBankedHoursVacationQuery, GetBankedHoursVacationQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetBankedHoursVacationQuery, GetBankedHoursVacationQueryVariables>(Operations, options);
}
export type GetBankedHoursVacationQueryHookResult = ReturnType<typeof useGetBankedHoursVacationQuery>;
export type GetBankedHoursVacationLazyQueryHookResult = ReturnType<typeof useGetBankedHoursVacationLazyQuery>;
export type GetBankedHoursVacationQueryResult = Apollo.QueryResult<GetBankedHoursVacationQuery, GetBankedHoursVacationQueryVariables>;
