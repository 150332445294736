import {createPages} from '@imperium/layout';
import loadable from '@loadable/component';
import {routes} from './routes';

const Dashboard = loadable(() => import('./components/Dashboard'));
const Podcasts = loadable(() => import('./components/Podcasts'));

export const routeProps = createPages(routes, {
	otherUserDashboard: {
		content: () => <Dashboard />,
		full: true,
	},
	dashboard: {
		content: () => <Dashboard />,
		full: true,
	},
	podcastsPage: {
		content: () => <Podcasts />,
		full: true,
	},
});
