import * as Apollo from '@apollo/client';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './deleteCommunicationFeedItem.graphql';

const defaultOptions = {} as const;
export type DeleteCommunicationFeedItemMutationVariables = Types.Exact<{
	id: Types.Scalars['String'];
	version: Types.Scalars['Int'];
}>;

export type DeleteCommunicationFeedItemMutation = {deleteCommunicationFeedItem?: {id: string} | null};

export type DeleteCommunicationFeedItemType = {id: string};

export type DeleteCommunicationFeedItemMutationFn = Apollo.MutationFunction<
	DeleteCommunicationFeedItemMutation,
	DeleteCommunicationFeedItemMutationVariables
>;

/**
 * __useDeleteCommunicationFeedItemMutation__
 *
 * To run a mutation, you first call `useDeleteCommunicationFeedItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommunicationFeedItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommunicationFeedItemMutation, { data, loading, error }] = useDeleteCommunicationFeedItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useDeleteCommunicationFeedItemMutation(
	baseOptions?: Apollo.MutationHookOptions<DeleteCommunicationFeedItemMutation, DeleteCommunicationFeedItemMutationVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<DeleteCommunicationFeedItemMutation, DeleteCommunicationFeedItemMutationVariables>(Operations, options);
}
export type DeleteCommunicationFeedItemMutationHookResult = ReturnType<typeof useDeleteCommunicationFeedItemMutation>;
export type DeleteCommunicationFeedItemMutationResult = Apollo.MutationResult<DeleteCommunicationFeedItemMutation>;
export type DeleteCommunicationFeedItemMutationOptions = Apollo.BaseMutationOptions<
	DeleteCommunicationFeedItemMutation,
	DeleteCommunicationFeedItemMutationVariables
>;
