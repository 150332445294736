import {defineRoutes} from '@imperium/router';

export const routes = defineRoutes({
	corporateTaxes: {
		path: `/account/:accountInfoId/corporate-taxes`,
		params: ['accountInfoId'] as const,
	},
	editFiledT2: {
		path: `/account/:accountInfoId/corporate-taxes/t2/file/:filedT2Id`,
		params: ['accountInfoId', 'filedT2Id'] as const,
	},
	editPaidCorporateTaxes: {
		path: `/account/:accountInfoId/corporate-taxes/pay/:paidCorporateTaxesId`,
		params: ['accountInfoId', 'paidCorporateTaxesId'] as const,
	},
	fileT2: {
		path: `/account/:accountInfoId/corporate-taxes/t2/file`,
		params: ['accountInfoId'] as const,
	},
	payCorporateTaxes: {
		path: `/account/:accountInfoId/corporate-taxes/pay`,
		params: ['accountInfoId'] as const,
	},
	signConsentForm: {
		path: `/account/:accountInfoId/corporate-taxes/sign-consent-form`,
		params: ['accountInfoId'] as const,
	},
	editConsentForm: {
		path: `/account/:accountInfoId/corporate-taxes/:consentFormId/edit-consent-form`,
		params: ['accountInfoId', 'consentFormId'] as const,
	},
	setManagementBonus: {
		path: `/account/:accountInfoId/corporate-taxes/management-bonus/set`,
		params: ['accountInfoId'] as const,
	},
	editManagementBonus: {
		path: `/account/:accountInfoId/corporate-taxes/management-bonus/set/:managementBonusId`,
		params: ['accountInfoId', 'managementBonusId'] as const,
	},
});
