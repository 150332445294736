import {defineRoutes} from '@imperium/router';

export const routes = defineRoutes({
	corporateAssets: {
		path: `/account/:accountInfoId/settings/corporate-assets`,
		params: ['accountInfoId'] as const,
	},
	createCorporateAsset: {
		path: `/account/:accountInfoId/settings/corporate-assets/create`,
		params: ['accountInfoId'] as const,
	},
	editCorporateAsset: {
		path: `/account/:accountInfoId/settings/corporate-assets/:corporateAssetId/edit`,
		params: ['accountInfoId', 'corporateAssetId'] as const,
	},
	personalVehicles: {
		path: `/account/:accountInfoId/settings/personal-vehicles`,
		params: ['accountInfoId'] as const,
	},
	createPersonalVehicle: {
		path: `/account/:accountInfoId/settings/personal-vehicle/create`,
		params: ['accountInfoId'] as const,
	},
	editPersonalVehicle: {
		path: `/account/:accountInfoId/settings/personal-vehicle/edit/:personalVehicleId`,
		params: ['accountInfoId', 'personalVehicleId'] as const,
	},
});
