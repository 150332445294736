import {useTForm} from '@thx/controls';
import debug from 'debug';
import {useState} from 'react';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Button, Form, FormInput} from 'semantic-ui-react';
import {ManagerDropdown} from '~common/components/ManagerDropdown';
import {useAsyncError} from '~lib/useAsyncError';
import type {routes} from '../../../routes';
import {useEditAccountManagerMutation} from './editAccountManager';
import {useGetEditAccountManagerLazyQuery} from './getEditAccountManager';

const d = debug('tacs.web.accounts.components.accounts.EditAccountManager');

export function EditAccountManager() {
	const throwError = useAsyncError();
	const {accountInfoId} = useParams<typeof routes.types.account>();
	const [editMutation, {loading: mutationLoading}] = useEditAccountManagerMutation();
	const [getQuery, {data, loading, error}] = useGetEditAccountManagerLazyQuery({variables: {accountInfoId}});
	const [editClicked, setEditClicked] = useState(false);
	const {hasErrors, hasWarnings, handleSubmit, setFieldValue, values, resetForm} = useTForm({
		initialValues: {managerId: data?.getAccountInfoById?.manager?.id},
		onSubmit: formValues => {
			editMutation({
				variables: {accountInfoId, managerId: formValues.managerId},
			})
				.then(() => {
					toast.success('Manager Saved');
					setEditClicked(false);
				})
				.catch(throwError);
		},
		enableReinitialize: true,
	});

	if (editClicked) {
		if (!loading && !error && !data?.getAccountInfoById) getQuery().catch(throwError);
		return (
			<Form error={hasErrors} warning={hasWarnings} onSubmit={handleSubmit}>
				<FormInput autoFocus loading={loading} label="Manager">
					<ManagerDropdown fluid selection value={values.managerId} onChange={val => setFieldValue('managerId', val)} />
				</FormInput>
				<Button positive type="submit" floated="right">
					Save
				</Button>
				<Button
					type="button"
					onClick={() => {
						resetForm();
						setEditClicked(false);
					}}
				>
					Cancel
				</Button>
			</Form>
		);
	}

	return (
		<Button fluid color="orange" onClick={() => setEditClicked(true)}>
			Change Manager
		</Button>
	);
}
