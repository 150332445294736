import * as Apollo from '@apollo/client';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './changeHourlyPayByUserId.graphql';

const defaultOptions = {} as const;
export type ChangeHourlyPayByUserIdMutationVariables = Types.Exact<{
	userId: Types.Scalars['String'];
	hourlyPay: Types.Scalars['Money'];
	startingDate: Types.Scalars['LocalDate'];
}>;

export type ChangeHourlyPayByUserIdMutation = {changeHourlyPayByUserId: any};

export type ChangeHourlyPayByUserIdType = any;

export type ChangeHourlyPayByUserIdMutationFn = Apollo.MutationFunction<ChangeHourlyPayByUserIdMutation, ChangeHourlyPayByUserIdMutationVariables>;

/**
 * __useChangeHourlyPayByUserIdMutation__
 *
 * To run a mutation, you first call `useChangeHourlyPayByUserIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeHourlyPayByUserIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeHourlyPayByUserIdMutation, { data, loading, error }] = useChangeHourlyPayByUserIdMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      hourlyPay: // value for 'hourlyPay'
 *      startingDate: // value for 'startingDate'
 *   },
 * });
 */
export function useChangeHourlyPayByUserIdMutation(
	baseOptions?: Apollo.MutationHookOptions<ChangeHourlyPayByUserIdMutation, ChangeHourlyPayByUserIdMutationVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<ChangeHourlyPayByUserIdMutation, ChangeHourlyPayByUserIdMutationVariables>(Operations, options);
}
export type ChangeHourlyPayByUserIdMutationHookResult = ReturnType<typeof useChangeHourlyPayByUserIdMutation>;
export type ChangeHourlyPayByUserIdMutationResult = Apollo.MutationResult<ChangeHourlyPayByUserIdMutation>;
export type ChangeHourlyPayByUserIdMutationOptions = Apollo.BaseMutationOptions<
	ChangeHourlyPayByUserIdMutation,
	ChangeHourlyPayByUserIdMutationVariables
>;
