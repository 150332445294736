import {LocalDate} from '@js-joda/core';
import {LocalDatePicker} from '@thx/controls';
import {useHistory, useParams} from 'react-router-dom';
import {Form, FormInput, Header} from 'semantic-ui-react';
import {routes} from '../../routes';

export function UserActivitySidebar() {
	const {push} = useHistory();
	const {userId, startDate, endDate} = useParams<typeof routes.types.userActivity>();

	return (
		<Form>
			<Header>Activity Filter</Header>
			<FormInput label="Start date">
				<LocalDatePicker
					value={LocalDate.parse(startDate)}
					onChange={value => {
						if (value) push(routes.to.userActivity({userId, startDate: value.toString(), endDate}));
					}}
					maxDate={LocalDate.parse(endDate) || LocalDate.now()}
				/>
			</FormInput>
			<FormInput label="End date">
				<LocalDatePicker
					value={LocalDate.parse(endDate)}
					onChange={value => {
						if (value) push(routes.to.userActivity({userId, startDate, endDate: value.toString()}));
					}}
					minDate={LocalDate.parse(startDate) || undefined}
				/>
			</FormInput>
		</Form>
	);
}
