import {useCan} from '@imperium/auth-client';
import {useLayoutState} from '@imperium/layout';
import {useState} from 'react';
import {Link} from 'react-router-dom';
import {Menu, MenuItem, Popup} from 'semantic-ui-react';
import {Permission} from '~core/graphql';
import {routes as administrationRoutes} from '../../administration/routes';
import {routes as supportRoutes} from '../../support/routes';
import {routes} from '../routes';
import {useUserState} from '../state';

export function UserMenuItem() {
	const {userId, userName} = useUserState();
	const {isMobile} = useLayoutState();
	const [open, setOpen] = useState(false);
	const [readAllAccount] = useCan(Permission.ReadAllAccount);

	if (!userId) return null;

	return (
		<Popup
			on={['click']}
			inverted
			content={
				<Menu inverted vertical onClick={() => setOpen(false)}>
					<Link to={routes.to.user({userId})}>
						<MenuItem content="My Profile" />
					</Link>
					{readAllAccount && (
						<>
							<Link to={administrationRoutes.to.timeCards({userId})}>
								<MenuItem content="Time Card" />
							</Link>
							<Link to={routes.to.userReminders({userId})}>
								<MenuItem content="Reminders" />
							</Link>
						</>
					)}
					<Link to={supportRoutes.to.support({userId})}>
						<MenuItem content="Support" />
					</Link>
					<Link to={routes.to.logout()}>
						<MenuItem content="Logout" />
					</Link>
				</Menu>
			}
			open={open}
			onClose={() => setOpen(false)}
			onOpen={() => setOpen(true)}
			trigger={isMobile ? <MenuItem icon="user" /> : <MenuItem content={userName} />}
		/>
	);
}
