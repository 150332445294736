import type {DataHookParams} from '@imperium/layout';
import {LocalDate} from '@js-joda/core';
import {toLocalDate} from '@thx/date';
import debug from 'debug';
import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useGetFiscalDatePickerMinDateQuery} from '~common/components/FiscalDatePicker/getFiscalDatePickerMinDate';
import {setFiscalOpenDate, setHistoricDataEntryMode} from '../../accounting/state';
import type {routes} from '../routes';
import {setAccount} from '../state';
import {useGetAccountStateQuery} from './getAccountState';

const d = debug('tacs.web.accounts.hooks.useGetAccount');

interface HasYearEnd {
	yearEnd: LocalDate;
}

function hasYearEnd(v: any): v is HasYearEnd {
	return !!(v && v.yearEnd && v.yearEnd instanceof LocalDate);
}

interface GetAccount extends DataHookParams {
	routeParams?: typeof routes.types.account;
}

export function useGetAccount({isMatching, routeParams}: GetAccount) {
	const dispatch = useDispatch();
	d(`Rendering useGetAccount hook: ${routeParams?.accountInfoId}, ${isMatching}`);

	useGetAccountStateQuery({
		skip: !isMatching,
		variables: {
			accountInfoId: routeParams?.accountInfoId || '',
		},
		onCompleted: ({getAccountByInfoId, getChartOfAccountsByAccountInfoId}) => {
			d(`Query completed: ${getAccountByInfoId?.accountInfo?.friendlyId}`);

			if (getAccountByInfoId && getAccountByInfoId.account && getAccountByInfoId.accountInfo) {
				const yearEnd = hasYearEnd(getAccountByInfoId.account)
					? getAccountByInfoId.account.yearEnd
					: LocalDate.now().withMonth(12).withDayOfMonth(31);
				dispatch(
					setAccount({
						accountInfoId: getAccountByInfoId.accountInfo.id,
						name: getAccountByInfoId.accountInfo.name,
						friendlyId: getAccountByInfoId.accountInfo.friendlyId,
						type: getAccountByInfoId.accountInfo.type,
						yearEnd,
					}),
				);

				dispatch(setHistoricDataEntryMode(!getChartOfAccountsByAccountInfoId?.historicDataInputComplete));
			}
		},
	});

	useGetFiscalDatePickerMinDateQuery({
		skip: !isMatching,
		variables: {
			accountInfoId: routeParams?.accountInfoId || '',
		},
		onCompleted: data => {
			if (data.getLastClosedDate) {
				dispatch(setFiscalOpenDate(toLocalDate(data.getLastClosedDate).plusDays(1)));
			} else {
				dispatch(setFiscalOpenDate(null));
			}
		},
	});

	useEffect(() => {
		if (!isMatching) {
			d('Setting no account');
			dispatch(setAccount(null));
			dispatch(setHistoricDataEntryMode(true));
			dispatch(setFiscalOpenDate(null));
		}
	}, [dispatch, isMatching]);
}
