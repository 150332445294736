import {formatMoney, toMoney} from '@thx/money';
import {useParams} from 'react-router-dom';
import {Placeholder, Table, TableBody, TableCell, TableRow} from 'semantic-ui-react';
import type {routes} from '../../routes';
import {useGetBankedHoursVacationQuery} from './getBankedHoursVacation';

export function TimeCardStatsTable() {
	const {userId} = useParams<typeof routes.types.timeCards>();
	const {data, loading, error} = useGetBankedHoursVacationQuery({variables: {userId}});

	if (error) throw error;
	if (loading)
		return (
			<Table>
				<TableBody>
					<TableRow>
						<TableCell colSpan={2}>
							<Placeholder>
								<Placeholder.Line />
								<Placeholder.Line />
								<Placeholder.Line />
								<Placeholder.Line />
								<Placeholder.Line />
							</Placeholder>
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		);

	const hourlyPay = data?.getLatestTimeCard?.timeCardLines
		? toMoney(data?.getLatestTimeCard.timeCardLines[data.getLatestTimeCard.timeCardLines.length - 1].hourlyPay)
		: toMoney(0);

	const vacationPayRate = data?.getLatestTimeCard?.vacationPayRate || 0;
	const bankedHours = data?.getAgentByUserId?.bankedHours || 0;
	const bankedVacationPay = toMoney(data?.getAgentByUserId?.bankedVacationPay) || toMoney(0);
	// Rounds to nearest half day
	let vacationDaysAvailable = 0;
	if (bankedVacationPay.isPositive()) {
		vacationDaysAvailable = Math.round(bankedVacationPay.divide(8 * hourlyPay.toDecimal()).toDecimal() / 0.5) * 0.5;
	}
	return (
		<Table>
			<TableBody>
				<TableRow>
					<TableCell>
						<strong>Hourly Pay</strong>
					</TableCell>
					<TableCell>{formatMoney(hourlyPay)}</TableCell>
				</TableRow>
				<TableRow>
					<TableCell>
						<strong>Vacation Rate</strong>
					</TableCell>
					<TableCell>{vacationPayRate}%</TableCell>
				</TableRow>
				<TableRow>
					<TableCell>
						<strong>Banked Hours</strong>
					</TableCell>
					<TableCell>{bankedHours}</TableCell>
				</TableRow>
				<TableRow>
					<TableCell>
						<strong>Banked Vacation Pay</strong>
					</TableCell>
					<TableCell>{formatMoney(bankedVacationPay)}</TableCell>
				</TableRow>
				<TableRow>
					<TableCell>
						<strong>Estimated Vacation Days</strong>
					</TableCell>
					<TableCell>{vacationDaysAvailable}</TableCell>
				</TableRow>
			</TableBody>
		</Table>
	);
}
