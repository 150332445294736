import {useCan} from '@imperium/auth-client';
import {useHistory, useParams} from 'react-router-dom';
import {Tab, TabPane} from 'semantic-ui-react';
import {Permission} from '~core/graphql';
import {useGetDocumentByIdQuery} from '../../graphql/getDocumentById';
import {routes} from '../../routes';
import {DocumentSidebarJournalEntries} from './DocumentSidebarJournalEntries';
import DocumentSidebarStatement from './DocumentSidebarStatement';
import {DocumentSidebarViewer} from './DocumentSidebarViewer';

export function DocumentSidebarTabs() {
	const {push} = useHistory();
	const {documentId, accountInfoId, tab} = useParams<typeof routes.types.documentViewer>();
	const [writeAllAccounts] = useCan(Permission.WriteAllAccount);

	const {data, loading, error} = useGetDocumentByIdQuery({
		variables: {documentId},
	});

	if (loading) return null;
	if (error) throw error;
	if (!data?.getDocumentById) throw new Error('The unsorted doc meta information could not be loaded');

	const panes = [
		{
			menuItem: 'Details',
			render: () => (
				<TabPane>
					<DocumentSidebarViewer />
				</TabPane>
			),
		},
	];
	if (writeAllAccounts) {
		panes.push({
			menuItem: 'Journal Entries',
			render: () => (
				<TabPane>
					<DocumentSidebarJournalEntries />
				</TabPane>
			),
		});
	}

	if (data.getDocumentById.type.statement) {
		panes.push({
			menuItem: 'Statement',
			render: () => (
				<TabPane>
					<DocumentSidebarStatement />
				</TabPane>
			),
		});
	}

	return (
		<Tab
			panes={panes}
			activeIndex={tab}
			onTabChange={(event, target) => push(routes.to.documentViewer({documentId, accountInfoId, tab: target.activeIndex?.toString() || '0'}))}
		/>
	);
}
