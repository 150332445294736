import {useLayoutState} from '@imperium/layout';
import {getFiscalYear} from '@thx/date';
import {Icon, Menu} from 'semantic-ui-react';
import {useCommonState} from '~common/state';
import {useAccountState} from '../../accounts/state';
import {useAccountingState} from '../state';

export function FiscalYearLocked() {
	const {yearEnd} = useAccountState();
	const {fiscalOpenDate} = useAccountingState();
	const {isMobile} = useLayoutState();
	const {endDate} = useCommonState();
	if (isMobile) return null;

	if (fiscalOpenDate && yearEnd && endDate.isBefore(fiscalOpenDate)) {
		return (
			<Menu.Item style={{fontWeight: 'bold'}}>
				<Icon name="lock" />
				FY Closed: {getFiscalYear(endDate, yearEnd)}
			</Menu.Item>
		);
	}

	return null;
}
