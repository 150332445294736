import {createPages} from '@imperium/layout';
import loadable from '@loadable/component';
import {FilteredByDateRange} from '~common/components/FilteredByDateRange';
import {PayStubTableSideBar} from './components/payStubs/PayStubTable/PayStubTableSideBar';
import {SourceDeductionPaymentEditFormSidebar} from './components/sourceDeductions/SourceDeductionPaymentEditFormSidebar';
import {SourceDeductionTableSidebar} from './components/sourceDeductions/sourceDeductionTable/SourceDeductionTableSidebar';
import {routes} from './routes';

const PayStubAddForm = loadable(() => import('./components/payStubs/PayStubAddForm'));
const PayStubEditForm = loadable(() => import('./components/payStubs/PayStubEditForm'));
const PayStubsTable = loadable(() => import('./components/payStubs/PayStubTable'));
const SourceDeductionOpeningBalanceCreateForm = loadable(() => import('./components/sourceDeductions/SourceDeductionOpeningBalanceCreateForm'));
const SourceDeductionOpeningBalanceEditForm = loadable(() => import('./components/sourceDeductions/SourceDeductionOpeningBalanceEditForm'));
const SourceDeductionPayableCreateForm = loadable(() => import('./components/sourceDeductions/SourceDeductionPayableCreateForm'));
const SourceDeductionPayableEditForm = loadable(() => import('./components/sourceDeductions/SourceDeductionPayableEditForm'));
const SourceDeductionPaymentCreateForm = loadable(() => import('./components/sourceDeductions/SourceDeductionPaymentCreateForm'));
const SourceDeductionPaymentEditForm = loadable(() => import('./components/sourceDeductions/SourceDeductionPaymentEditForm'));
const SourceDeductionTable = loadable(() => import('./components/sourceDeductions/sourceDeductionTable/SourceDeductionTable'));
const SourceDeductionStatementBalanceCreateForm = loadable(() => import('./components/sourceDeductions/SourceDeductionStatementBalanceCreateForm'));
const SourceDeductionStatementBalanceEditForm = loadable(() => import('./components/sourceDeductions/SourceDeductionStatementBalanceEditForm'));
const T4CreateForm = loadable(() => import('./components/t4/T4CreateForm'));
const T4EditForm = loadable(() => import('./components/t4/T4EditForm'));
const T4sTable = loadable(() => import('./components/t4/T4sTable'));
const T4SummaryTable = loadable(() => import('./components/T4SummaryTable'));

export const routeProps = createPages(routes, {
	payStubTable: {
		header: (
			<>
				<h2>Pay Stubs</h2>
				<FilteredByDateRange />
			</>
		),
		content: () => <PayStubsTable />,
		sidebar: [{render: PayStubTableSideBar}],
	},
	addPayStub: {
		header: 'Add Pay Stub',
		content: () => <PayStubAddForm />,
	},
	editPayStub: {
		header: 'Edit Pay Stub',
		content: () => <PayStubEditForm />,
	},
	T4Summary: {
		header: (
			<>
				<h2>T4 Summary</h2>
				<FilteredByDateRange />
			</>
		),
		content: () => <T4SummaryTable />,
	},
	sourceDeductionTable: {
		header: (
			<>
				<h2>Source Deductions</h2>
				<FilteredByDateRange />
			</>
		),
		content: () => <SourceDeductionTable />,
		sidebar: [{render: SourceDeductionTableSidebar}],
	},
	addSourceDeductionPayment: {
		header: 'Add Payment',
		content: () => <SourceDeductionPaymentCreateForm />,
	},
	editSourceDeductionPayment: {
		header: 'Edit Payment',
		content: () => <SourceDeductionPaymentEditForm />,
		sidebar: [{render: SourceDeductionPaymentEditFormSidebar}],
	},
	addSourceDeductionPayable: {
		header: 'Create Payable',
		content: () => <SourceDeductionPayableCreateForm />,
	},
	editSourceDeductionPayable: {
		header: 'Edit Payable',
		content: () => <SourceDeductionPayableEditForm />,
	},
	addSourceDeductionOpeningBalance: {
		header: 'Create Opening Balance',
		content: () => <SourceDeductionOpeningBalanceCreateForm />,
	},
	editSourceDeductionOpeningBalance: {
		header: 'Edit Opening Balance',
		content: () => <SourceDeductionOpeningBalanceEditForm />,
	},
	addSourceDeductionStatementBalance: {
		header: 'Create Statement Balance',
		content: () => <SourceDeductionStatementBalanceCreateForm />,
	},
	editSourceDeductionStatementBalance: {
		header: 'Edit Statement Balance',
		content: () => <SourceDeductionStatementBalanceEditForm />,
	},
	t4s: {
		header: 'T4s',
		content: () => <T4sTable />,
		sidebar: [
			{
				to: dat => routes.to.createT4({accountInfoId: dat.params.accountInfoId}),
				color: 'green',
				text: 'File T4',
			},
		],
	},
	createT4: {
		header: 'File T4',
		content: () => <T4CreateForm />,
	},
	editT4: {
		header: 'Edit T4',
		content: () => <T4EditForm />,
	},
});
