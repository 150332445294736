import {Link} from 'react-router-dom';
import {Menu} from 'semantic-ui-react';
import {routes} from '../routes';
import styles from './dashboard.module.css';

export function MymcButton() {
	return (
		<Menu.Item fitted="vertically" name="home">
			<Link to={routes.to.dashboard()} style={{fontSize: '2em'}}>
				<span className={styles.orangeText}>MYMC</span>
			</Link>
		</Menu.Item>
	);
}
