import * as Apollo from '@apollo/client';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './deleteAccountCascade.graphql';

const defaultOptions = {} as const;
export type DeleteAccountCascadeMutationVariables = Types.Exact<{
	accountInfoId: Types.Scalars['String'];
}>;

export type DeleteAccountCascadeMutation = {deleteAccountCascade?: string | null};

export type DeleteAccountCascadeType = string;

export type DeleteAccountCascadeMutationFn = Apollo.MutationFunction<DeleteAccountCascadeMutation, DeleteAccountCascadeMutationVariables>;

/**
 * __useDeleteAccountCascadeMutation__
 *
 * To run a mutation, you first call `useDeleteAccountCascadeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccountCascadeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountCascadeMutation, { data, loading, error }] = useDeleteAccountCascadeMutation({
 *   variables: {
 *      accountInfoId: // value for 'accountInfoId'
 *   },
 * });
 */
export function useDeleteAccountCascadeMutation(
	baseOptions?: Apollo.MutationHookOptions<DeleteAccountCascadeMutation, DeleteAccountCascadeMutationVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<DeleteAccountCascadeMutation, DeleteAccountCascadeMutationVariables>(Operations, options);
}
export type DeleteAccountCascadeMutationHookResult = ReturnType<typeof useDeleteAccountCascadeMutation>;
export type DeleteAccountCascadeMutationResult = Apollo.MutationResult<DeleteAccountCascadeMutation>;
export type DeleteAccountCascadeMutationOptions = Apollo.BaseMutationOptions<DeleteAccountCascadeMutation, DeleteAccountCascadeMutationVariables>;
