import * as Apollo from '@apollo/client';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getFinancialDropdownAccounts.graphql';

const defaultOptions = {} as const;
export type GetFinancialDropdownAccountsQueryVariables = Types.Exact<{
	accountInfoId: Types.Scalars['String'];
	filter: Types.FinancialAccountFilterType;
}>;

export type GetFinancialDropdownAccountsQuery = {
	getFinancialAccountByAccountInfoId?: Array<{
		id: string;
		accountNumber: string;
		accountNickname: string;
		type: Types.FinancialAccountTypeEnum;
		archived: boolean;
		institution: {id: string; name: string};
		glAccount?: {id: string; code: number} | null;
	}> | null;
};

export type GetFinancialDropdownAccountsType = {
	id: string;
	accountNumber: string;
	accountNickname: string;
	type: Types.FinancialAccountTypeEnum;
	archived: boolean;
	institution: {id: string; name: string};
	glAccount?: {id: string; code: number} | null;
};

/**
 * __useGetFinancialDropdownAccountsQuery__
 *
 * To run a query within a React component, call `useGetFinancialDropdownAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFinancialDropdownAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFinancialDropdownAccountsQuery({
 *   variables: {
 *      accountInfoId: // value for 'accountInfoId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetFinancialDropdownAccountsQuery(
	baseOptions: Apollo.QueryHookOptions<GetFinancialDropdownAccountsQuery, GetFinancialDropdownAccountsQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetFinancialDropdownAccountsQuery, GetFinancialDropdownAccountsQueryVariables>(Operations, options);
}
export function useGetFinancialDropdownAccountsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetFinancialDropdownAccountsQuery, GetFinancialDropdownAccountsQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetFinancialDropdownAccountsQuery, GetFinancialDropdownAccountsQueryVariables>(Operations, options);
}
export type GetFinancialDropdownAccountsQueryHookResult = ReturnType<typeof useGetFinancialDropdownAccountsQuery>;
export type GetFinancialDropdownAccountsLazyQueryHookResult = ReturnType<typeof useGetFinancialDropdownAccountsLazyQuery>;
export type GetFinancialDropdownAccountsQueryResult = Apollo.QueryResult<
	GetFinancialDropdownAccountsQuery,
	GetFinancialDropdownAccountsQueryVariables
>;
