import * as Apollo from '@apollo/client';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './editAgentOffice.graphql';

const defaultOptions = {} as const;
export type EditAgentOfficeMutationVariables = Types.Exact<{
	data: Types.EditAgentInput;
}>;

export type EditAgentOfficeMutation = {editAgent?: {id: string} | null};

export type EditAgentOfficeType = {id: string};

export type EditAgentOfficeMutationFn = Apollo.MutationFunction<EditAgentOfficeMutation, EditAgentOfficeMutationVariables>;

/**
 * __useEditAgentOfficeMutation__
 *
 * To run a mutation, you first call `useEditAgentOfficeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAgentOfficeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAgentOfficeMutation, { data, loading, error }] = useEditAgentOfficeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditAgentOfficeMutation(baseOptions?: Apollo.MutationHookOptions<EditAgentOfficeMutation, EditAgentOfficeMutationVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<EditAgentOfficeMutation, EditAgentOfficeMutationVariables>(Operations, options);
}
export type EditAgentOfficeMutationHookResult = ReturnType<typeof useEditAgentOfficeMutation>;
export type EditAgentOfficeMutationResult = Apollo.MutationResult<EditAgentOfficeMutation>;
export type EditAgentOfficeMutationOptions = Apollo.BaseMutationOptions<EditAgentOfficeMutation, EditAgentOfficeMutationVariables>;
