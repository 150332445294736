import * as Apollo from '@apollo/client';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getVacationPayRate.graphql';

const defaultOptions = {} as const;
export type GetVacationPayRateQueryVariables = Types.Exact<{
	userId: Types.Scalars['String'];
}>;

export type GetVacationPayRateQuery = {getLatestTimeCard?: {id: string; vacationPayRate: number} | null};

export type GetVacationPayRateType = {id: string; vacationPayRate: number};

/**
 * __useGetVacationPayRateQuery__
 *
 * To run a query within a React component, call `useGetVacationPayRateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVacationPayRateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVacationPayRateQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetVacationPayRateQuery(baseOptions: Apollo.QueryHookOptions<GetVacationPayRateQuery, GetVacationPayRateQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetVacationPayRateQuery, GetVacationPayRateQueryVariables>(Operations, options);
}
export function useGetVacationPayRateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVacationPayRateQuery, GetVacationPayRateQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetVacationPayRateQuery, GetVacationPayRateQueryVariables>(Operations, options);
}
export type GetVacationPayRateQueryHookResult = ReturnType<typeof useGetVacationPayRateQuery>;
export type GetVacationPayRateLazyQueryHookResult = ReturnType<typeof useGetVacationPayRateLazyQuery>;
export type GetVacationPayRateQueryResult = Apollo.QueryResult<GetVacationPayRateQuery, GetVacationPayRateQueryVariables>;
