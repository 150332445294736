import * as Apollo from '@apollo/client';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getContactByIdInvite.graphql';

const defaultOptions = {} as const;
export type GetContactByIdInviteQueryVariables = Types.Exact<{
	id: Types.Scalars['String'];
}>;

export type GetContactByIdInviteQuery = {
	getContactById?: {
		id: string;
		version: number;
		primaryEmail?: string | null;
		authorizedUser?: {id: string; version: number; email: string} | null;
	} | null;
};

export type GetContactByIdInviteType = {
	id: string;
	version: number;
	primaryEmail?: string | null;
	authorizedUser?: {id: string; version: number; email: string} | null;
};

/**
 * __useGetContactByIdInviteQuery__
 *
 * To run a query within a React component, call `useGetContactByIdInviteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactByIdInviteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactByIdInviteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetContactByIdInviteQuery(baseOptions: Apollo.QueryHookOptions<GetContactByIdInviteQuery, GetContactByIdInviteQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetContactByIdInviteQuery, GetContactByIdInviteQueryVariables>(Operations, options);
}
export function useGetContactByIdInviteLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetContactByIdInviteQuery, GetContactByIdInviteQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetContactByIdInviteQuery, GetContactByIdInviteQueryVariables>(Operations, options);
}
export type GetContactByIdInviteQueryHookResult = ReturnType<typeof useGetContactByIdInviteQuery>;
export type GetContactByIdInviteLazyQueryHookResult = ReturnType<typeof useGetContactByIdInviteLazyQuery>;
export type GetContactByIdInviteQueryResult = Apollo.QueryResult<GetContactByIdInviteQuery, GetContactByIdInviteQueryVariables>;
