import {LayoutData, useLayoutState} from '@imperium/layout';
import {debug} from 'debug';
import {Permission} from '~core/graphql';
import {needsPermission, needsRoute} from '~lib/permissionHelpers';
import {routes} from './routes';

const d = debug('tacs.web.communications.layout');

export const layout: LayoutData = {
	permissions: [Permission.ReadAllAccount, Permission.ViewAdminPayrollAccount],
	primaryMenu: [],
	secondaryMenu: [
		{
			text: 'Communications',
			visible: {...needsPermission([Permission.ReadAllAccount, Permission.ViewAdminPayrollAccount]), ...needsRoute('account')},
			stateSelectorHook: useLayoutState,
			to: data => (data.state.params?.accountInfoId ? routes.to.communicationFeed({accountInfoId: data.state.params.accountInfoId}) : ''),
			icon: 'comments',
		},
	],
};
