import * as Apollo from '@apollo/client';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './statementDelete.graphql';

const defaultOptions = {} as const;
export type StatementDeleteMutationVariables = Types.Exact<{
	id: Types.Scalars['String'];
	version: Types.Scalars['Int'];
}>;

export type StatementDeleteMutation = {deleteStatement?: string | null};

export type StatementDeleteType = string;

export type StatementDeleteMutationFn = Apollo.MutationFunction<StatementDeleteMutation, StatementDeleteMutationVariables>;

/**
 * __useStatementDeleteMutation__
 *
 * To run a mutation, you first call `useStatementDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStatementDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [statementDeleteMutation, { data, loading, error }] = useStatementDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useStatementDeleteMutation(baseOptions?: Apollo.MutationHookOptions<StatementDeleteMutation, StatementDeleteMutationVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<StatementDeleteMutation, StatementDeleteMutationVariables>(Operations, options);
}
export type StatementDeleteMutationHookResult = ReturnType<typeof useStatementDeleteMutation>;
export type StatementDeleteMutationResult = Apollo.MutationResult<StatementDeleteMutation>;
export type StatementDeleteMutationOptions = Apollo.BaseMutationOptions<StatementDeleteMutation, StatementDeleteMutationVariables>;
