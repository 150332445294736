import {useParams} from 'react-router-dom';
import {Grid, GridColumn, GridRow} from 'semantic-ui-react';
import {FilteredByDateRange} from '~common/components/FilteredByDateRange';
import {useCommonState} from '~common/state';
import {useAsyncError} from '~lib/useAsyncError';
import {UserAndAccountEventFeedPagination} from '../../../../user/components/UserEventFeed/UserAndAccountEventFeedPagination';
import type {routes} from '../../../routes';
import {setAccountEventOffsetFilter, useAccountState} from '../../../state';
import {useGetNumberOfUserEventsByAccountIdQuery} from './getNumberOfUserEventsByAccountId';

export function AccountEventTimelineHeader() {
	const {accountInfoId} = useParams<typeof routes.types.timeline>();
	const {startDate, endDate} = useCommonState();
	const {accountEventFeatureFilter, accountEventOffsetFilter} = useAccountState();
	const throwError = useAsyncError();

	const {data, error} = useGetNumberOfUserEventsByAccountIdQuery({
		variables: {
			id: accountInfoId,
			filter: {
				startDate,
				endDate,
				feature: accountEventFeatureFilter,
			},
		},
	});

	if (error) throwError(error);

	const totalPages = data && data.getNumberOfUserEventsByAccountId > 100 ? Math.ceil(data.getNumberOfUserEventsByAccountId / 100) : 1;

	return (
		<Grid stackable>
			<GridRow stretched columns={2}>
				<GridColumn width={4}>
					<h2 style={{display: 'inline', flex: ''}}>Event Timeline</h2>
					<FilteredByDateRange />
				</GridColumn>
				<GridColumn textAlign="right" width={12}>
					<GridRow>
						<GridColumn floated="right">
							<UserAndAccountEventFeedPagination
								setOffsetFilter={setAccountEventOffsetFilter}
								offsetFilter={accountEventOffsetFilter}
								numberOfResults={data?.getNumberOfUserEventsByAccountId}
								totalPages={totalPages}
							/>
						</GridColumn>
					</GridRow>
				</GridColumn>
			</GridRow>
		</Grid>
	);
}
