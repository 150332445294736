import {useState} from 'react';
import {Button, Grid, GridColumn, GridRow, Icon, Modal} from 'semantic-ui-react';

interface ConfirmModalProps {
	onConfirm: () => void;
	headerText: string; // Should be a short phrase like "Confirm Action" -STT
	text: string; // Long phrase like "Are you sure you want to perform this action?" -STT
	trigger: JSX.Element;
}

export function ConfirmModal(props: ConfirmModalProps) {
	const [open, setOpen] = useState(false);
	const {trigger, onConfirm, headerText, text} = props;
	return (
		<Modal open={open} size="small" dimmer trigger={trigger} onClose={() => setOpen(false)} onOpen={() => setOpen(true)}>
			<Modal.Content>
				<Grid>
					<GridRow>
						<GridColumn>
							<h3>
								<Icon color="green" name="checkmark" />
								{headerText}
							</h3>
						</GridColumn>
					</GridRow>
					{text && (
						<GridRow>
							<GridColumn>{text}</GridColumn>
						</GridRow>
					)}
					<GridRow>
						<GridColumn textAlign="right">
							<Button
								color="green"
								onClick={() => {
									setOpen(false);
									onConfirm();
								}}
							>
								<Icon name="checkmark" /> Confirm
							</Button>
							<Button
								color="orange"
								floated="right"
								onClick={() => {
									setOpen(false);
								}}
							>
								<Icon name="dont" /> Cancel
							</Button>
						</GridColumn>
					</GridRow>
				</Grid>
			</Modal.Content>
		</Modal>
	);
}
