import {useCan} from '@imperium/auth-client';
import {formatDate} from '@thx/date';
import {useState} from 'react';
import {useParams, useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Button, Card, Divider, List} from 'semantic-ui-react';
import {ColoredSpan, ConfirmModal} from '~common/components';
import {Permission} from '~core/graphql';
import {useAsyncError} from '~lib/useAsyncError';
import {routes} from '../../../routes';
import {MoveFile} from '../MoveFile';
import {useGetFileInfoByIdQuery} from './getFileInfoById';
import {useSortFileInfoMutation} from './sortFileInfo';

export function FileViewerSidebar() {
	const throwError = useAsyncError();
	const {accountInfoId, fileInfoId} = useParams<typeof routes.types.fileViewer>();
	const [clicked, setClicked] = useState(false);
	const {push} = useHistory();
	const [sortFile] = useSortFileInfoMutation();
	const [readAllAccount] = useCan(Permission.ReadAllAccount);

	const {data, error} = useGetFileInfoByIdQuery({variables: {fileInfoId}});

	if (error) throwError(error);
	if (!data?.getFileInfoById?.accountInfo.manager?.id) return null;

	let moveAction = <Button content="Move file to another account" color="orange" fluid onClick={() => setClicked(true)} />;
	if (clicked) {
		moveAction = (
			<MoveFile
				accountInfoId={accountInfoId}
				fileInfoId={fileInfoId}
				onSave={() => {
					setClicked(false);
					push(routes.to.receivedFiles({accountInfoId}));
				}}
				managerId={data?.getFileInfoById.accountInfo.manager.id}
			/>
		);
	}

	function sort(fileInfo: string) {
		sortFile({variables: {fileInfo}})
			.then(() => {
				toast.success(`This file has has been moved to unsorted files.`);
			})
			.catch(throwError);
	}

	return (
		<List>
			{readAllAccount && (
				<>
					<List.Item>
						<Card>
							<Card.Content>
								<Card.Header>Document Details</Card.Header>
								<Divider />
								<List.Description>
									<List>
										<List.Item>
											<ColoredSpan labelText="Submitted by" contentText={data?.getFileInfoById?.submittedBy?.name || 'Unknown'} />
										</List.Item>
										<List.Item>
											<ColoredSpan labelText="Received by Method" contentText={data?.getFileInfoById?.receiveMethod} />
										</List.Item>
										<List.Item>
											<ColoredSpan labelText="Received on" contentText={formatDate(data?.getFileInfoById?.receivedOn)} />
										</List.Item>
									</List>
								</List.Description>
							</Card.Content>
						</Card>
						<Divider />
					</List.Item>
					<List.Item>
						<ConfirmModal
							onConfirm={() => {
								sort(fileInfoId);
							}}
							headerText="Confirm Sort"
							text="Would you like to move this file back to unsorted?"
							trigger={
								<Button size="small" color="blue" fluid>
									Sort File
								</Button>
							}
						/>
					</List.Item>
					<List.Item>{moveAction}</List.Item>
				</>
			)}
		</List>
	);
}
