import {useDispatch} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import {Button, Dropdown, Form} from 'semantic-ui-react';
import {setArchivedCorporateAsset, useAccountState} from '../../../accounts/state';
import {routes} from '../../routes';

export function CorporateAssetsTableSidebar() {
	const {accountInfoId} = useParams<typeof routes.types.corporateAssets>();
	const {push} = useHistory();
	const dispatch = useDispatch();
	const {archivedCorporateAssets} = useAccountState();

	return (
		<Form>
			<Form.Input label="Status">
				<Dropdown
					fluid
					selection
					options={[
						{id: 1, text: 'Active', value: false},
						{id: 2, text: 'Archived', value: true},
					]}
					value={archivedCorporateAssets}
					onChange={(event, data) => dispatch(setArchivedCorporateAsset(data.value as boolean))}
				/>
			</Form.Input>
			<Form.Input>
				<Button content="Add Corporate Asset" onClick={() => push(routes.to.createCorporateAsset({accountInfoId}))} positive fluid />
			</Form.Input>
		</Form>
	);
}
