import {LayoutData, useLayoutState} from '@imperium/layout';
import {debug} from 'debug';
import {AccountTypeEnum, Permission} from '~core/graphql';
import {isLoggedIn, needsPermission, needsRoute} from '~lib/permissionHelpers';
import {routes as accountingRoutes} from '../accounting/routes';
import {routes as receivableRoutes} from '../receivables/routes';
import {AccountNameStatusBarItem} from './components/accounts/AccountNameStatusBarItem';
import {AccountTypeStatusBarItem} from './components/accounts/AccountTypeStatusBarItem';
import {useGetAccount} from './hooks/useGetAccount';
import {routes} from './routes';
import {useAccountState} from './state';

const d = debug('tacs.web.accounts.layout');

export const layout: LayoutData = {
	permissions: [Permission.Sysadmin, Permission.ReadAllAccount, Permission.ViewAdminPayrollAccount],
	dataHooks: [
		{
			routeMatch: routes.match.account,
			dataHook: useGetAccount,
		},
	],
	statusbar: [
		{
			visible: {...isLoggedIn(), ...needsRoute('account')},
			render: () => <AccountNameStatusBarItem />,
		},
		{
			visible: {...isLoggedIn(), ...needsRoute('account')},
			render: () => <AccountTypeStatusBarItem />,
		},
	],
	primaryMenu: [
		{
			visible: {...isLoggedIn(), ...needsPermission(Permission.Sysadmin)},
			text: 'Admin',
			to: routes.to.unmanagedAccounts,
		},
		{
			visible: {...isLoggedIn(), ...needsPermission(Permission.ReadAllAccount)},
			text: 'Management',
			to: routes.to.leads,
		},
	],
	secondaryMenu: [
		{
			text: 'Account',
			visible: {...isLoggedIn(), ...needsRoute('account')},
			stateSelectorHook: useLayoutState,
			to: data => (data.state.params?.accountInfoId ? routes.to.account({accountInfoId: data.state.params.accountInfoId}) : ''),
			icon: 'address card',
		},
		{
			text: 'Contacts',
			visible: {...isLoggedIn(), ...needsRoute('account')},
			dropdown: [
				{
					text: 'Employees',
					icon: 'users',
					stateSelectorHook: [useLayoutState, useAccountState],
					visible: {'state.type': {$in: [AccountTypeEnum.CorporateAccount, AccountTypeEnum.SelfEmployedAccount]}},
					to: data => (data.state.params?.accountInfoId ? routes.to.employees({accountInfoId: data.state.params.accountInfoId}) : ''),
				},
				{
					text: 'Directors',
					icon: 'user',
					stateSelectorHook: [useLayoutState, useAccountState],
					visible: {'state.type': {$in: [AccountTypeEnum.CorporateAccount]}},
					to: data => (data.state.params?.accountInfoId ? routes.to.directors({accountInfoId: data.state.params.accountInfoId}) : ''),
				},
				{
					text: 'Partners',
					icon: 'user',
					stateSelectorHook: [useLayoutState, useAccountState],
					visible: {'state.type': {$in: [AccountTypeEnum.SelfEmployedAccount]}},
					to: data => (data.state.params?.accountInfoId ? routes.to.partners({accountInfoId: data.state.params.accountInfoId}) : ''),
				},
				{
					text: 'Authorized Representatives',
					icon: 'user secret',
					stateSelectorHook: useLayoutState,
					to: data => (data.state.params?.accountInfoId ? routes.to.authorizedRepresentatives({accountInfoId: data.state.params.accountInfoId}) : ''),
				},
			],
		},
		{
			text: 'Financial Accounts',
			icon: 'building',
			stateSelectorHook: [useLayoutState, useAccountState],
			to: data => (data.state.params?.accountInfoId ? routes.to.financialAccounts({accountInfoId: data.state.params.accountInfoId}) : ''),
			visible: {
				...isLoggedIn(),
				...needsRoute('account'),
				...needsPermission([Permission.ReadAllAccount, Permission.ViewAdminPayrollAccount]),
				'state.type': {$in: [AccountTypeEnum.CorporateAccount, AccountTypeEnum.SelfEmployedAccount]},
			},
		},
		{
			text: 'Settings',
			visible: {...isLoggedIn(), ...needsRoute('account')},
			key: 'settings',
			dropdown: [
				{
					text: 'Account Status',
					icon: 'industry',
					stateSelectorHook: [useLayoutState, useAccountState],
					to: data => (data.state.params?.accountInfoId ? routes.to.accountStatus({accountInfoId: data.state.params.accountInfoId}) : ''),
				},
			],
			weight: 999,
		},
		{
			text: 'Leads',
			visible: {...isLoggedIn(), ...needsRoute('management'), ...needsPermission(Permission.ReadAllAccount)},
			to: routes.to.leads,
		},
		{
			text: "Transfer Agent's Accounts",
			visible: {
				...isLoggedIn(),
				...needsRoute('management'),
				...needsPermission([Permission.ManageAssignedAgents]),
			},
			to: routes.to.agentAccountsTransfer,
		},
		{
			text: "Transfer Manager's Accounts",
			visible: {...isLoggedIn(), ...needsRoute('management'), ...needsPermission(Permission.Sysadmin)},
			to: routes.to.managerAccountsTransfer,
		},
		{
			text: 'Unmanaged Accounts',
			visible: {...isLoggedIn(), ...needsRoute('admin'), ...needsPermission(Permission.Sysadmin)},
			to: routes.to.unmanagedAccounts,
		},
		{
			text: 'Templates',
			visible: {...isLoggedIn(), ...needsRoute('admin'), ...needsPermission(Permission.Sysadmin)},
			dropdown: [
				{
					text: 'Chart of Accounts',
					icon: 'list ul',
					to: accountingRoutes.to.chartOfAccountsTemplatesList,
				},
				{
					text: 'Contracts',
					icon: 'list ol',
					to: receivableRoutes.to.contractTemplates,
				},
			],
		},
	],
};
