import debug from 'debug';
import {sortBy} from 'lodash-es';
import React, {useMemo} from 'react';
import {Dropdown, DropdownItem, DropdownProps, Icon, Popup} from 'semantic-ui-react';
import {
	GetGeneralLedgerAccountsDropdownChartOfAccountsType,
	useGetGeneralLedgerAccountsDropdownChartOfAccountsQuery,
} from '~common/components/GeneralLedgerAccountsDropdown/getGeneralLedgerAccountsDropdownChartOfAccounts';
import {ChartOfAccountsGeneralLedgerAccountsFilterInput, GeneralLedgerAccountGroupEnum, GeneralLedgerAccountTypeEnum, UnArray} from '~core/graphql';

const d = debug('tacs.web.common.components.GeneralLedgerAccountsDropdown');

export type GeneralLedgerAccountSelection = UnArray<NonNullable<GetGeneralLedgerAccountsDropdownChartOfAccountsType['glAccounts']>>;

export type GlAccDropdownHandleChangeInput = string | string[] | undefined;
type UnavailableOptions = string | undefined;

interface GeneralLedgerAccountsDropdownProps extends Omit<DropdownProps, 'onChange'> {
	onChange?: (val?: string | string[] | GeneralLedgerAccountSelection) => void;
	accountInfoId: string;
	accountGroups?: GeneralLedgerAccountGroupEnum[];
	accountTypes?: GeneralLedgerAccountTypeEnum[];
	filterFunction?: (account: GetGeneralLedgerAccountsDropdownChartOfAccountsType) => boolean;
	unavailableOptions?: UnavailableOptions[];
	inputGLAccObjectToOnChange?: boolean;
}

export function GeneralLedgerAccountsDropdown(props: GeneralLedgerAccountsDropdownProps) {
	const {onChange, accountInfoId, accountGroups, accountTypes, filterFunction, unavailableOptions, value, inputGLAccObjectToOnChange, ...rest} =
		props;

	// Provide sane defaults to the filters
	let filter: ChartOfAccountsGeneralLedgerAccountsFilterInput = {
		accountGroups: [
			GeneralLedgerAccountGroupEnum.Asset,
			GeneralLedgerAccountGroupEnum.Liability,
			GeneralLedgerAccountGroupEnum.Equity,
			GeneralLedgerAccountGroupEnum.Revenue,
			GeneralLedgerAccountGroupEnum.Expense,
		],
		accountTypes: [GeneralLedgerAccountTypeEnum.Account, GeneralLedgerAccountTypeEnum.Subaccount],
	};

	if (accountTypes && accountGroups) {
		filter = {accountGroups, accountTypes};
	} else if (accountGroups) {
		filter.accountGroups = accountGroups;
	} else if (accountTypes) {
		filter.accountTypes = accountTypes;
	}

	const {loading, data, error} = useGetGeneralLedgerAccountsDropdownChartOfAccountsQuery({variables: {accountInfoId, filter}});

	function handleChange(selections?: string | string[]) {
		if (onChange) {
			if (inputGLAccObjectToOnChange && !rest.multiple) {
				const glAccount = data?.getChartOfAccountsByAccountInfoId?.glAccounts?.find(gla => gla.id === selections);
				if (glAccount) onChange(glAccount);
			} else onChange(selections);
		}
	}

	let options = data?.getChartOfAccountsByAccountInfoId?.glAccounts;

	if (filterFunction) {
		options = data?.getChartOfAccountsByAccountInfoId?.glAccounts?.filter(filterFunction) || [];
	}
	if (unavailableOptions) {
		options = options?.filter(glAccount => {
			return !unavailableOptions.includes(glAccount.id);
		});
	}

	const dropdownOptions = useMemo(() => {
		return (
			options?.reduce((acc, glAccount) => {
				if (glAccount)
					acc.push({
						key: glAccount.id,
						value: glAccount.id,
						text: `${glAccount.code} - ${glAccount.name}`,
						content: (
							<DropdownItem>
								{glAccount.generalLedgerAccountTemplate?.description && (
									<Popup
										trigger={<Icon circular name="info" size="small" style={{float: 'right'}} />}
										content={glAccount.generalLedgerAccountTemplate.description}
									/>
								)}
								{glAccount.code} - {glAccount.name}
							</DropdownItem>
						),
					});
				return acc;
			}, [] as {key: string; text: string; value: string; content: any}[]) || []
		);
	}, [options]);

	return (
		<Dropdown
			{...rest}
			fluid
			value={value}
			options={sortBy(dropdownOptions, ['text'])}
			loading={loading || rest.loading}
			placeholder={props.placeholder || (!dropdownOptions?.length ? 'No accounts found!' : 'Select an account...')}
			disabled={!!error || props.disabled}
			onChange={(_e, val) => handleChange(val?.value as GlAccDropdownHandleChangeInput)}
			search
		/>
	);
}
