import * as Apollo from '@apollo/client';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './deleteStatementById.graphql';

const defaultOptions = {} as const;
export type DeleteStatementByIdMutationVariables = Types.Exact<{
	id: Types.Scalars['String'];
	version: Types.Scalars['Int'];
}>;

export type DeleteStatementByIdMutation = {deleteStatement?: string | null};

export type DeleteStatementByIdType = string;

export type DeleteStatementByIdMutationFn = Apollo.MutationFunction<DeleteStatementByIdMutation, DeleteStatementByIdMutationVariables>;

/**
 * __useDeleteStatementByIdMutation__
 *
 * To run a mutation, you first call `useDeleteStatementByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStatementByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStatementByIdMutation, { data, loading, error }] = useDeleteStatementByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useDeleteStatementByIdMutation(
	baseOptions?: Apollo.MutationHookOptions<DeleteStatementByIdMutation, DeleteStatementByIdMutationVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<DeleteStatementByIdMutation, DeleteStatementByIdMutationVariables>(Operations, options);
}
export type DeleteStatementByIdMutationHookResult = ReturnType<typeof useDeleteStatementByIdMutation>;
export type DeleteStatementByIdMutationResult = Apollo.MutationResult<DeleteStatementByIdMutation>;
export type DeleteStatementByIdMutationOptions = Apollo.BaseMutationOptions<DeleteStatementByIdMutation, DeleteStatementByIdMutationVariables>;
