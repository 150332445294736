import {createPages} from '@imperium/layout';
import loadable from '@loadable/component';
import {CorporateAssetsTableSidebar} from './components/CorporateAssetsTable/CorporateAssetsTableSidebar';
import {PersonalVehiclesTableSidebar} from './components/PersonalVehicleTable/PersonalVehiclesTableSidebar';
import {routes} from './routes';

const CorporateAssetCreateForm = loadable(() => import('./components/CorporateAssetCreateForm'));
const CorporateAssetEditForm = loadable(() => import('./components/CorporateAssetEditForm'));
const CorporateAssetsTable = loadable(() => import('./components/CorporateAssetsTable'));
const PersonalVehicleCreateForm = loadable(() => import('./components/PersonalVehicleCreateForm'));
const PersonalVehicleEditForm = loadable(() => import('./components/PersonalVehicleEditForm'));
const PersonalVehicleTable = loadable(() => import('./components/PersonalVehicleTable'));

export const routeProps = createPages(routes, {
	corporateAssets: {
		header: 'Corporate Assets',
		content: () => <CorporateAssetsTable />,
		sidebar: [{render: CorporateAssetsTableSidebar}],
	},
	createCorporateAsset: {
		header: 'Create Corporate Asset',
		content: () => <CorporateAssetCreateForm />,
	},
	editCorporateAsset: {
		header: 'Edit Corporate Asset',
		content: () => <CorporateAssetEditForm />,
	},
	personalVehicles: {
		header: 'Personal Vehicles',
		content: () => <PersonalVehicleTable />,
		sidebar: [{render: PersonalVehiclesTableSidebar}],
	},
	createPersonalVehicle: {
		header: 'Create Personal Vehicle',
		content: () => <PersonalVehicleCreateForm />,
	},
	editPersonalVehicle: {
		header: 'Edit Personal Vehicle',
		content: () => <PersonalVehicleEditForm />,
	},
});
