import * as Apollo from '@apollo/client';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './updateCommunicationFeedItem.graphql';

const defaultOptions = {} as const;
export type UpdateCommunicationFeedItemMutationVariables = Types.Exact<{
	id: Types.Scalars['String'];
	contents: Types.Scalars['String'];
	color: Types.Scalars['String'];
	pinned: Types.Scalars['Boolean'];
	version: Types.Scalars['Int'];
}>;

export type UpdateCommunicationFeedItemMutation = {
	updateCommunicationFeedItem: {
		id: string;
		type: Types.CommunicationTypeEnum;
		contents: string;
		color?: string | null;
		createdAt: any;
		pinned: boolean;
		version?: number | null;
		data?: {cc?: string | null; date?: string | null; from: string; subject?: string | null; text?: string | null; to: string} | null;
		createdBy: {id: string; version: number; name: string; roles: Array<Types.AgentRole>};
	};
};

export type UpdateCommunicationFeedItemType = {
	id: string;
	type: Types.CommunicationTypeEnum;
	contents: string;
	color?: string | null;
	createdAt: any;
	pinned: boolean;
	version?: number | null;
	data?: {cc?: string | null; date?: string | null; from: string; subject?: string | null; text?: string | null; to: string} | null;
	createdBy: {id: string; version: number; name: string; roles: Array<Types.AgentRole>};
};

export type UpdateCommunicationFeedItemMutationFn = Apollo.MutationFunction<
	UpdateCommunicationFeedItemMutation,
	UpdateCommunicationFeedItemMutationVariables
>;

/**
 * __useUpdateCommunicationFeedItemMutation__
 *
 * To run a mutation, you first call `useUpdateCommunicationFeedItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommunicationFeedItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommunicationFeedItemMutation, { data, loading, error }] = useUpdateCommunicationFeedItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      contents: // value for 'contents'
 *      color: // value for 'color'
 *      pinned: // value for 'pinned'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useUpdateCommunicationFeedItemMutation(
	baseOptions?: Apollo.MutationHookOptions<UpdateCommunicationFeedItemMutation, UpdateCommunicationFeedItemMutationVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<UpdateCommunicationFeedItemMutation, UpdateCommunicationFeedItemMutationVariables>(Operations, options);
}
export type UpdateCommunicationFeedItemMutationHookResult = ReturnType<typeof useUpdateCommunicationFeedItemMutation>;
export type UpdateCommunicationFeedItemMutationResult = Apollo.MutationResult<UpdateCommunicationFeedItemMutation>;
export type UpdateCommunicationFeedItemMutationOptions = Apollo.BaseMutationOptions<
	UpdateCommunicationFeedItemMutation,
	UpdateCommunicationFeedItemMutationVariables
>;
