import * as Apollo from '@apollo/client';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './editAgentEmployee.graphql';

const defaultOptions = {} as const;
export type EditAgentEmployeeMutationVariables = Types.Exact<{
	data: Types.EditAgentInput;
}>;

export type EditAgentEmployeeMutation = {editAgent?: {id: string} | null};

export type EditAgentEmployeeType = {id: string};

export type EditAgentEmployeeMutationFn = Apollo.MutationFunction<EditAgentEmployeeMutation, EditAgentEmployeeMutationVariables>;

/**
 * __useEditAgentEmployeeMutation__
 *
 * To run a mutation, you first call `useEditAgentEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAgentEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAgentEmployeeMutation, { data, loading, error }] = useEditAgentEmployeeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditAgentEmployeeMutation(
	baseOptions?: Apollo.MutationHookOptions<EditAgentEmployeeMutation, EditAgentEmployeeMutationVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<EditAgentEmployeeMutation, EditAgentEmployeeMutationVariables>(Operations, options);
}
export type EditAgentEmployeeMutationHookResult = ReturnType<typeof useEditAgentEmployeeMutation>;
export type EditAgentEmployeeMutationResult = Apollo.MutationResult<EditAgentEmployeeMutation>;
export type EditAgentEmployeeMutationOptions = Apollo.BaseMutationOptions<EditAgentEmployeeMutation, EditAgentEmployeeMutationVariables>;
