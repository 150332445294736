import {LayoutData, useLayoutState} from '@imperium/layout';
import {debug} from 'debug';
import {AccountTypeEnum, Permission} from '~core/graphql';
import {isLoggedIn, needsPermission, needsRoute} from '~lib/permissionHelpers';
import {useAccountState} from '../accounts/state';
import {routes} from './routes';

const d = debug('tacs.web.documents.layout');

export const layout: LayoutData = {
	permissions: [Permission.ReadAllAccount, Permission.ViewAdminPayrollAccount, Permission.WriteAllAccount, Permission.WriteOwnAccount],
	primaryMenu: [],
	secondaryMenu: [
		{
			text: 'Documents',
			visible: {...isLoggedIn(), ...needsRoute('account'), ...needsPermission(Permission.ViewAdminPayrollAccount)},
			dropdown: [
				{
					visible: {...needsPermission([Permission.ViewAdminPayrollAccount])},
					text: 'Documents',
					icon: 'file alternate',
					stateSelectorHook: [useLayoutState, useAccountState],
					to: data => (data.state.params?.accountInfoId ? routes.to.documents({accountInfoId: data.state.params.accountInfoId}) : ''),
				},
				{
					visible: {
						...needsPermission([Permission.ReadAllAccount, Permission.ViewAdminPayrollAccount]),
						'state.type': {$in: [AccountTypeEnum.CorporateAccount, AccountTypeEnum.SelfEmployedAccount, AccountTypeEnum.PersonalAccount]},
					},
					text: 'Unsorted Files',
					icon: 'file',
					stateSelectorHook: [useLayoutState, useAccountState],
					to: data => (data.state.params?.accountInfoId ? routes.to.draftDocuments({accountInfoId: data.state.params.accountInfoId}) : ''),
				},
				{
					visible: {
						...needsPermission([Permission.ViewAdminPayrollAccount]),
						'state.type': {$in: [AccountTypeEnum.CorporateAccount, AccountTypeEnum.SelfEmployedAccount, AccountTypeEnum.PersonalAccount]},
					},
					text: 'Packages',
					icon: 'box',
					stateSelectorHook: [useLayoutState, useAccountState],
					to: data => (data.state.params?.accountInfoId ? routes.to.receivedFiles({accountInfoId: data.state.params.accountInfoId}) : ''),
				},
				{
					visible: {
						...needsPermission([Permission.ViewAdminPayrollAccount]),
						'state.type': {$in: [AccountTypeEnum.CorporateAccount, AccountTypeEnum.SelfEmployedAccount, AccountTypeEnum.PersonalAccount]},
					},
					text: 'Upload Documents',
					icon: 'upload',
					stateSelectorHook: [useLayoutState, useAccountState],
					to: data => (data.state.params?.accountInfoId ? routes.to.documentUpload({accountInfoId: data.state.params.accountInfoId}) : ''),
				},
				{
					visible: {
						...needsPermission([Permission.ViewAdminPayrollAccount]),
						'state.type': {$in: [AccountTypeEnum.BasicAccount]},
					},
					text: 'Upload Log Books',
					icon: 'truck',
					stateSelectorHook: [useLayoutState, useAccountState],
					to: data => (data.state.params?.accountInfoId ? routes.to.logBookUpload({accountInfoId: data.state.params.accountInfoId}) : ''),
				},
				{
					visible: {
						...needsPermission([Permission.ReadAllAccount, Permission.ViewAdminPayrollAccount]),
						'state.type': {$in: [AccountTypeEnum.CorporateAccount, AccountTypeEnum.SelfEmployedAccount, AccountTypeEnum.PersonalAccount]},
					},
					text: 'Missing Documents',
					icon: 'wait',
					stateSelectorHook: [useLayoutState, useAccountState],
					to: data => (data.state.params?.accountInfoId ? routes.to.missingDocuments({accountInfoId: data.state.params.accountInfoId}) : ''),
				},
			],
		},
		{
			text: 'Expected Documents',
			icon: 'wait',
			visible: {...needsPermission([Permission.ReadAllAccount, Permission.ViewAdminPayrollAccount])},
			stateSelectorHook: useLayoutState,
			to: data => (data.state.params?.accountInfoId ? routes.to.viewExpectedDocumentSettings({accountInfoId: data.state.params.accountInfoId}) : ''),
			moveToKey: 'settings',
		},
	],
};
