import * as Apollo from '@apollo/client';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getEditAddress.graphql';

const defaultOptions = {} as const;
export type GetEditAddressQueryVariables = Types.Exact<{
	accountInfoId: Types.Scalars['String'];
}>;

export type GetEditAddressQuery = {
	getAccountInfoById?: {
		id: string;
		version: number;
		name: string;
		address?: {
			id: string;
			version: number;
			lineOne?: string | null;
			lineTwo?: string | null;
			city?: string | null;
			province?: string | null;
			postalCode?: string | null;
			country?: string | null;
		} | null;
	} | null;
};

export type GetEditAddressType = {
	id: string;
	version: number;
	name: string;
	address?: {
		id: string;
		version: number;
		lineOne?: string | null;
		lineTwo?: string | null;
		city?: string | null;
		province?: string | null;
		postalCode?: string | null;
		country?: string | null;
	} | null;
};

/**
 * __useGetEditAddressQuery__
 *
 * To run a query within a React component, call `useGetEditAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEditAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEditAddressQuery({
 *   variables: {
 *      accountInfoId: // value for 'accountInfoId'
 *   },
 * });
 */
export function useGetEditAddressQuery(baseOptions: Apollo.QueryHookOptions<GetEditAddressQuery, GetEditAddressQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetEditAddressQuery, GetEditAddressQueryVariables>(Operations, options);
}
export function useGetEditAddressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEditAddressQuery, GetEditAddressQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetEditAddressQuery, GetEditAddressQueryVariables>(Operations, options);
}
export type GetEditAddressQueryHookResult = ReturnType<typeof useGetEditAddressQuery>;
export type GetEditAddressLazyQueryHookResult = ReturnType<typeof useGetEditAddressLazyQuery>;
export type GetEditAddressQueryResult = Apollo.QueryResult<GetEditAddressQuery, GetEditAddressQueryVariables>;
