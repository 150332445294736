import * as Apollo from '@apollo/client';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './sendContactUserInvitation.graphql';

const defaultOptions = {} as const;
export type SendContactUserInvitationMutationVariables = Types.Exact<{
	contactId: Types.Scalars['String'];
	registrationPageEndpoint: Types.Scalars['String'];
}>;

export type SendContactUserInvitationMutation = {sendContactInvitation: boolean};

export type SendContactUserInvitationType = boolean;

export type SendContactUserInvitationMutationFn = Apollo.MutationFunction<
	SendContactUserInvitationMutation,
	SendContactUserInvitationMutationVariables
>;

/**
 * __useSendContactUserInvitationMutation__
 *
 * To run a mutation, you first call `useSendContactUserInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendContactUserInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendContactUserInvitationMutation, { data, loading, error }] = useSendContactUserInvitationMutation({
 *   variables: {
 *      contactId: // value for 'contactId'
 *      registrationPageEndpoint: // value for 'registrationPageEndpoint'
 *   },
 * });
 */
export function useSendContactUserInvitationMutation(
	baseOptions?: Apollo.MutationHookOptions<SendContactUserInvitationMutation, SendContactUserInvitationMutationVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<SendContactUserInvitationMutation, SendContactUserInvitationMutationVariables>(Operations, options);
}
export type SendContactUserInvitationMutationHookResult = ReturnType<typeof useSendContactUserInvitationMutation>;
export type SendContactUserInvitationMutationResult = Apollo.MutationResult<SendContactUserInvitationMutation>;
export type SendContactUserInvitationMutationOptions = Apollo.BaseMutationOptions<
	SendContactUserInvitationMutation,
	SendContactUserInvitationMutationVariables
>;
