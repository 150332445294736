import * as Apollo from '@apollo/client';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getAccountTableAccounts.graphql';

const defaultOptions = {} as const;
export type GetAccountInfoAuthorizedRepresentativesByIdQueryVariables = Types.Exact<{
	id: Types.Scalars['String'];
}>;

export type GetAccountInfoAuthorizedRepresentativesByIdQuery = {
	getAuthorizedRepresentativesByAccountInfoId?: Array<{
		id: string;
		version: number;
		contact: {id: string; version: number; givenName: string; surname: string; preferredName?: string | null};
	}> | null;
};

export type GetAccountInfoAuthorizedRepresentativesByIdType = {
	id: string;
	version: number;
	contact: {id: string; version: number; givenName: string; surname: string; preferredName?: string | null};
};

/**
 * __useGetAccountInfoAuthorizedRepresentativesByIdQuery__
 *
 * To run a query within a React component, call `useGetAccountInfoAuthorizedRepresentativesByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountInfoAuthorizedRepresentativesByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountInfoAuthorizedRepresentativesByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAccountInfoAuthorizedRepresentativesByIdQuery(
	baseOptions: Apollo.QueryHookOptions<GetAccountInfoAuthorizedRepresentativesByIdQuery, GetAccountInfoAuthorizedRepresentativesByIdQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetAccountInfoAuthorizedRepresentativesByIdQuery, GetAccountInfoAuthorizedRepresentativesByIdQueryVariables>(
		Operations,
		options,
	);
}
export function useGetAccountInfoAuthorizedRepresentativesByIdLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetAccountInfoAuthorizedRepresentativesByIdQuery,
		GetAccountInfoAuthorizedRepresentativesByIdQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetAccountInfoAuthorizedRepresentativesByIdQuery, GetAccountInfoAuthorizedRepresentativesByIdQueryVariables>(
		Operations,
		options,
	);
}
export type GetAccountInfoAuthorizedRepresentativesByIdQueryHookResult = ReturnType<typeof useGetAccountInfoAuthorizedRepresentativesByIdQuery>;
export type GetAccountInfoAuthorizedRepresentativesByIdLazyQueryHookResult = ReturnType<
	typeof useGetAccountInfoAuthorizedRepresentativesByIdLazyQuery
>;
export type GetAccountInfoAuthorizedRepresentativesByIdQueryResult = Apollo.QueryResult<
	GetAccountInfoAuthorizedRepresentativesByIdQuery,
	GetAccountInfoAuthorizedRepresentativesByIdQueryVariables
>;
