import * as Apollo from '@apollo/client';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getJournalEntriesByDocumentId.graphql';

const defaultOptions = {} as const;
export type GetJournalEntriesByDocumentIdQueryVariables = Types.Exact<{
	documentId: Types.Scalars['String'];
}>;

export type GetJournalEntriesByDocumentIdQuery = {
	getJournalEntriesByDocumentId?: Array<{
		id: string;
		version: number;
		date: any;
		comment?: string | null;
		journalNumber: number;
		journalEntryLines: Array<{id: string; credit: any; debit: any; lineNumber: number; glAccount: {id: string; name: string}}>;
	}> | null;
};

export type GetJournalEntriesByDocumentIdType = {
	id: string;
	version: number;
	date: any;
	comment?: string | null;
	journalNumber: number;
	journalEntryLines: Array<{id: string; credit: any; debit: any; lineNumber: number; glAccount: {id: string; name: string}}>;
};

/**
 * __useGetJournalEntriesByDocumentIdQuery__
 *
 * To run a query within a React component, call `useGetJournalEntriesByDocumentIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJournalEntriesByDocumentIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJournalEntriesByDocumentIdQuery({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useGetJournalEntriesByDocumentIdQuery(
	baseOptions: Apollo.QueryHookOptions<GetJournalEntriesByDocumentIdQuery, GetJournalEntriesByDocumentIdQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetJournalEntriesByDocumentIdQuery, GetJournalEntriesByDocumentIdQueryVariables>(Operations, options);
}
export function useGetJournalEntriesByDocumentIdLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetJournalEntriesByDocumentIdQuery, GetJournalEntriesByDocumentIdQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetJournalEntriesByDocumentIdQuery, GetJournalEntriesByDocumentIdQueryVariables>(Operations, options);
}
export type GetJournalEntriesByDocumentIdQueryHookResult = ReturnType<typeof useGetJournalEntriesByDocumentIdQuery>;
export type GetJournalEntriesByDocumentIdLazyQueryHookResult = ReturnType<typeof useGetJournalEntriesByDocumentIdLazyQuery>;
export type GetJournalEntriesByDocumentIdQueryResult = Apollo.QueryResult<
	GetJournalEntriesByDocumentIdQuery,
	GetJournalEntriesByDocumentIdQueryVariables
>;
