import * as Apollo from '@apollo/client';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getDisplayRelatedAccountsListAccounts.graphql';

const defaultOptions = {} as const;
export type GetDisplayRelatedAccountsListAccountsQueryVariables = Types.Exact<{
	accountInfoId: Types.Scalars['String'];
}>;

export type GetDisplayRelatedAccountsListAccountsQuery = {
	getRelatedAccounts?: Array<{id: string; version: number; name: string; friendlyId: number; type: Types.AccountTypeEnum; archived: boolean}> | null;
};

export type GetDisplayRelatedAccountsListAccountsType = {
	id: string;
	version: number;
	name: string;
	friendlyId: number;
	type: Types.AccountTypeEnum;
	archived: boolean;
};

/**
 * __useGetDisplayRelatedAccountsListAccountsQuery__
 *
 * To run a query within a React component, call `useGetDisplayRelatedAccountsListAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDisplayRelatedAccountsListAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDisplayRelatedAccountsListAccountsQuery({
 *   variables: {
 *      accountInfoId: // value for 'accountInfoId'
 *   },
 * });
 */
export function useGetDisplayRelatedAccountsListAccountsQuery(
	baseOptions: Apollo.QueryHookOptions<GetDisplayRelatedAccountsListAccountsQuery, GetDisplayRelatedAccountsListAccountsQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetDisplayRelatedAccountsListAccountsQuery, GetDisplayRelatedAccountsListAccountsQueryVariables>(Operations, options);
}
export function useGetDisplayRelatedAccountsListAccountsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetDisplayRelatedAccountsListAccountsQuery, GetDisplayRelatedAccountsListAccountsQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetDisplayRelatedAccountsListAccountsQuery, GetDisplayRelatedAccountsListAccountsQueryVariables>(Operations, options);
}
export type GetDisplayRelatedAccountsListAccountsQueryHookResult = ReturnType<typeof useGetDisplayRelatedAccountsListAccountsQuery>;
export type GetDisplayRelatedAccountsListAccountsLazyQueryHookResult = ReturnType<typeof useGetDisplayRelatedAccountsListAccountsLazyQuery>;
export type GetDisplayRelatedAccountsListAccountsQueryResult = Apollo.QueryResult<
	GetDisplayRelatedAccountsListAccountsQuery,
	GetDisplayRelatedAccountsListAccountsQueryVariables
>;
