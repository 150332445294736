import {LayoutData, useLayoutState} from '@imperium/layout';
import {debug} from 'debug';
import {AccountTypeEnum, Permission} from '~core/graphql';
import {needsPermission, needsRoute} from '~lib/permissionHelpers';
import {useAccountState} from '../accounts/state';
import {routes} from './routes';

const d = debug('tacs.web.corporateTaxes.layout');

export const layout: LayoutData = {
	permissions: [Permission.ReadAllAccount, Permission.ViewAdminPayrollAccount],
	primaryMenu: [],
	secondaryMenu: [
		{
			text: 'Corporate Taxes',
			stateSelectorHook: [useLayoutState, useAccountState],
			visible: {
				...needsPermission([Permission.ReadAllAccount, Permission.ViewAdminPayrollAccount]),
				...needsRoute('account'),
				'state.type': {$in: [AccountTypeEnum.CorporateAccount]},
			},
			to: data => (data.state.params?.accountInfoId ? routes.to.corporateTaxes({accountInfoId: data.state.params.accountInfoId}) : ''),
			icon: 'university',
		},
	],
};
