import {LayoutData, useLayoutState} from '@imperium/layout';
import {debug} from 'debug';
import {Permission} from '~core/graphql';
import {needsPermission, needsRoute} from '~lib/permissionHelpers';
import {routes} from './routes';

const d = debug('tacs.web.receivables.layout');

export const layout: LayoutData = {
	permissions: [Permission.ReadAllAccount, Permission.ViewAdminPayrollAccount],
	primaryMenu: [],
	secondaryMenu: [
		{
			text: 'Receivables',
			visible: {...needsPermission([Permission.ReadAllAccount, Permission.ViewAdminPayrollAccount]), ...needsRoute('account')},
			stateSelectorHook: useLayoutState,
			dropdown: [
				{
					text: 'Contracts',
					icon: 'signup',
					stateSelectorHook: useLayoutState,
					to: data => (data.state.params?.accountInfoId ? routes.to.contracts({accountInfoId: data.state.params.accountInfoId}) : ''),
				},
				{
					text: 'Payment Schedule',
					icon: 'ordered list',
					stateSelectorHook: useLayoutState,
					to: data => (data.state.params?.accountInfoId ? routes.to.paymentSchedule({accountInfoId: data.state.params.accountInfoId}) : ''),
				},
				{
					text: 'Transactions',
					icon: 'unordered list',
					stateSelectorHook: useLayoutState,
					to: data => (data.state.params?.accountInfoId ? routes.to.transactions({accountInfoId: data.state.params.accountInfoId}) : ''),
				},
			],
		},
	],
};
