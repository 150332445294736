import {ScriptelInput, TFormProps} from '@thx/controls';
import type {ScriptelSchemaType} from '@thx/yup-types';
import debug from 'debug';
import {findIndex} from 'lodash-es';
import {FormInput, Grid, GridColumn, GridRow, Label} from 'semantic-ui-react';

const d = debug('tacs.web.common.components.RenderNameWithSignatures');

export function RenderNameWithSignatures<T>(
	fieldName: string,
	members: string[] | undefined | null,
	signatures: {name: string; signature: ScriptelSchemaType}[],
	setFieldValue: TFormProps<T>['setFieldValue'],
) {
	return (
		<Grid columns="equal" stackable>
			<GridRow>
				{members?.map((member, index) => {
					// all of this clutter makes sure that we match the signature to the right signor.
					// We don't know how many people will sign and other members could be added or removed in the future
					// so we have to accommodate for that.
					let dx = findIndex(signatures, ['name', member]);
					let value;
					// if the dx is -1 then we don't have a matching value, so it's set to null.
					if (dx === -1) {
						value = undefined;
						dx = index;
					} else value = signatures && signatures[dx] ? signatures[dx].signature : undefined;

					return (
						<GridColumn key={member || undefined}>
							<Label>
								<FormInput>
									<Label>{member || 'Name not found'}</Label>
								</FormInput>
							</Label>
							<ScriptelInput value={value} onChange={val => setFieldValue(`${fieldName}[${dx}]`, {name: member, signature: val})} />
						</GridColumn>
					);
				})}
			</GridRow>
		</Grid>
	);
}
