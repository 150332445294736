import * as Apollo from '@apollo/client';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getGstFilingFrequencyDropdownFilingFrequencies.graphql';

const defaultOptions = {} as const;
export type GetGstFilingFrequencyDropdownFilingFrequenciesQueryVariables = Types.Exact<{[key: string]: never}>;

export type GetGstFilingFrequencyDropdownFilingFrequenciesQuery = {
	getGstFilingFrequencies?: Array<{id: string; text: string; value: Types.GstFilingFrequencyEnum} | null> | null;
};

export type GetGstFilingFrequencyDropdownFilingFrequenciesType = {id: string; text: string; value: Types.GstFilingFrequencyEnum};

/**
 * __useGetGstFilingFrequencyDropdownFilingFrequenciesQuery__
 *
 * To run a query within a React component, call `useGetGstFilingFrequencyDropdownFilingFrequenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGstFilingFrequencyDropdownFilingFrequenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGstFilingFrequencyDropdownFilingFrequenciesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGstFilingFrequencyDropdownFilingFrequenciesQuery(
	baseOptions?: Apollo.QueryHookOptions<
		GetGstFilingFrequencyDropdownFilingFrequenciesQuery,
		GetGstFilingFrequencyDropdownFilingFrequenciesQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetGstFilingFrequencyDropdownFilingFrequenciesQuery, GetGstFilingFrequencyDropdownFilingFrequenciesQueryVariables>(
		Operations,
		options,
	);
}
export function useGetGstFilingFrequencyDropdownFilingFrequenciesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetGstFilingFrequencyDropdownFilingFrequenciesQuery,
		GetGstFilingFrequencyDropdownFilingFrequenciesQueryVariables
	>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetGstFilingFrequencyDropdownFilingFrequenciesQuery, GetGstFilingFrequencyDropdownFilingFrequenciesQueryVariables>(
		Operations,
		options,
	);
}
export type GetGstFilingFrequencyDropdownFilingFrequenciesQueryHookResult = ReturnType<typeof useGetGstFilingFrequencyDropdownFilingFrequenciesQuery>;
export type GetGstFilingFrequencyDropdownFilingFrequenciesLazyQueryHookResult = ReturnType<
	typeof useGetGstFilingFrequencyDropdownFilingFrequenciesLazyQuery
>;
export type GetGstFilingFrequencyDropdownFilingFrequenciesQueryResult = Apollo.QueryResult<
	GetGstFilingFrequencyDropdownFilingFrequenciesQuery,
	GetGstFilingFrequencyDropdownFilingFrequenciesQueryVariables
>;
