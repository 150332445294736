import {createPages} from '@imperium/layout';
import loadable from '@loadable/component';
import {FilteredByDateRange} from '~common/components/FilteredByDateRange';
import {EmployeeDropdownSidebar} from './components/EmployeeDropdownSidear';
import {routes} from './routes';

const AccountCalendar = loadable(() => import('./components/AccountCalendar'));
const MaintenanceReportTable = loadable(() => import('./components/MaintenanceReportTable'));
const PerDiemJournalEntriesTable = loadable(() => import('./components/PerDiemJournalEntriesTable'));
const PerDiemReportTable = loadable(() => import('./components/PerDiemReportTable'));
const PvlJournalEntriesTable = loadable(() => import('./components/PvlJournalEntriesTable'));
const PvlReportTable = loadable(() => import('./components/PvlReportTable'));

export const routeProps = createPages(routes, {
	calendar: {
		content: () => <AccountCalendar />,
	},
	pvlReport: {
		header: (
			<>
				<h2>PVL Report</h2>
				<FilteredByDateRange />
			</>
		),
		content: () => <PvlReportTable />,
		sidebar: [{render: EmployeeDropdownSidebar}],
	},
	pvlJournalEntries: {
		header: (
			<>
				<h2>PVL Journal Entries</h2>
				<FilteredByDateRange />
			</>
		),
		content: () => <PvlJournalEntriesTable />,
		sidebar: [{render: EmployeeDropdownSidebar}],
	},
	perDiemReport: {
		header: (
			<>
				<h2>Per Diem Report</h2>
				<FilteredByDateRange />
			</>
		),
		content: () => <PerDiemReportTable />,
		sidebar: [{render: EmployeeDropdownSidebar}],
	},
	perDiemJournalEntries: {
		header: (
			<>
				<h2>Per Diem Journal Entries</h2>
				<FilteredByDateRange />
			</>
		),
		content: () => <PerDiemJournalEntriesTable />,
		sidebar: [{render: EmployeeDropdownSidebar}],
	},
	maintenanceReport: {
		header: (
			<>
				<h2>Maintenance Report</h2>
				<FilteredByDateRange />
			</>
		),
		content: () => <MaintenanceReportTable />,
		sidebar: [{render: EmployeeDropdownSidebar}],
	},
});
