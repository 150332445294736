import {useCan} from '@imperium/auth-client';
import debug from 'debug';
import {useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Button} from 'semantic-ui-react';
import {DeleteModal} from '~common/components';
import {Permission} from '~core/graphql';
import {useAsyncError} from '~lib/useAsyncError';
import {routes} from '../../../../dashboard/routes';
import {useAccountState} from '../../../state';
import {useDeleteAccountCascadeMutation} from './deleteAccountCascade';

const d = debug('tacs.web.accounts.components.accounts.DeleteAccountButton');

export function DeleteAccountButton() {
	const {push} = useHistory();
	const throwError = useAsyncError();
	const [confirmMutation, {loading: mutationLoading}] = useDeleteAccountCascadeMutation();
	const [sysadmin] = useCan(Permission.Sysadmin);
	const {accountInfoId, name, friendlyId} = useAccountState();

	if (!sysadmin) return null;

	function onConfirmClicked() {
		if (accountInfoId) {
			toast.success('The account will be removed.');
			push(routes.to.dashboard());
			confirmMutation({
				variables: {accountInfoId},
			}).catch(throwError);
		}
	}

	return (
		<DeleteModal
			onDelete={onConfirmClicked}
			headerText="Confirm Delete"
			text={`Are you sure you want to delete ${name} (${friendlyId})?`}
			trigger={
				<Button disabled={mutationLoading} fluid negative>
					Delete Account
				</Button>
			}
		/>
	);
}
