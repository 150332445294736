import * as Apollo from '@apollo/client';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './createAuthorizedRepresentative.graphql';

const defaultOptions = {} as const;
export type CreateAuthorizedRepresentativeMutationVariables = Types.Exact<{
	data: Types.CreateAuthorizedRepresentativeAndContactInput;
}>;

export type CreateAuthorizedRepresentativeMutation = {createAuthorizedRepresentativeAndContact?: {id: string; version: number} | null};

export type CreateAuthorizedRepresentativeType = {id: string; version: number};

export type CreateAuthorizedRepresentativeMutationFn = Apollo.MutationFunction<
	CreateAuthorizedRepresentativeMutation,
	CreateAuthorizedRepresentativeMutationVariables
>;

/**
 * __useCreateAuthorizedRepresentativeMutation__
 *
 * To run a mutation, you first call `useCreateAuthorizedRepresentativeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAuthorizedRepresentativeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAuthorizedRepresentativeMutation, { data, loading, error }] = useCreateAuthorizedRepresentativeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateAuthorizedRepresentativeMutation(
	baseOptions?: Apollo.MutationHookOptions<CreateAuthorizedRepresentativeMutation, CreateAuthorizedRepresentativeMutationVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<CreateAuthorizedRepresentativeMutation, CreateAuthorizedRepresentativeMutationVariables>(Operations, options);
}
export type CreateAuthorizedRepresentativeMutationHookResult = ReturnType<typeof useCreateAuthorizedRepresentativeMutation>;
export type CreateAuthorizedRepresentativeMutationResult = Apollo.MutationResult<CreateAuthorizedRepresentativeMutation>;
export type CreateAuthorizedRepresentativeMutationOptions = Apollo.BaseMutationOptions<
	CreateAuthorizedRepresentativeMutation,
	CreateAuthorizedRepresentativeMutationVariables
>;
