import * as Apollo from '@apollo/client';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getEmployeeAddSidebarData.graphql';

const defaultOptions = {} as const;
export type GetEmployeeAddSidebarDataQueryVariables = Types.Exact<{
	accountInfoId: Types.Scalars['String'];
}>;

export type GetEmployeeAddSidebarDataQuery = {
	getAuthorizedRepresentativesByAccountInfoId?: Array<{
		id: string;
		version: number;
		contact: {id: string; version: number; givenName: string; surname: string; preferredName?: string | null};
	}> | null;
	getEmployeesByAccountInfoId?: Array<{
		id: string;
		version: number;
		contact: {id: string; version: number; givenName: string; surname: string; preferredName?: string | null};
	}> | null;
	getPartnersByAccountInfoId?: Array<{
		id: string;
		version: number;
		contact: {id: string; version: number; givenName: string; surname: string; preferredName?: string | null};
	}> | null;
};

export type GetEmployeeAddSidebarDataType = {
	id: string;
	version: number;
	contact: {id: string; version: number; givenName: string; surname: string; preferredName?: string | null};
};

/**
 * __useGetEmployeeAddSidebarDataQuery__
 *
 * To run a query within a React component, call `useGetEmployeeAddSidebarDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeAddSidebarDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeAddSidebarDataQuery({
 *   variables: {
 *      accountInfoId: // value for 'accountInfoId'
 *   },
 * });
 */
export function useGetEmployeeAddSidebarDataQuery(
	baseOptions: Apollo.QueryHookOptions<GetEmployeeAddSidebarDataQuery, GetEmployeeAddSidebarDataQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetEmployeeAddSidebarDataQuery, GetEmployeeAddSidebarDataQueryVariables>(Operations, options);
}
export function useGetEmployeeAddSidebarDataLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetEmployeeAddSidebarDataQuery, GetEmployeeAddSidebarDataQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetEmployeeAddSidebarDataQuery, GetEmployeeAddSidebarDataQueryVariables>(Operations, options);
}
export type GetEmployeeAddSidebarDataQueryHookResult = ReturnType<typeof useGetEmployeeAddSidebarDataQuery>;
export type GetEmployeeAddSidebarDataLazyQueryHookResult = ReturnType<typeof useGetEmployeeAddSidebarDataLazyQuery>;
export type GetEmployeeAddSidebarDataQueryResult = Apollo.QueryResult<GetEmployeeAddSidebarDataQuery, GetEmployeeAddSidebarDataQueryVariables>;
