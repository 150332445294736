import type {ActionCreatorWithPayload} from '@reduxjs/toolkit';
import {useDispatch} from 'react-redux';
import {Pagination} from 'semantic-ui-react';

interface Props {
	numberOfResults: number | undefined;
	offsetFilter: number;
	totalPages: number;
	setOffsetFilter: ActionCreatorWithPayload<number>;
}

export function UserAndAccountEventFeedPagination({numberOfResults, offsetFilter, totalPages, setOffsetFilter}: Props) {
	const dispatch = useDispatch();

	return numberOfResults ? (
		<div style={{textAlign: 'center', display: 'inline-block'}}>
			<Pagination
				activePage={offsetFilter === 0 ? 1 : offsetFilter / 100 + 1}
				secondary
				totalPages={totalPages}
				onPageChange={(_e, {activePage}) => dispatch(setOffsetFilter(activePage ? +activePage - 1 : 1))}
			/>
			<p>
				{`Displaying results ${offsetFilter === 0 ? 1 : offsetFilter} - ${
					offsetFilter + 100 > numberOfResults ? numberOfResults : offsetFilter + 99
				} of ${numberOfResults} total.`}
			</p>
		</div>
	) : null;
}
