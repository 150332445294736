import * as Apollo from '@apollo/client';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './createFinancialAccount.graphql';

const defaultOptions = {} as const;
export type CreateFinancialAccountMutationVariables = Types.Exact<{
	data: Types.CreateFinancialAccountInput;
}>;

export type CreateFinancialAccountMutation = {createFinancialAccount: {id: string}};

export type CreateFinancialAccountType = {id: string};

export type CreateFinancialAccountMutationFn = Apollo.MutationFunction<CreateFinancialAccountMutation, CreateFinancialAccountMutationVariables>;

/**
 * __useCreateFinancialAccountMutation__
 *
 * To run a mutation, you first call `useCreateFinancialAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFinancialAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFinancialAccountMutation, { data, loading, error }] = useCreateFinancialAccountMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateFinancialAccountMutation(
	baseOptions?: Apollo.MutationHookOptions<CreateFinancialAccountMutation, CreateFinancialAccountMutationVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<CreateFinancialAccountMutation, CreateFinancialAccountMutationVariables>(Operations, options);
}
export type CreateFinancialAccountMutationHookResult = ReturnType<typeof useCreateFinancialAccountMutation>;
export type CreateFinancialAccountMutationResult = Apollo.MutationResult<CreateFinancialAccountMutation>;
export type CreateFinancialAccountMutationOptions = Apollo.BaseMutationOptions<
	CreateFinancialAccountMutation,
	CreateFinancialAccountMutationVariables
>;
