import * as Apollo from '@apollo/client';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './spacesCleanupCleanExpired.graphql';

const defaultOptions = {} as const;
export type SpacesCleanupCleanExpiredMutationVariables = Types.Exact<{[key: string]: never}>;

export type SpacesCleanupCleanExpiredMutation = {spacesCleanupCleanExpired?: Array<{id: string}> | null};

export type SpacesCleanupCleanExpiredType = {id: string};

export type SpacesCleanupCleanExpiredMutationFn = Apollo.MutationFunction<
	SpacesCleanupCleanExpiredMutation,
	SpacesCleanupCleanExpiredMutationVariables
>;

/**
 * __useSpacesCleanupCleanExpiredMutation__
 *
 * To run a mutation, you first call `useSpacesCleanupCleanExpiredMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpacesCleanupCleanExpiredMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spacesCleanupCleanExpiredMutation, { data, loading, error }] = useSpacesCleanupCleanExpiredMutation({
 *   variables: {
 *   },
 * });
 */
export function useSpacesCleanupCleanExpiredMutation(
	baseOptions?: Apollo.MutationHookOptions<SpacesCleanupCleanExpiredMutation, SpacesCleanupCleanExpiredMutationVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<SpacesCleanupCleanExpiredMutation, SpacesCleanupCleanExpiredMutationVariables>(Operations, options);
}
export type SpacesCleanupCleanExpiredMutationHookResult = ReturnType<typeof useSpacesCleanupCleanExpiredMutation>;
export type SpacesCleanupCleanExpiredMutationResult = Apollo.MutationResult<SpacesCleanupCleanExpiredMutation>;
export type SpacesCleanupCleanExpiredMutationOptions = Apollo.BaseMutationOptions<
	SpacesCleanupCleanExpiredMutation,
	SpacesCleanupCleanExpiredMutationVariables
>;
