import * as Apollo from '@apollo/client';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getDocumentTypeDropdownDocumentTypes.graphql';

const defaultOptions = {} as const;
export type GetDocumentTypeDropdownDocumentTypesQueryVariables = Types.Exact<{[key: string]: never}>;

export type GetDocumentTypeDropdownDocumentTypesQuery = {getDocumentTypes?: Array<{id: string; version: number; name: string}> | null};

export type GetDocumentTypeDropdownDocumentTypesType = {id: string; version: number; name: string};

/**
 * __useGetDocumentTypeDropdownDocumentTypesQuery__
 *
 * To run a query within a React component, call `useGetDocumentTypeDropdownDocumentTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentTypeDropdownDocumentTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentTypeDropdownDocumentTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDocumentTypeDropdownDocumentTypesQuery(
	baseOptions?: Apollo.QueryHookOptions<GetDocumentTypeDropdownDocumentTypesQuery, GetDocumentTypeDropdownDocumentTypesQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetDocumentTypeDropdownDocumentTypesQuery, GetDocumentTypeDropdownDocumentTypesQueryVariables>(Operations, options);
}
export function useGetDocumentTypeDropdownDocumentTypesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetDocumentTypeDropdownDocumentTypesQuery, GetDocumentTypeDropdownDocumentTypesQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetDocumentTypeDropdownDocumentTypesQuery, GetDocumentTypeDropdownDocumentTypesQueryVariables>(Operations, options);
}
export type GetDocumentTypeDropdownDocumentTypesQueryHookResult = ReturnType<typeof useGetDocumentTypeDropdownDocumentTypesQuery>;
export type GetDocumentTypeDropdownDocumentTypesLazyQueryHookResult = ReturnType<typeof useGetDocumentTypeDropdownDocumentTypesLazyQuery>;
export type GetDocumentTypeDropdownDocumentTypesQueryResult = Apollo.QueryResult<
	GetDocumentTypeDropdownDocumentTypesQuery,
	GetDocumentTypeDropdownDocumentTypesQueryVariables
>;
