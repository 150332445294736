import * as Apollo from '@apollo/client';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getSourceDeductionPaymentEditPayment.graphql';

const defaultOptions = {} as const;
export type GetSourceDeductionPaymentEditPaymentQueryVariables = Types.Exact<{
	id: Types.Scalars['String'];
}>;

export type GetSourceDeductionPaymentEditPaymentQuery = {
	getSourceDeductionPaymentById?: {
		id: string;
		version: number;
		period: any;
		date: any;
		chequeNumber?: number | null;
		nsf: boolean;
		paymentType: Types.SourceDeductionPaymentTypeEnum;
		lineItems: Array<{id: string; version: number; amount: any; arrears: boolean; description: string}>;
	} | null;
};

export type GetSourceDeductionPaymentEditPaymentType = {
	id: string;
	version: number;
	period: any;
	date: any;
	chequeNumber?: number | null;
	nsf: boolean;
	paymentType: Types.SourceDeductionPaymentTypeEnum;
	lineItems: Array<{id: string; version: number; amount: any; arrears: boolean; description: string}>;
};

/**
 * __useGetSourceDeductionPaymentEditPaymentQuery__
 *
 * To run a query within a React component, call `useGetSourceDeductionPaymentEditPaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSourceDeductionPaymentEditPaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSourceDeductionPaymentEditPaymentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSourceDeductionPaymentEditPaymentQuery(
	baseOptions: Apollo.QueryHookOptions<GetSourceDeductionPaymentEditPaymentQuery, GetSourceDeductionPaymentEditPaymentQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetSourceDeductionPaymentEditPaymentQuery, GetSourceDeductionPaymentEditPaymentQueryVariables>(Operations, options);
}
export function useGetSourceDeductionPaymentEditPaymentLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetSourceDeductionPaymentEditPaymentQuery, GetSourceDeductionPaymentEditPaymentQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetSourceDeductionPaymentEditPaymentQuery, GetSourceDeductionPaymentEditPaymentQueryVariables>(Operations, options);
}
export type GetSourceDeductionPaymentEditPaymentQueryHookResult = ReturnType<typeof useGetSourceDeductionPaymentEditPaymentQuery>;
export type GetSourceDeductionPaymentEditPaymentLazyQueryHookResult = ReturnType<typeof useGetSourceDeductionPaymentEditPaymentLazyQuery>;
export type GetSourceDeductionPaymentEditPaymentQueryResult = Apollo.QueryResult<
	GetSourceDeductionPaymentEditPaymentQuery,
	GetSourceDeductionPaymentEditPaymentQueryVariables
>;
