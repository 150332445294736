import * as Apollo from '@apollo/client';

import Operations from '~common/components/EmployeeDropdown/getEmployeeDropdown.graphql';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

const defaultOptions = {} as const;
export type GetEmployeeDropdownQueryVariables = Types.Exact<{
	agentId: Types.Scalars['String'];
}>;

export type GetEmployeeDropdownQuery = {
	getEmployeesForAgent?: Array<{id: string; version: number; contact: {id: string; givenName: string; surname: string}}> | null;
};

export type GetEmployeeDropdownType = {id: string; version: number; contact: {id: string; givenName: string; surname: string}};

/**
 * __useGetEmployeeDropdownQuery__
 *
 * To run a query within a React component, call `useGetEmployeeDropdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeDropdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeDropdownQuery({
 *   variables: {
 *      agentId: // value for 'agentId'
 *   },
 * });
 */
export function useGetEmployeeDropdownQuery(baseOptions: Apollo.QueryHookOptions<GetEmployeeDropdownQuery, GetEmployeeDropdownQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetEmployeeDropdownQuery, GetEmployeeDropdownQueryVariables>(Operations, options);
}
export function useGetEmployeeDropdownLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetEmployeeDropdownQuery, GetEmployeeDropdownQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetEmployeeDropdownQuery, GetEmployeeDropdownQueryVariables>(Operations, options);
}
export type GetEmployeeDropdownQueryHookResult = ReturnType<typeof useGetEmployeeDropdownQuery>;
export type GetEmployeeDropdownLazyQueryHookResult = ReturnType<typeof useGetEmployeeDropdownLazyQuery>;
export type GetEmployeeDropdownQueryResult = Apollo.QueryResult<GetEmployeeDropdownQuery, GetEmployeeDropdownQueryVariables>;
