import debug from 'debug';
import {isArray} from 'lodash-es';
import type {Dispatch} from 'react';
import {useEffect, useState} from 'react';
import {Dropdown, DropdownProps} from 'semantic-ui-react';
import {DocumentStatusEnum, documentStatusEnumLookup} from '~core/graphql';
import {useAsyncError} from '~lib/useAsyncError';
import {useGetDocumentAmountInputDocumentTypeLazyQuery} from '../DocumentAmountInput/getDocumentAmountInputDocumentType';
import {documentStatusToIconConverter} from '../DocumentStatusIcon';

const d = debug('tacs.web.documents.components.documents.DocumentStatusDropdown');

interface DocumentStatusDropdownProps extends Omit<DropdownProps, 'onChange'> {
	onChange?: Dispatch<DocumentStatusEnum | DocumentStatusEnum[]>;
	documentTypeId?: string;
	multiple: boolean;
}

export function DocumentStatusDropdown(props: DocumentStatusDropdownProps) {
	const throwError = useAsyncError();
	const {onChange, multiple, documentTypeId, ...rest} = props;
	const [options, setOptions] = useState<{icon: string; value: string; text: string; id: string}[]>([]);
	const [runQuery, {data, loading}] = useGetDocumentAmountInputDocumentTypeLazyQuery();

	useEffect(() => {
		if (documentTypeId) runQuery({variables: {documentTypeId}}).catch(throwError);
		if (documentTypeId) {
			if (data?.getDocumentTypeById?.statement) {
				setOptions([
					{
						icon: documentStatusToIconConverter(DocumentStatusEnum.Pending),
						value: DocumentStatusEnum.Pending,
						text: DocumentStatusEnum.Pending,
						id: DocumentStatusEnum.Pending,
					},
					{
						icon: documentStatusToIconConverter(DocumentStatusEnum.Reconciled),
						value: DocumentStatusEnum.Reconciled,
						text: DocumentStatusEnum.Reconciled,
						id: DocumentStatusEnum.Reconciled,
					},
				]);
			} else if (data?.getDocumentTypeById?.amount) {
				setOptions([
					{
						icon: documentStatusToIconConverter(DocumentStatusEnum.Pending),
						value: DocumentStatusEnum.Pending,
						text: DocumentStatusEnum.Pending,
						id: DocumentStatusEnum.Pending,
					},
					{
						icon: documentStatusToIconConverter(DocumentStatusEnum.Entered),
						value: DocumentStatusEnum.Entered,
						text: DocumentStatusEnum.Entered,
						id: DocumentStatusEnum.Entered,
					},
				]);
			} else {
				setOptions([
					{
						icon: documentStatusToIconConverter(DocumentStatusEnum.Pending),
						value: DocumentStatusEnum.Pending,
						text: DocumentStatusEnum.Pending,
						id: DocumentStatusEnum.Pending,
					},
					{
						icon: documentStatusToIconConverter(DocumentStatusEnum.Reviewed),
						value: DocumentStatusEnum.Reviewed,
						text: DocumentStatusEnum.Reviewed,
						id: DocumentStatusEnum.Reviewed,
					},
				]);
			}
		} else {
			setOptions([
				{
					icon: documentStatusToIconConverter(DocumentStatusEnum.Pending),
					value: DocumentStatusEnum.Pending,
					text: DocumentStatusEnum.Pending,
					id: DocumentStatusEnum.Pending,
				},
				{
					icon: documentStatusToIconConverter(DocumentStatusEnum.Entered),
					value: DocumentStatusEnum.Entered,
					text: DocumentStatusEnum.Entered,
					id: DocumentStatusEnum.Entered,
				},
				{
					icon: documentStatusToIconConverter(DocumentStatusEnum.Reviewed),
					value: DocumentStatusEnum.Reviewed,
					text: DocumentStatusEnum.Reviewed,
					id: DocumentStatusEnum.Reviewed,
				},
				{
					icon: documentStatusToIconConverter(DocumentStatusEnum.Reconciled),
					value: DocumentStatusEnum.Reconciled,
					text: DocumentStatusEnum.Reconciled,
					id: DocumentStatusEnum.Reconciled,
				},
			]);
		}
	}, [documentTypeId, runQuery, data?.getDocumentTypeById?.amount, throwError, data?.getDocumentTypeById?.statement]);

	return (
		<Dropdown
			{...rest}
			selection
			multiple={multiple}
			options={options}
			loading={loading}
			onChange={(e, val) => {
				if (val.value && onChange) {
					if (multiple && isArray(val.value)) {
						onChange(val.value.map(v => documentStatusEnumLookup(v.toString())));
					} else if (!multiple) {
						onChange(documentStatusEnumLookup(val.value.toString()));
					}
				}
			}}
			clearable
		/>
	);
}
