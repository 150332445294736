import * as Apollo from '@apollo/client';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './deleteAccountingDataCascade.graphql';

const defaultOptions = {} as const;
export type DeleteAccountingDataCascadeMutationVariables = Types.Exact<{
	accountInfoId: Types.Scalars['String'];
	deleteHistoricEntries: Types.Scalars['Boolean'];
}>;

export type DeleteAccountingDataCascadeMutation = {deleteAccountingDataCascade?: string | null};

export type DeleteAccountingDataCascadeType = string;

export type DeleteAccountingDataCascadeMutationFn = Apollo.MutationFunction<
	DeleteAccountingDataCascadeMutation,
	DeleteAccountingDataCascadeMutationVariables
>;

/**
 * __useDeleteAccountingDataCascadeMutation__
 *
 * To run a mutation, you first call `useDeleteAccountingDataCascadeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccountingDataCascadeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountingDataCascadeMutation, { data, loading, error }] = useDeleteAccountingDataCascadeMutation({
 *   variables: {
 *      accountInfoId: // value for 'accountInfoId'
 *      deleteHistoricEntries: // value for 'deleteHistoricEntries'
 *   },
 * });
 */
export function useDeleteAccountingDataCascadeMutation(
	baseOptions?: Apollo.MutationHookOptions<DeleteAccountingDataCascadeMutation, DeleteAccountingDataCascadeMutationVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useMutation<DeleteAccountingDataCascadeMutation, DeleteAccountingDataCascadeMutationVariables>(Operations, options);
}
export type DeleteAccountingDataCascadeMutationHookResult = ReturnType<typeof useDeleteAccountingDataCascadeMutation>;
export type DeleteAccountingDataCascadeMutationResult = Apollo.MutationResult<DeleteAccountingDataCascadeMutation>;
export type DeleteAccountingDataCascadeMutationOptions = Apollo.BaseMutationOptions<
	DeleteAccountingDataCascadeMutation,
	DeleteAccountingDataCascadeMutationVariables
>;
