import debug from 'debug';
import {Dropdown, DropdownProps} from 'semantic-ui-react';
import {useGetManagerDropdownManagersQuery} from '~common/components/ManagerDropdown/getManagerDropdownManagers';

const d = debug('tacs.web.common.components.SupervisorDropdown');

interface SupervisorDropdownProps extends Omit<DropdownProps, 'options' | 'onChange'> {
	onChange?: (managerId: string) => void;
}

export function SupervisorDropdown(props: SupervisorDropdownProps) {
	const {data, loading: queryLoading, error} = useGetManagerDropdownManagersQuery();
	const {onChange, loading, ...rest} = props;

	function handleChange(val?: string) {
		if (val && onChange) onChange(val);
	}

	const options = data?.getActiveAgentsByRoles?.map(agent => {
		return {key: agent.id, value: agent.id, text: agent.name};
	});

	return (
		<Dropdown
			search
			{...rest}
			error={!!error}
			loading={loading || queryLoading}
			options={options || []}
			onChange={(e, val) => handleChange(val.value?.toString())}
		/>
	);
}
