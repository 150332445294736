import {createPages} from '@imperium/layout';
import loadable from '@loadable/component';
import {FilteredByDateRange} from '~common/components/FilteredByDateRange';
import {CommunicationsSidebar} from './components/CommunicationsSidebar';
import {routes} from './routes';

const CommunicationsFeed = loadable(() => import('./components/CommunicationFeed'));

export const routeProps = createPages(routes, {
	communicationFeed: {
		header: (
			<>
				<h2>Communications</h2>
				<FilteredByDateRange />
			</>
		),
		content: () => <CommunicationsFeed />,
		sidebar: [{render: CommunicationsSidebar}],
	},
});
