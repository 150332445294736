import {useCan} from '@imperium/auth-client';
import {LocalDate} from '@js-joda/core';
import {LocalDatePicker, TForm} from '@thx/controls';
import {formatDate} from '@thx/date';
import {formatMoney} from '@thx/money';
import {moneySchemaType} from '@thx/yup-types';
import debug from 'debug';
import {useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Accordion, Button, Divider, Form, FormField, Icon, Label, Popup, Radio} from 'semantic-ui-react';
import {boolean, date, object, string} from 'yup';
import {DeleteModal} from '~common/components';
import {Permission, type UpdateDocumentInput} from '~core/graphql';
import {useAsyncError} from '~lib/useAsyncError';
import {useConvertMoney} from '~lib/useConvertMoney';
import {EmployeesDropdown} from '../../../../accounts/components/employees/EmployeesDropdown';
import {CorporateAssetDropdown} from '../../../../assets/components/CorporateAssetDropdown';
import {useDeleteDocumentMutation} from '../../../graphql/deleteDocument';
import {useGetDocumentByIdQuery} from '../../../graphql/getDocumentById';
import {routes} from '../../../routes';
import {DocumentAmountInput} from '../DocumentAmountInput';
import {DocumentStatusDropdown} from '../DocumentStatusDropdown';
import {DocumentTypeDropdown} from '../DocumentTypeDropdown';
import {VendorDropdown} from '../VendorDropdown';
import {useUpdatedDocumentFormMutation} from './updateDocumentForm';

const d = debug('tacs.web.documents.components.documents.DocumentSidebarViewer');

const addDocumentFormValidation = object().shape({
	type: string().required('The type is required.'),
	vendor: string().required('The vendor is required'),
	employee: string().nullable().notRequired(),
	corporateAsset: string().nullable().notRequired(),
	amount: moneySchemaType().nullable().notRequired(),
	status: string().nullable().notRequired(),
	date: date().required('The date is required.'),
	cash: boolean().required('Cash is required.'),
});

export function DocumentSidebarViewer() {
	const throwError = useAsyncError();
	const {goBack, push} = useHistory();
	const {accountInfoId, documentId} = useParams<typeof routes.types.documentViewer>();
	const [writeAllAccounts] = useCan(Permission.WriteAllAccount);
	const [updateDocumentFormMutation, {loading: mutationLoading}] = useUpdatedDocumentFormMutation();
	const [deleteMutation, {called: calledDelete}] = useDeleteDocumentMutation();
	const [activeAccordion, setActiveAccordion] = useState(false);

	const {data, loading, error} = useGetDocumentByIdQuery({
		variables: {documentId},
	});
	const {loading: convertLoading, convert: convertToCad} = useConvertMoney('USD', 'CAD', data?.getDocumentById?.date, !data?.getDocumentById);

	if (loading) return null;
	if (error) throw error;
	if (!data?.getDocumentById) throw new Error('The unsorted doc meta information could not be loaded');

	function handleFormSubmit(documentInput: UpdateDocumentInput) {
		if (!data?.getDocumentById) throw new Error('The unsorted doc meta information could not be loaded');
		updateDocumentFormMutation({
			variables: {
				data: documentInput,
			},
		})
			.then(() => {
				toast.success('Document updated!');
				goBack();
			})
			.catch(throwError);
	}

	function onDeleteClicked() {
		deleteMutation({
			variables: {id: documentId, version: data?.getDocumentById?.version || 0},
		})
			.then(() => {
				toast.success(`${data?.getDocumentById?.type?.name} deleted.`);
				goBack();
			})
			.catch(throwError);
	}

	return (
		<TForm<UpdateDocumentInput>
			initialValues={{
				id: data.getDocumentById.id,
				version: data?.getDocumentById.version,
				type: data.getDocumentById?.type?.id,
				vendor: data.getDocumentById?.vendor?.id,
				employee: data.getDocumentById?.employee?.id,
				amount: data.getDocumentById?.amount,
				date: data.getDocumentById?.date,
				status: data.getDocumentById?.status,
				cash: data.getDocumentById?.cash,
				corporateAsset: data.getDocumentById?.corporateAsset?.id,
			}}
			onSubmit={handleFormSubmit}
			validationSchema={addDocumentFormValidation}
		>
			{tFormProps => {
				const {handleSubmit, hasErrors, hasWarnings, renderWarnings, values, setFieldValue} = tFormProps;
				return (
					<Form onSubmit={handleSubmit} error={hasErrors} warning={hasWarnings}>
						<FormField required>
							<FormField required>
								<label>Date</label>
								<LocalDatePicker maxDate={LocalDate.now()} onChange={val => setFieldValue('date', val)} value={values?.date} />
							</FormField>
							<label>Type</label>
							<DocumentTypeDropdown
								selection
								onChange={val => {
									setFieldValue('type', val);
								}}
								value={values?.type}
								disabled={!writeAllAccounts}
							/>
						</FormField>
						<FormField required>
							<label>Vendor</label>
							<VendorDropdown
								addNewVendorButton
								onChange={val => setFieldValue('vendor', val)}
								value={values?.vendor}
								accountInfoId={accountInfoId}
							/>
						</FormField>
						{writeAllAccounts && (
							<>
								<FormField>
									<label>Amount</label>
									<DocumentAmountInput
										disabled={false}
										documentTypeId={values?.type || ''}
										onChange={val => setFieldValue('amount', val)}
										value={values?.amount}
									/>
									{!convertLoading && values.amount && values.amount.currency !== 'CAD' && (
										<Label as="div" basic size="large" style={{width: '100%', marginTop: '6px'}}>
											{formatMoney(convertToCad(values.amount), false)} CAD
										</Label>
									)}
								</FormField>
								<FormField>
									<label>Status</label>
									<DocumentStatusDropdown
										multiple={false}
										onChange={value => setFieldValue('status', value)}
										value={values.status}
										documentTypeId={values?.type || ''}
									/>
								</FormField>
								<FormField>
									<label>Cash</label>
									<Radio toggle checked={values.cash} onChange={(event, val) => setFieldValue('cash', val.checked)} />
								</FormField>
								<Accordion>
									<Accordion.Title active={activeAccordion} index={0} onClick={() => setActiveAccordion(!activeAccordion)}>
										<Icon name="dropdown" />
										More Details
									</Accordion.Title>
									<Accordion.Content active={activeAccordion}>
										<FormField>
											<label>Employee</label>
											<EmployeesDropdown
												clearable
												fluid
												selection
												accountInfoId={accountInfoId}
												value={values.employee || ''}
												onChange={value => {
													setFieldValue('employee', value);
												}}
												defaultNull
											/>
										</FormField>
										<FormField>
											<label>Corporate Asset</label>
											<CorporateAssetDropdown
												clearable
												fluid
												selection
												accountInfoId={accountInfoId}
												value={values.corporateAsset || undefined}
												onChange={value => {
													setFieldValue('corporateAsset', value);
												}}
												defaultNull
											/>
											<Divider />
											Sorted by {data?.getDocumentById?.sortedBy.name} on {formatDate(data?.getDocumentById?.sortedOn)}
										</FormField>
									</Accordion.Content>
								</Accordion>
							</>
						)}

						<FormField>{renderWarnings()}</FormField>
						<Divider />
						<FormField>
							<Button disabled={mutationLoading} color="green" type="submit" fluid>
								Update Document
							</Button>
						</FormField>
						<FormField>
							<DeleteModal
								onDelete={onDeleteClicked}
								headerText="Confirm Delete"
								text={`Are you sure you want to delete the ${data?.getDocumentById?.type?.name?.toLowerCase()}?`}
								trigger={<Button disabled={calledDelete} color="red" icon="trash" type="button" />}
							/>
							<Popup
								content="View Original File"
								trigger={
									<Button
										color="blue"
										icon="caret square up"
										type="button"
										onClick={() => push(routes.to.fileViewer({fileInfoId: data?.getDocumentById?.fileInfo.id || '', accountInfoId}))}
									/>
								}
							/>
							<Button color="grey" type="button" onClick={() => goBack()} floated="right">
								Discard Changes
							</Button>
						</FormField>
					</Form>
				);
			}}
		</TForm>
	);
}
