import * as Apollo from '@apollo/client';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getAllAccountsByOffice.graphql';

const defaultOptions = {} as const;
export type GetAllAccountsByOfficeQueryVariables = Types.Exact<{
	managerId: Types.Scalars['String'];
}>;

export type GetAllAccountsByOfficeQuery = {getAllAccountsByManager?: Array<{id: string; version: number; name: string; friendlyId: number}> | null};

export type GetAllAccountsByOfficeType = {id: string; version: number; name: string; friendlyId: number};

/**
 * __useGetAllAccountsByOfficeQuery__
 *
 * To run a query within a React component, call `useGetAllAccountsByOfficeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAccountsByOfficeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAccountsByOfficeQuery({
 *   variables: {
 *      managerId: // value for 'managerId'
 *   },
 * });
 */
export function useGetAllAccountsByOfficeQuery(
	baseOptions: Apollo.QueryHookOptions<GetAllAccountsByOfficeQuery, GetAllAccountsByOfficeQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetAllAccountsByOfficeQuery, GetAllAccountsByOfficeQueryVariables>(Operations, options);
}
export function useGetAllAccountsByOfficeLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetAllAccountsByOfficeQuery, GetAllAccountsByOfficeQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetAllAccountsByOfficeQuery, GetAllAccountsByOfficeQueryVariables>(Operations, options);
}
export type GetAllAccountsByOfficeQueryHookResult = ReturnType<typeof useGetAllAccountsByOfficeQuery>;
export type GetAllAccountsByOfficeLazyQueryHookResult = ReturnType<typeof useGetAllAccountsByOfficeLazyQuery>;
export type GetAllAccountsByOfficeQueryResult = Apollo.QueryResult<GetAllAccountsByOfficeQuery, GetAllAccountsByOfficeQueryVariables>;
