import {createPages} from '@imperium/layout';
import loadable from '@loadable/component';
import {FilteredByDateRange} from '~common/components/FilteredByDateRange';
import {routes} from './routes';

const DepositGstForm = loadable(() => import('./components/DepositGstForm'));
const EditDepositedGstForm = loadable(() => import('./components/EditDepositedGstForm'));
const EditFiledGstForm = loadable(() => import('./components/EditFiledGstForm'));
const EditHeldGstForm = loadable(() => import('./components/EditHeldGstForm'));
const EditPaidGstForm = loadable(() => import('./components/EditPaidGstForm'));
const FileGstForm = loadable(() => import('./components/FileGstForm'));
const GstTable = loadable(() => import('./components/GstTable'));
const HoldGstForm = loadable(() => import('./components/HoldGstForm'));
const PayGstForm = loadable(() => import('./components/PayGstForm'));

export const routeProps = createPages(routes, {
	gst: {
		header: (
			<>
				<h2>GST</h2>
				<FilteredByDateRange />
			</>
		),
		content: () => <GstTable />,
		sidebar: [
			{
				to: dat => routes.to.fileGST({accountInfoId: dat.params.accountInfoId}),
				color: 'green',
				text: 'File GST',
			},
			{
				to: dat => routes.to.depositGST({accountInfoId: dat.params.accountInfoId}),
				color: 'blue',
				text: 'Deposit GST',
			},
			{
				to: dat => routes.to.payGST({accountInfoId: dat.params.accountInfoId}),
				color: 'purple',
				text: 'Pay GST',
			},
			{
				to: dat => routes.to.holdGST({accountInfoId: dat.params.accountInfoId}),
				color: 'orange',
				text: 'Hold/Transfer GST',
			},
		],
	},
	depositGST: {
		header: 'Deposit GST',
		content: () => <DepositGstForm />,
	},
	editDepositedGST: {
		header: 'Edit Deposited GST',
		content: () => <EditDepositedGstForm />,
	},
	fileGST: {
		header: 'File GST',
		content: () => <FileGstForm />,
	},
	editFiledGST: {
		header: 'Edit Filed GST',
		content: () => <EditFiledGstForm />,
	},
	payGST: {
		header: 'Pay GST',
		content: () => <PayGstForm />,
	},
	editPaidGST: {
		header: 'Edit Paid GST',
		content: () => <EditPaidGstForm />,
	},
	holdGST: {
		header: 'Hold/Transfer GST',
		content: () => <HoldGstForm />,
	},
	editHeldGST: {
		header: 'Edit Held GST',
		content: () => <EditHeldGstForm />,
	},
});
