import * as Apollo from '@apollo/client';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getTimeCardPaymentStatus.graphql';

const defaultOptions = {} as const;
export type GetTimeCardPaymentStatusQueryVariables = Types.Exact<{
	timeCardId: Types.Scalars['String'];
}>;

export type GetTimeCardPaymentStatusQuery = {getTimeCardById: {id: string; paid: boolean}};

export type GetTimeCardPaymentStatusType = {id: string; paid: boolean};

/**
 * __useGetTimeCardPaymentStatusQuery__
 *
 * To run a query within a React component, call `useGetTimeCardPaymentStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTimeCardPaymentStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTimeCardPaymentStatusQuery({
 *   variables: {
 *      timeCardId: // value for 'timeCardId'
 *   },
 * });
 */
export function useGetTimeCardPaymentStatusQuery(
	baseOptions: Apollo.QueryHookOptions<GetTimeCardPaymentStatusQuery, GetTimeCardPaymentStatusQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetTimeCardPaymentStatusQuery, GetTimeCardPaymentStatusQueryVariables>(Operations, options);
}
export function useGetTimeCardPaymentStatusLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetTimeCardPaymentStatusQuery, GetTimeCardPaymentStatusQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetTimeCardPaymentStatusQuery, GetTimeCardPaymentStatusQueryVariables>(Operations, options);
}
export type GetTimeCardPaymentStatusQueryHookResult = ReturnType<typeof useGetTimeCardPaymentStatusQuery>;
export type GetTimeCardPaymentStatusLazyQueryHookResult = ReturnType<typeof useGetTimeCardPaymentStatusLazyQuery>;
export type GetTimeCardPaymentStatusQueryResult = Apollo.QueryResult<GetTimeCardPaymentStatusQuery, GetTimeCardPaymentStatusQueryVariables>;
