import * as Apollo from '@apollo/client';
/* eslint-disable @typescript-eslint/ban-types */
import type * as Types from '~core/graphql';

import Operations from './getEditAccountManager.graphql';

const defaultOptions = {} as const;
export type GetEditAccountManagerQueryVariables = Types.Exact<{
	accountInfoId: Types.Scalars['String'];
}>;

export type GetEditAccountManagerQuery = {
	getAccountInfoById?: {id: string; version: number; manager?: {id: string; version: number; name: string} | null} | null;
};

export type GetEditAccountManagerType = {id: string; version: number; manager?: {id: string; version: number; name: string} | null};

/**
 * __useGetEditAccountManagerQuery__
 *
 * To run a query within a React component, call `useGetEditAccountManagerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEditAccountManagerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEditAccountManagerQuery({
 *   variables: {
 *      accountInfoId: // value for 'accountInfoId'
 *   },
 * });
 */
export function useGetEditAccountManagerQuery(baseOptions: Apollo.QueryHookOptions<GetEditAccountManagerQuery, GetEditAccountManagerQueryVariables>) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useQuery<GetEditAccountManagerQuery, GetEditAccountManagerQueryVariables>(Operations, options);
}
export function useGetEditAccountManagerLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetEditAccountManagerQuery, GetEditAccountManagerQueryVariables>,
) {
	const options = {...defaultOptions, ...baseOptions};
	return Apollo.useLazyQuery<GetEditAccountManagerQuery, GetEditAccountManagerQueryVariables>(Operations, options);
}
export type GetEditAccountManagerQueryHookResult = ReturnType<typeof useGetEditAccountManagerQuery>;
export type GetEditAccountManagerLazyQueryHookResult = ReturnType<typeof useGetEditAccountManagerLazyQuery>;
export type GetEditAccountManagerQueryResult = Apollo.QueryResult<GetEditAccountManagerQuery, GetEditAccountManagerQueryVariables>;
